import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import "./index.less";
import LeftNav from "./LeftNav";

const CosmosIntroduction = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                }
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Introduction to Cosmos SDK for Beginners</h1>
                                <p>
                                    The Cosmos SDK is a framework for building blockchain applications that are interoperable with one another. This course is designed to introduce beginners to the Cosmos SDK and how to use it to build their own blockchain applications. Participants will learn the basics of the SDK, including how to set up a development environment, create a basic application, and integrate it with other blockchains using the Cosmos Hub. The course will also cover advanced topics such as governance, staking, and token economics.
                                </p>
                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                <div className="course-outline">
                                    <h1>Course Outline</h1>
                                    <div className='course-row'>
                                        <div className='left-col'>
                                            <h3>Module </h3>
                                            <h2>1</h2>
                                        </div>
                                        <div className='right-col'>
                                            <h3>Introduction to Cosmos SDK </h3>
                                            <ul>
                                                <li><Link to='/whatis-cosmos-sdk'>What is Cosmos SDK?</Link></li>
                                                <li><Link to='/why-learn-cosmos'>Why learn Cosmos SDK?</Link></li>
                                                <li><Link to='/setting-cosmossdk-enviroment'>Setting up the Cosmos SDK environment</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='course-row'>
                                        <div className='left-col'>
                                            <h3>Module </h3>
                                            <h2>2</h2>
                                        </div>
                                        <div className='right-col'>
                                            <h3>Cosmos SDK Basics</h3>
                                            <ul>
                                                <li><Link to='/cosmossdk-basics'>Understanding the Cosmos SDK architecture</Link></li>
                                                <li><Link to='/ignite-module-creation'>Understanding the ignite tool for module creation</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='course-row'>
                                        <div className='left-col'>
                                            <h3>Module </h3>
                                            <h2>3</h2>
                                        </div>
                                        <div className='right-col'>
                                            <h3>State Management with Cosmos SDK</h3>
                                            <ul>
                                                <li><Link to='/state-management'>Understanding state management in Cosmos SDK</Link></li>
                                                <li><Link to='/storing-retrieving-data'>Storing and retrieving data in the key-value store</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='course-row'>
                                        <div className='left-col'>
                                            <h3>Module </h3>
                                            <h2>4</h2>
                                        </div>
                                        <div className='right-col'>
                                            <h3>Building Custom Modules in Cosmos SDK</h3>
                                            <ul>
                                                <li><Link to='/building-custommodules'>Building a custom module from scratch</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='course-row'>
                                        <div className='left-col'>
                                            <h3>Module </h3>
                                            <h2>5</h2>
                                        </div>
                                        <div className='right-col'>
                                            <h3>Interacting with External Networks</h3>
                                            <ul>
                                                <li><Link to='/understanding-ibc'>Understanding inter-blockchain communication (IBC)</Link></li>
                                                <li><Link to='/ibc-working'>Sending and receiving transactions between Cosmos SDK and other networks</Link></li>
                                                <li><Link to='/setting-relayer'>Setting up a go relayer</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <h3>References</h3>
                                        <p className="mb-1"><a href="https://github.com/cosmos/sdk-tutorials" target="_blank" rel="noreferrer noopener">https://github.com/cosmos/sdk-tutorials</a></p>
                                        <p className="mb-1"><a href="https://docs.ignite.com/" target="_blank" rel="noreferrer noopener">https://docs.ignite.com/</a> </p>
                                        <p><a href="https://docs.cosmos.network/main" target="_blank" rel="noreferrer noopener">https://docs.cosmos.network/main</a></p>
                                    </div>
                                </div>
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmosIntroduction;