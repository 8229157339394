import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import ArticleCard from "../../components/common/ArticleCard";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import "./index.less";

import ExploreBanner from '../../assets/images/explore-banner.png';
import reactIcon from '../../assets/images/react-icon.png';
import articleImg1 from '../../assets/images/how_comdex_driving_defi.jpg';
import articleImg2 from '../../assets/images/comdex_providing_defi.png';
import articleImg3 from '../../assets/images/comdex_integrate_comswasm.jpeg';
import TopBlueBg from '../../assets/images/blue-texture-left.png';

const DeveloperPortal = () => {
    return (
        <div className="homepage-wrapper developerportal-wrapper">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col sm='7'>
                            <h1>Developers <br /> Portal</h1>
                            <p>
                                Comdex is a lightning fast blockchain optimised for building financial applications. Rapidly create massively scalable dApps with novel Web3 modules, ultra low fees and unmatched capital efficiency.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <img className="explore-banner" src={ExploreBanner} alt={ExploreBanner} />
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                    <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Learn and apply information from the official sources about a task or project.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                    <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get the codes in one place.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href="https://discord.com/invite/7vjPvWKKMT" target="_blank" rel="noreferrer noopener">
                                                    <h3>Developer Chat<SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Discuss & learn with the team.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="explore-section-alt pb-0">
                <Container>
                    <Row className='mb-5 pb-4'>
                        <Col sm='12'>
                            <div>
                                <h2>Introduction to Cosmos</h2>
                                <p className="mb-0">
                                    Cosmos is a decentralized network of independent parallel blockchains, each powered by BFT consensus algorithms like Tendermint consensus. The team aims to create an Internet of Blockchains, a network of blockchains able to communicate with each other in a decentralized way.
                                </p>
                            </div>
                            <ul className="list">
                                <li>
                                    <Link to='/cosmos-introduction'>
                                        <div className='explore-card'>
                                            <div className="explore-card-inner">
                                                <div className="card-upper">
                                                    <div className="left-col">
                                                        <div className="icon-col mb-4">
                                                            <img src={reactIcon} alt={reactIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-bottom">
                                                    <h3>Introduction to <br /> Cosmos SDK</h3>
                                                    <ul>
                                                        <li>Introduction to Cosmos SDK</li>
                                                        <li>Cosmos SDK Basics</li>
                                                        <li>State Management with Cosmos SDK</li>
                                                    </ul>
                                                    <Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className='mb-5 pb-4'>
                        <Col sm='12'>
                            <div>
                                <h2>Introduction to Comdex</h2>
                                <p className="mb-0">
                                    Comdex is an Interchain DeFi infrastructure layer to build applications to power DeFi in the Cosmos ecosystem and beyond by utilising on-chain modules of the prevailing primitives in the worlds of DeFi and Finance.
                                </p>
                            </div>
                            <ul className="list">
                                <li>
                                    <Link to='/introduction-comdex'>
                                        <div className='explore-card'>
                                            <div className="explore-card-inner">
                                                <div className="card-upper">
                                                    <div className="left-col">
                                                        <div className="icon-col mb-4">
                                                            <img src={reactIcon} alt={reactIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-bottom">
                                                    <h3>Introduction to <br /> Comdex</h3>
                                                    <ul>
                                                        <li>Comdex architecture</li>
                                                        <li>Setting up Devnet</li>
                                                    </ul>
                                                    
                                                        <Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className='mb-5 pb-4'>
                        <Col sm='12'>
                            <div>
                                <h2>Dev Lessons (Basic)</h2>
                                <p className="mb-0">
                                    Learn how to code or improve your coding skills. These lessons are designed to provide step-by-step guidance on how to create your first dapp on Comdex chain.
                                </p>
                            </div>
                            <ul className="list">
                                <li>
                                    <Link to='/dev-lessons-basics'>
                                        <div className='explore-card'>
                                            <div className="explore-card-inner">
                                                <div className="card-upper">
                                                    <div className="left-col">
                                                        <div className="icon-col mb-4">
                                                            <img src={reactIcon} alt={reactIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-bottom">
                                                    <h3>Get familiar with GO</h3>
                                                    <ul>
                                                        <li>Introduction to Go Programming</li>
                                                        <li>Go Syntax and Variables</li>
                                                        <li>Control Structures in Go</li>
                                                    </ul>
                                                    <Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/devlessons-with-rust'>
                                        <div className='explore-card'>
                                            <div className="explore-card-inner">
                                                <div className="card-upper">
                                                    <div className="left-col">
                                                        <div className="icon-col mb-4">
                                                            <img src={reactIcon} alt={reactIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-bottom">
                                                    <h3>Get familiar with Rust</h3>
                                                    <ul>
                                                        <li>Introduction to Rust Programming</li>
                                                        <li>Rust Syntax and Variables</li>
                                                        <li>Control Structures in Rust</li>
                                                    </ul>
                                                    <Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/create-your-dApp'>
                                        <div className='explore-card'>
                                            <div className="explore-card-inner">
                                                <div className="card-upper">
                                                    <div className="left-col">
                                                        <div className="icon-col mb-4">
                                                            <img src={reactIcon} alt={reactIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-bottom">
                                                    <h3>Create your first dApp on Comdex</h3>
                                                    <ul>
                                                        <li>Build dApp on Comdex chain</li>
                                                        <li>Using generic modules</li>
                                                        <li>Steps to Launch a dApp</li>
                                                    </ul>
                                                    <Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className='mb-5 pb-4'>
                        <Col sm='12'>
                            <div>
                                <h2>Dev Lessons (Advance)</h2>
                                <p className="mb-0">
                                    Learn how to create and use modules on the Comdex chain.
                                </p>
                            </div>
                            <ul className="list">
                                <li>
                                    <Link to='/dev-lessons-advance'>
                                        <div className='explore-card'>
                                            <div className="explore-card-inner">
                                                <div className="card-upper">
                                                    <div className="left-col">
                                                        <div className="icon-col mb-4">
                                                            <img src={reactIcon} alt={reactIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-bottom">
                                                    <h3>Creating your own Comdex Module</h3>
                                                    <ul>
                                                        <li>Creating a structure of the application</li>
                                                        <li>Importing methods from the Bank keeper and Asset keeper</li>
                                                    </ul>
                                                    <Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/gettingstarted-with-cosmWasm'>
                                        <div className='explore-card'>
                                            <div className="explore-card-inner">
                                                <div className="card-upper">
                                                    <div className="left-col">
                                                        <div className="icon-col mb-4">
                                                            <img src={reactIcon} alt={reactIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-bottom">
                                                    <h3>Getting started with CosmWasm</h3>
                                                    <ul>
                                                        <li>What is CosmWasm</li>
                                                        <li>How to use CosmWasm</li>
                                                        <li>Pre-Requisites </li>
                                                    </ul>
                                                    <Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="article-section recent-blog">
                <Container>
                    <Row>
                        <Col>
                            <div className="headings text-left">
                                <h1>Related Blogs</h1>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="acticle-list">
                                <ArticleCard
                                    articleImg={articleImg1}
                                    name='How Comdex is driving DeFi in Cosmos'
                                    date='Feb 17, 2023'
                                    description='In our last piece, we highlighted the advantages of building decentralized finance (DeFi) projects on Cosmos over building on popular...'
                                    link="https://blog.comdex.one/how-comdex-is-driving-defi-in-cosmos-67660de32474"
                                />
                                <ArticleCard
                                    articleImg={articleImg2}
                                    name='Comdex: Providing a DeFi Infrastructure...'
                                    date='May 4, 2022'
                                    description='On March 24th, we held our first community Town Hall event. Validators, community members & the Comdex team came together....'
                                    link="https://blog.comdex.one/comdex-providing-a-defi-infrastructure-layer-for-the-cosmos-ecosystem-af706b7f2d3c"
                                />
                                <ArticleCard
                                    articleImg={articleImg3}
                                    name='Comdex integrates CosmWasm'
                                    date='May 7, 2022'
                                    description='With the launch of the Comdex ‘Decentralized Synthetics Exchange’ fast approaching, the Comdex & CosmWasm development teams have...'
                                    link="https://blog.comdex.one/comdex-integrates-cosmwasm-e819b4502deb"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default DeveloperPortal;