import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const CosmosModuleOneThree = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'WHAT IS COSMOS',
                                    href: '',
                                },
                                {
                                    title: 'MODULE 1',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Key features and benefits of using Cosmos SDK</h1>
                                <p>
                                    The Cosmos SDK is a powerful tool for building blockchain applications, offering several key features and benefits:
                                </p>

                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                
                                <div className="learn-module">
                                    <h1>What will you learn in Module 1.3</h1>
                                    <div className="learn-m-card">
                                        <ul>
                                            <li>
                                               Understanding the benefits of using Cosmos SDK for building blockchain applications
                                            </li>
                                            <li>
                                                Key features of Cosmos SDK such as modularity, flexibility, and interoperability
                                            </li>
                                            <li>
                                                Real-world use cases of Cosmos SDK
                                            </li>
                                        </ul>
                                    </div>

                                    <h4>1. Modularity:</h4>
                                    <p>
                                        The Cosmos SDK is designed with modularity in mind, meaning that it is composed of several independent modules that can be used together or separately depending on the developer's needs. These modules can be easily customized and extended with additional functionality as needed. Some of the modules included in the Cosmos SDK are authentication, token issuance, governance, and more.
                                    </p>
                                    
                                    <h4>2. Flexibility:</h4>
                                    <p>
                                        The Cosmos SDK is built to be highly flexible, allowing developers to choose from multiple programming languages, consensus algorithms, and other tools. This flexibility means that developers can use the tools and languages they are most comfortable with, and can easily customize the network to meet their needs.
                                    </p>

                                    <h4>3. Interoperability:</h4>
                                    <p>
                                        The Cosmos SDK's focus on interoperability is perhaps its most significant advantage. This is made possible through the use of the Inter-Blockchain Communication (IBC) protocol, which enables seamless communication between different blockchain networks. This feature opens up new possibilities for decentralized applications that can leverage the strengths of different networks, and allows developers to create custom blockchain networks that can interact with other networks in the Cosmos ecosystem.
                                    </p>

                                    <h3 className="mb-4 pb-2 mt-5">Real-world use cases of Cosmos SDK include:</h3>

                                    <h4>1. Binance Chain:</h4>
                                    <p>
                                        Binance Chain is a high-performance blockchain network built using the Cosmos SDK. It offers fast transaction times and low fees, making it ideal for cryptocurrency trading and other applications.
                                    </p>

                                    <h4>2. Terra:</h4>
                                    <p>
                                        Terra is a stablecoin platform that is built using the Cosmos SDK. It is designed to be fast and secure, with a focus on usability and accessibility.
                                    </p>

                                    <h4>3. Kava:</h4>
                                    <p>
                                        Kava is a decentralized finance (DeFi) platform built using the Cosmos SDK. It offers a variety of DeFi services, such as borrowing and lending, and is designed to be highly scalable and interoperable.
                                    </p>
                                    
                                    <h4>4. Comdex Chain:</h4>
                                    <p>
                                        Comdex Chain is a blockchain network built using the Cosmos SDK, and is designed to be a fast and secure platform for digital asset exchange. It offers fast transaction times and low fees and includes features such as smart contracts and decentralized exchange (DEX). The Comdex Chain is also interoperable with other networks in the Cosmos ecosystem, making it possible for developers to create applications that can interact with multiple networks.
                                    </p>

                                    <p className="mt-5 pt-4">
                                        Overall, the Cosmos SDK offers a powerful set of tools for building blockchain applications, with a focus on modularity, flexibility, and interoperability. These features make it an attractive option for developers who want to create custom blockchain networks that can interact seamlessly with other networks in the Cosmos ecosystem, such as the Comdex Chain.
                                    </p>

                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with the first section <Link to='/'><b>Setting up the development environment.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmosModuleOneThree;