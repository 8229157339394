import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const ImportingMethods = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'CREATE YOUR OWN COMDEX MODULE',
                                        href: 'dev-lessons-advance',
                                    },
                                    {
                                        title: 'importing methods',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Importing methods from the Bank keeper and Asset keeper</h1>
                                <p>
                                    In the previous step you have created the loan module with ignite scaffold module using --dep bank. This command created a new module and added the bank keeper to the loan module, which allows you to add and use bank's keeper methods in loan's keeper methods.
                                </p>

                                <p>
                                    We will also be importing asset module's function for getting assets and pairs.
                                </p>

                                <div className="learn-module">
                                <CodeSnippet
codeData={`ignite scaffold module loan --dep bank`}
/>

                                    <p>Now, we will create our proto:</p>
                                    <CodeSnippet
codeData={`type BankKeeper interface {    
    SpendableCoins(ctx sdk.Context, addr sdk.AccAddress) 
    sdk.Coins    
        SendCoinsFromAccountToModule(ctx sdk.Context, address 
    sdk.AccAddress, name string, coins sdk.Coins) error    
        SendCoinsFromModuleToAccount(ctx sdk.Context, name string, 
    address sdk.AccAddress, coins sdk.Coins) error    
        GetAllBalances(ctx sdk.Context, addr sdk.AccAddress) sdk.Coins    
        SendCoinsFromModuleToModule(        
            ctx sdk.Context, senderModule, recipientModule string, amt 
    sdk.Coins,    
        ) error    
        SendCoins(ctx sdk.Context, fromAddr, toAddr sdk.AccAddress, 
    amt sdk.Coins) error    
        GetBalance(ctx sdk.Context, addr sdk.AccAddress, denom string) sdk.Coin
    }
    
    type AssetKeeper interface {    
        GetAsset(ctx sdk.Context, id uint64) (assettypes.Asset, bool)    
        GetPair(ctx sdk.Context, id uint64) (assettypes.Pair, bool)    
        GetApp(ctx sdk.Context, id uint64) (assettypes.AppData, bool)
}`}
/>

                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/transactions'><b>Transactions.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ImportingMethods;