import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const ControlStructuresInRust = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'DEV LESSONS BASICS(RUST)',
                                    href: 'devlessons-with-rust',
                                },
                                {
                                    title: 'CONTROL STRUCTURES IN RUST',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Control Structures in Rust</h1>
                                <p>
                                    In programming, control structures are used to determine the flow of the program based on certain conditions. In this lesson, we will learn about the different types of control structures in Rust with examples.
                                </p>

                                <div className="learn-module">
                                    <h1>Lesson Objectives:</h1>
                                    <div className="learn-m-card">
                                        <p>By the end of this lesson, you should be able to:</p>
                                        <ul>
                                            <li>
                                                Control Stucture
                                            </li>
                                            <li>
                                                Different types of control structures in Rust with examples.
                                            </li>
                                            <li>
                                               Determine the flow of the program based on certain conditions
                                            </li>
                                        </ul>
                                    </div>

                                    <h3>If statements and conditional expressions</h3>

                                    <p>
                                        In Rust, if statements and conditional expressions are used to execute a block of code if a certain condition is met. The basic syntax for an if statement in Rust is:
                                    </p>
                                    <CodeSnippet
codeData={`let x = 5;
if x == 5 {    
    println!("x is equal to 5");
}`
}
/>

                                    <p>
                                        In this example, we first declare a variable x and assign it a value of 5. We then use an if statement to check if x is equal to 5. If the condition is true, the code within the curly braces will be executed, which in this case is to print a message to the console.
                                    </p>

                                    <p>
                                        Conditional expressions in Rust are similar to if statements, but they are used to assign a value to a variable based on a condition. The syntax for a conditional expression in Rust is:
                                    </p>
                                    <CodeSnippet
codeData={`let result = if x == 5 {    
    "x is equal to 5"
    } else {    
        "x is not equal to 5"
    };`}
/>

                                    <p>
                                        In this example, we use a conditional expression to assign a value to the result variable based on the value of x. If x is equal to 5, the first value is assigned, and if it is not, the second value is assigned.
                                    </p>

                                    <h3>For loops and looping structures</h3>
                                    <p>
                                        For loops are used to iterate over a sequence of values in Rust. The basic syntax for a for loop in Rust is:
                                    </p>
                                    <CodeSnippet
codeData={`let numbers = [1, 2, 3, 4, 5];
for number in numbers.iter() {    
    println!("{}", number);
}`}
/>
                                    <p>
                                        In this example, we create an array of numbers and use a for loop to iterate over each element in the array and print it to the console.
                                    </p>

                                    <p>
                                        Rust also provides other looping structures, such as loop, while, and while let, for more specific use cases.
                                    </p>
                                    <CodeSnippet
codeData={`let mut counter = 0;
loop {    
    counter += 1;    
    println!("{}", counter);    
    if counter == 10 {        
        break;    
        }
}`}
/>
                                   <p>
                                        In this example, we use a loop to repeatedly print the value of counter to the console until it reaches 10, at which point we use the break keyword to exit the loop.
                                   </p>

                                   <h3>Match expressions</h3>
                                   <p>
                                        Match expressions in Rust are used to compare a value against a series of patterns and execute code based on the pattern that matches. The basic syntax for a match expression in Rust is:
                                   </p>
                                   <CodeSnippet
codeData={`let x = 5;match x {    
    1 => println!("x is equal to 1"),    
    2 | 3 => println!("x is equal to 2 or 3"),    
    4..=6 => println!("x is between 4 and 6"),   
    _ => println!("x is something else"),}`}
/>

                                    <p>
                                        In this example, we use a match expression to compare the value of x against several patterns. If x is equal to 1, we print a message to the console. If x is equal to 2 or 3, we print a different message. If x is between 4 and 6, we print yet another message. Finally, if x does not match any of the other patterns, we print a default message using the underscore pattern.
                                    </p>

                                    <h2>Here are some additional examples</h2>
                                    <h3>If statements and conditional expressions</h3>
                                    <CodeSnippet
codeData={`let x = 10;
if x > 5 {    
    println!("x is greater than 5");
} else if x < 5 {    
    println!("x is less than 5");
} else {    
    println!("x is equal to 5");
}

let result = if x % 2 == 0 {    
    "x is even"
} else {    
    "x is odd"
};
println!("{}", result);`}
/>
                                    <p>
                                        In this example, we use an if statement to check if x is greater than 5. If it is, we print a message to the console. If it is not, we check if it is less than 5. If it is, we print a different message. Otherwise, we print a third message. We also use a conditional expression to assign a value to the result variable based on whether x is even or odd.
                                    </p>
                                    <h3>For loops and looping structures</h3>
                                    <CodeSnippet
codeData={`let numbers = [1, 2, 3, 4, 5];
for number in numbers.iter().rev() {    
    println!("{}", number);
}

let mut counter = 0;
while counter < 10 {    
    counter += 1;    
    println!("{}", counter);
}

let mut option = Some(5);
while let Some(x) = option {    
    println!("{}", x);    
    option = if x > 0 { Some(x - 1) } else { None };
}`}
/>
                                    <p>
                                        In this example, we use a for loop to iterate over the numbers array in reverse order and print each number to the console. We also use a while loop to repeatedly print the value of counter to the console until it reaches 10. Finally, we use a while let loop to repeatedly print the value of the option variable until it becomes None.
                                    </p>
                                    <h3>Match expressions</h3>
                                    <CodeSnippet
codeData={`let x = 3;
match x {    
    1 | 2 => println!("x is less than or equal to 2"),    
    3 | 4 | 5 => println!("x is between 3 and 5"),    
    n if n % 2 == 0 => println!("x is an even number"),    
    n if n % 2 != 0 => println!("x is an odd number"),    
    _ => println!("x is something else"),
}`}
/>
                                    <p>
                                        In this example, we use a match expression to compare the value of x against several patterns. If x is less than or equal to 2, we print a message to the console. If x is between 3 and 5, we print a different message. If x is an even number, we print another message. If x is an odd number, we print yet another message. Finally, if x does not match any of the other patterns, we print a default message using the underscore pattern.
                                    </p>

                                    <h3>Conclusion</h3>
                                    <p>
                                        In this lesson, we have learned about the different types of control structures in Rust, including if statements, conditional expressions, for loops, and match expressions. These control structures are essential for controlling the flow of a program and making decisions based on certain conditions. By understanding these control structures, you are now ready to move on to the next lesson and learn about functions in Rust.
                                    </p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/functions-inrust'><b>Functions in Rust.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ControlStructuresInRust;