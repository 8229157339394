import { Button, Form, Input, Alert } from "antd";
import React, { Component } from "react";
import { Col, Container, Row } from "../../components/common";
import $ from 'jquery';
import "./index.less";

import TopBlueBg from '../../assets/images/blue-texture-left.png';
import RightRedBg from '../../assets/images/red-texture-right2.png';
import ContactSolar from '../../assets/images/contact-solar.png';

const { TextArea } = Input;

class ContactUs extends Component {
    constructor(props) {
        super(props)
        this.formIns = React.createRef();

        var date = new Date();
        var formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

        this.state = {
            currentDate: formatedDate,
            visible: false,
            submitSuccess: false,
            loading: false,
            countryCode: "91",
            formData: {}
        };
    }

    onFinish = (values) => {
        this.setState({ formData: values, loading: true });
        let data = this.state.formData;
        let that = this;
        $.ajax({
            url: 'https://script.google.com/macros/s/AKfycbyC7RzqVUjlA-qMOKYHwUnQpkFiHcCg-kp2lLGhJ3Q4bpZzjUd-j_VtkbtWQxAeTiqLrA/exec',
            method: "GET",
            dataType: "json",
            data: $.param(data),
            success: function (res) {
                that.formIns.current.resetFields();
                that.setState({ loading: false, submitSuccess: true });
                setTimeout(
                    () => that.setState({ submitSuccess: false }), 3000);
            }
        });
    }
    render() {
        const { loading } = this.state;
        const onFinishFailed = (errorInfo) => {
            console.log("Failed:", errorInfo);
        };
        let successMsg = '';
        const submitSuccess = this.state.submitSuccess;
        if (submitSuccess) {
            successMsg = <Alert message="Your information has been submitted successfully!" type="success" className="mb-2" />
        }
        return (
            <div className="homepage-wrapper contact-wrapper">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <img className="right-red-bg" src={RightRedBg} alt='' />
                <img className="contact-solar" src={ContactSolar} alt="" />
                <Container>
                    <Row>
                        <Col md='5' className='contact-info'>
                            <h1>Contact Us</h1>
                            <p>
                                Write to us at: <span>info@comdex.one</span> for any question/query or drop us your contact details and our team will reach out to you within the next 48-hours.
                            </p>
                        </Col>
                        <Col md='7'>
                            <div className="contact-form">
                                <div className="success-msg">{successMsg}</div>
                                <Form ref={this.formIns} name="cf" method="post" layout="vertical" onFinish={this.onFinish} onFinishFailed={onFinishFailed}>
                                    <Form.Item label="Name" rules={[{ required: true, message: 'Please enter your name!' }]} name="name">
                                        <Input placeholder="Enter your name.." />
                                    </Form.Item>

                                    <Form.Item
                                        label="Email"
                                        rules={[{ required: true, type: `email`, message: 'Please enter your email!' }]}
                                        name="email"
                                    >
                                        <Input placeholder="Enter your email.." />
                                    </Form.Item>

                                    <Form.Item label="What defines you the best?" rules={[{ required: true, message: 'Please enter!' }]} name="wdytb">
                                        <Input placeholder="Enter.." />
                                    </Form.Item>

                                    <Form.Item label="Message" rules={[{ required: true, message: 'Please enter message!' }]} name="message" >
                                        <TextArea placeholder="Enter your message.." rows={5} />
                                    </Form.Item>

                                    <Form.Item className="text-center mb-0">
                                        <Button type="primary" htmlType="submit" loading={loading} className="mt-3 px-5"> Submit </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ContactUs;