import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const OwnershipAndBorrowingInRust = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: '',
                                    },
                                    {
                                        title: 'DEV LESSONS BASICS(RUST)',
                                        href: '',
                                    },
                                    {
                                        title: 'UNDERSTANDING OWENSHIP AND BORROWING IN RUST',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Understanding Ownership and Borrowing in Rust</h1>
                                <p>
                                    Rust's ownership and borrowing model is a unique feature that ensures memory safety while avoiding the overhead of garbage collection. In this lesson, we'll explore the basics of ownership and borrowing in Rust, and how they prevent memory-related bugs.
                                </p>

                                <div className="learn-module">

                                    <h3>Ownership in Rust</h3>

                                    <p>
                                        Ownership in Rust refers to the set of rules that govern how memory is managed. Every value in Rust has an owner, and there can only be one owner at a time. When the owner goes out of scope, the value is dropped, and its memory is freed.
                                    </p>

                                    <p>
                                        Rust uses move semantics to transfer ownership between variables. Move semantics means that when a value is assigned to a new variable, the original variable loses ownership of the value, and the new variable becomes the owner. This prevents issues like double free errors, where memory is freed twice, causing a crash.
                                    </p>

                                    <h3>Borrowing in Rust</h3>
                                    
                                    <p>
                                        Borrowing in Rust is a way to give temporary access to a value to another variable or function without transferring ownership. There are two types of borrows in Rust: mutable and immutable borrows.
                                    </p>

                                    <p>
                                        Immutable borrows allow the borrower to read the value but not modify it. The original owner retains ownership of the value, and other variables can borrow it for read-only access. Immutable borrows prevent multiple variables from modifying the same value simultaneously, which can cause data races.
                                    </p>

                                    <p>
                                        Mutable borrows allow the borrower to modify the value while the original owner retains ownership. However, only one mutable borrow can exist at a time for a particular value. This prevents multiple variables from modifying the same value simultaneously, which can also cause data races.
                                    </p>

                                    <h3>Using Reference Types in Rust</h3>
                                    <p>
                                        In Rust, variables can hold ownership of their values, which ensures that the program has full control over the memory used by those values. However, there are cases where we need to pass ownership of values to other parts of the program or share data between different parts of the program. In such cases, Rust provides the concept of "references" that allow variables to refer to values without taking ownership of them.
                                    </p>

                                    <h3>References in Rust</h3>
                                    <p>
                                        References are a way to refer to the memory location of a value without taking ownership of that value. In Rust, references are created using the & operator followed by the name of the variable being referred to. For example, to create a reference to an integer variable named x, we can use the following code:
                                    </p>
                                    <CodeSnippet
codeData={`let x = 5;
let x_ref = &x;`}
/>
                                    <p>
                                        The x_ref variable is now a reference to the x variable. Since it's only a reference, it doesn't own the value of x, and x remains the owner. This means that x cannot be modified through x_ref.
                                    </p>

                                    <h3>Mutable References</h3>
                                    <p>
                                        Mutable references, denoted by &mut, enable the borrower to modify the value. However, Rust only allows one mutable reference to a value at any given time. This is known as the "mutable borrowing rule" in Rust.
                                    </p>

                                    <CodeSnippet
codeData={`let mut x = 5;
let x_ref = &mut x;
*x_ref = 6;`}
/>
                                    <p>
                                        In this example, we've created a mutable reference x_ref to the x variable, and then used the dereference operator * to modify its value.
                                    </p>

                                    <h3>Slices</h3>
                                    <p>
                                        Slices are another type of reference that allow a portion of an array or a string to be referenced without taking ownership of it. Slices are created using the & operator, followed by the variable name, and a range operator .. or ..= to specify the range of the slice.
                                    </p>
                                    <CodeSnippet
codeData={`let numbers = [1, 2, 3, 4, 5];
let slice = &numbers[1..3];`}
/>
                                    <p>In this example, slice is a reference to the elements of the numbers array from index 1 to 2 (inclusive).</p>

                                    <h3>Conclusion</h3>
                                    <p>
                                        References and slices are an essential part of Rust's ownership and borrowing model. They allow for safe and efficient sharing and mutation of data between different parts of a program. By enforcing strict rules around how values are owned and accessed, Rust ensures that memory is managed safely and efficiently. Understanding how references and slices work is essential for writing efficient, safe, and correct Rust code.
                                    </p>

                                    <h3>Managing memory allocation and deallocation in Rust</h3>
                                    <p>
                                        In Rust, memory allocation and deallocation are managed using a system called ownership. This system ensures that memory is always cleaned up when it's no longer needed, preventing common issues such as memory leaks and dangling pointers.
                                    </p>
                                    <p>
                                        The ownership system works by assigning each piece of memory to a single owner at any given time. The owner can be a variable or a data structure that has exclusive control over the memory it owns. When the owner goes out of scope, the memory it owns is automatically deallocated.
                                    </p>
                                    <p>
                                        For example, let's consider the following code:
                                    </p>
                                    <CodeSnippet
codeData={`fn main() {    
    let s = String::from("hello"); // s is the owner of the memory 
    allocated for the string "hello"    
        println!("{}", s);
} 
// s goes out of scope and the memory allocated for the string 
"hello" is automatically deallocated`}
/>

                                    <p>
                                        Here, s is the owner of the memory allocated for the string "hello". When s goes out of scope at the end of the block, the memory is automatically deallocated.
                                    </p>
                                    <p>
                                        However, sometimes we need to share memory between different parts of the program. Rust provides reference types to handle this situation. A reference is a pointer to a piece of memory that is owned by something else. Unlike a traditional pointer, a reference has no ownership of the memory it points to, but it can read and modify the data it references.
                                    </p>
                                    <p>
                                        For example, let's consider the following code:
                                    </p>
                                    <CodeSnippet
codeData={`fn main() {    
    let s = String::from("hello");    
    let len = calculate_length(&s); // passing a reference to s to the 
    calculate_length function    
        println!("The length of '{}' is {}.", s, len);
    }
    
    fn calculate_length(s: &String) -> usize {    
        s.len() // returns the length of the string referenced by s
    }`}
/>
                                    <p>
                                        Here, calculate_length takes a reference to a string (&String) as its argument. The reference is passed to the function instead of the string itself, which means that the ownership of the string is not transferred to the function. The function can read and modify the string, but it cannot deallocate it.
                                    </p>
                                    <p>
                                        Rust also provides several features to help manage memory allocation and deallocation, such as the Box type for allocating memory on the heap, and the drop function for explicitly releasing memory.
                                    </p>
                                    <p>
                                        For example, let's consider the following code:
                                    </p>
                                    <CodeSnippet
codeData={`struct MyStruct {    
    data: Vec<i32>
}

impl Drop for MyStruct {    
    fn drop(&mut self) {        
        println!("MyStruct is being deallocated.");    
    }
}

fn main() {    
    let mut s = Box::new(MyStruct { data: vec![1, 2, 3] }); // allocating 
    memory for MyStruct on the heap using Box    
        s.data.push(4);    
        drop(s); // explicitly releasing the memory allocated for MyStruct 
    using drop
}`}
/>
                                    <p>
                                        Here, MyStruct is a struct that contains a vector of integers. The Drop trait is implemented for MyStruct, which means that the drop function will be called when the memory allocated for MyStruct is released. main allocates memory for MyStruct on the heap using Box, adds an element to the vector, and then explicitly releases the memory using drop.
                                    </p>
                                    <p>
                                        Understanding how Rust manages memory is essential for writing safe and efficient programs, as it prevents many common issues that can arise from manual memory management.
                                    </p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/structs-enums-inrust'><b>Structs and Enums in Rust.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default OwnershipAndBorrowingInRust;