import { Button } from "antd";
import React from "react";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import ArticleCard from "../../components/common/ArticleCard";
import "./index.less";

import Eventbg1 from '../../assets/images/event-bg1.png';
import articleTop from '../../assets/images/how_comdex_driving_defi.jpg';
import articleImg1 from '../../assets/images/risk_assetment_0n_commodo.webp';
import articleImg2 from '../../assets/images/introduction_to_cswap_dex.webp';
import articleImg3 from '../../assets/images/harbor_protocol_the_interchain.webp';
import articleImg4 from '../../assets/images/need_composit_cmst.webp';
import articleImg5 from '../../assets/images/how_decentralized_stablecoins.webp';
import articleImg6 from '../../assets/images/why_defi_in_cosmos.webp';
import articleImg7 from '../../assets/images/understanding_harbor_tokenomics.webp';
import articleImg8 from '../../assets/images/why_isnot_harbor_collateral.webp';
import articleImg9 from '../../assets/images/risk_assetment_harbor_protocol.webp';
import articleImg10 from '../../assets/images/understanding_commodo_cpools.webp';
import GiftImg from '../../assets/images/BANNER.svg';
import TopBlueBg from '../../assets/images/blue-texture-left.png';
import RedRightBg from '../../assets/images/red-texture-right2.png';
import BlueLeftBg from '../../assets/images/blue-texture-left2.png';
import LineLeft from '../../assets/images/line-bg-left.png';
import BlueRightBg from '../../assets/images/blue-texture-right.png';
import LineRight from '../../assets/images/line-bg-right.png';

const Blogs = () => {
    return (
        <div className="homepage-wrapper blog-wrapper">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col sm='7'>
                            <h1>Blogs</h1>
                            <p>
                                Stay tuned with the latest news and updates.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <img className="blogbanner-bg" src={Eventbg1} alt={Eventbg1} />
            </section>
            <section className="bloglist-section">
                <img className="red-right" src={RedRightBg} alt='' />
                <img className="left-line" src={LineLeft} alt='' />
                <img className="left-bg" src={BlueLeftBg} alt='' />
                <img className="left-line2" src={LineLeft} alt='' />
                <img className="left-bg2" src={BlueLeftBg} alt='' />
                <img className="right-line2" src={LineRight} alt='' />
                <img className="right-bg2" src={BlueRightBg} alt='' />
                <Container>
                    <Row className='bloglist-list-row'>
                        <Col md='6'>
                            <img className="bloglist-img" src={articleTop} alt="Coming to Istanbul in 2023" />
                        </Col>
                        <Col md='6' className='bloglist-content'>
                            <h4>Feb 17, 2023</h4>
                            <h2>How Comdex is driving DeFi in Cosmos</h2>
                            <p>
                                In our last piece, we highlighted the advantages of building decentralized finance (DeFi) projects on Cosmos over building on popular...
                            </p>
                            <Button type="secondary" className="with-icon" href="https://blog.comdex.one/how-comdex-is-driving-defi-in-cosmos-67660de32474" target="_blank" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>Learn MOre</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="acticle-list">
                                <ArticleCard
                                    articleImg={articleImg1}
                                    name='Risk Assessment on Commodo'
                                    date='March 16, 2023'
                                    description='Commodo is a decentralized, open-source, IBC-native, collateralized lending-borrowing platform on Cosmos, built using the ....'
                                    link="https://blog.comdex.one/risk-assessment-on-commodo-6efcfb7fdd3e"
                                />
                                <ArticleCard
                                    articleImg={articleImg2}
                                    name='Introduction to cSwap — an interchain DEX on Comdex'
                                    date='March 10, 2023'
                                    description='Comdex’s easy plug-and-play modules will enable developers to easily create their own customizable decentralized applications (dApps)...'
                                    link="https://blog.comdex.one/introduction-to-cswap-an-interchain-dex-on-comdex-c132b2afbcfa"
                                />
                                <ArticleCard
                                    articleImg={articleImg3}
                                    name='Harbor Protocol: The Interchain Stablecoin...'
                                    date='March 3, 2023'
                                    description='In this piece, we’ll be providing an overview of Harbor Protocol: an interchain stablecoin protocol that enables the minting ....'
                                    link="https://medium.com/comdexofficial/harbor-protocol-the-interchain-stablecoin-protocol-unifying-liquidity-in-cosmos-defi-e5deee045c3a"
                                />
                                <ArticleCard
                                    articleImg={articleImg5}
                                    name='How decentralized stablecoins will...'
                                    date='Feb 23, 2023'
                                    description='Part 1 of this series explores why the Cosmos ecosystem is well-positioned for DeFi growth due to the customizability ...'
                                    link="https://medium.com/comdexofficial/how-decentralized-stablecoins-will-power-defi-in-cosmos-a81fd1825a15"
                                />
                                <ArticleCard
                                    articleImg={articleImg6}
                                    name='Why DeFi in Cosmos is the Future of Finance?'
                                    date='Feb 12, 2023'
                                    description='The recent fall of FTX highlights the fact that centralized systems are susceptible to the same counterparty risks as traditional....'
                                    link="https://medium.com/comdexofficial/why-defi-in-cosmos-is-the-future-of-finance-fc2fca7fb357"
                                />
                                <ArticleCard
                                    articleImg={articleImg4}
                                    name='Need for Composite $CMST Stablecoin'
                                    date='Sep 1, 2022'
                                    description='Cryptocurrencies are typically volatile assets, making them ineffective for payments as they could drastically change in value after a ...'
                                    link="https://blog.comdex.one/need-for-composite-cmst-stablecoin-13d8987bdbe6"
                                />
                            </div>

                            <a href="https://transit.comdex.one/" target="_blank" rel="noreferrer noopener">
                                <div className="airdrop-card">
                                    <img className="banner-img" src={GiftImg} alt="Banner" />
                                    {/* <div className="card-left">
                                        <h2>AIRDROP IS <b>LIVE!</b></h2>
                                    <p>Complete the mission, do magic transaction and claim your Airdrop...</p>
                                    <Button type="secondary"><SvgIcon name='double-arrow' viewbox='0 0 20 18' /> Claim Now</Button>
                                    </div>
                                    <div className="card-right">
                                        <img src={GiftImg} alt='Gift' />
                                    </div> */}
                                </div>
                            </a>

                            <div className="acticle-list">
                                <ArticleCard
                                    articleImg={articleImg7}
                                    name='Understanding $HARBOR Tokenomics'
                                    date='Nov 29, 2022'
                                    description='$HARBOR will serve as the governance token for managing policies on the protocol. HARBOR’s governance and tokenomics model ...'
                                    link="https://medium.com/comdexofficial/understanding-harbor-tokenomics-bf092eb50285"
                                />
                                <ArticleCard
                                    articleImg={articleImg8}
                                    name='Why isn’t $HARBOR collateral to mint $CMST?'
                                    date='Oct 19, 2022'
                                    description='Harbor protocol is an interchain stablecoin issuance protocol on the Comdex chain to mint Composite ($CMST), an over-collateralized...'
                                    link="https://medium.com/comdexofficial/why-isnt-harbor-collateral-to-mint-cmst-80066ed43d3e"
                                />
                                <ArticleCard
                                    articleImg={articleImg9}
                                    name='Risk Assessment on Harbor Protocol'
                                    date='Oct 6, 2022'
                                    description='Harbor protocol is an interchain stablecoin issuance protocol on the Comdex chain to mint Composite ($CMST), an over-collateralised....'
                                    link="https://medium.com/comdexofficial/risk-assessment-on-harbor-protocol-cbd228f00c6b"
                                />
                                <ArticleCard
                                    articleImg={articleImg10}
                                    name='Understanding Commodo — cPools, Liquidation, and much more'
                                    date='Sep 22, 2022'
                                    description='Commodo is an interchain liquidity protocol built on the Comdex chain to deliver decentralized, efficient & secure money markets ...'
                                    link="https://blog.comdex.one/understanding-commodo-cpools-liquidation-and-much-more-3cd25b72288a"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.gg/comdexofficial' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Blogs;
