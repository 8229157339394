/* eslint-disable jsx-a11y/no-distracting-elements */
import { Button, Divider } from "antd";
import gsap from "gsap";
import React, { useRef, useEffect, useState } from "react";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import ArticleCard from "../../components/common/ArticleCard";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Slider from "react-slick";
import Marquee from "react-fast-marquee";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import "./index.less";
import TextTransition, { presets } from 'react-text-transition';

import cosmwasmLogo from '../../assets/images/cosmwasm.svg';
import PressImage10 from '../../assets/images/press-10.png';
import PressImage11 from '../../assets/images/press-11.png';
import PressImage12 from '../../assets/images/press-12.png';
import PressImage2 from '../../assets/images/press-2.png';
import PressImage4 from '../../assets/images/press-4.png';
import PressImage5 from '../../assets/images/press-5.png';
import PressImage6 from '../../assets/images/press-6.png';
import PressImage7 from '../../assets/images/press-7.png';
import PressImage8 from '../../assets/images/press-8.png';
import PressImage9 from '../../assets/images/press-9.png';
import Validator1 from '../../assets/images/validator-1.png';
import Validator2 from '../../assets/images/validator-2.png';
import Validator3 from '../../assets/images/validator-3.png';
import Validator4 from '../../assets/images/validator-4.png';
import Validator5 from '../../assets/images/validator-5.png';
import Validator6 from '../../assets/images/validator-6.png';
import Validator7 from '../../assets/images/validator-7.png';
import Validator8 from '../../assets/images/validator-8.png';
import Validator9 from '../../assets/images/validator-9.png';
import Validator10 from '../../assets/images/validator-10.png';
import Validator11 from '../../assets/images/validator-11.png';
import Validator12 from '../../assets/images/validator-12.png';
import Validator13 from '../../assets/images/validator-13.png';
import Validator14 from '../../assets/images/validator-14.png';
import AkLogo from '../../assets/images/ak.png';
import articleImg1 from '../../assets/images/harbor_protocol_the_interchain.webp';
import articleImg2 from '../../assets/images/comdex_providing_defi.png';
import articleImg3 from '../../assets/images/how_cmdx_stakers_can.jpeg';
import bannerBg from '../../assets/images/home-banner-lines.png';
import LineLeftBg from '../../assets/images/line-bg-left2.png';
import BlueLeftBg from '../../assets/images/blue-texture-left2.png';
import RedRightBg from '../../assets/images/red-texture-right.png';
import RedRightBg2 from '../../assets/images/red-texture-right2.png';
import RocketAnimation from '../../assets/json/rocket.json';
import RedLeftBg from '../../assets/images/red-texture-left.png';
import bannerTop from '../../assets/images/banner-top.png';
import swapImg from '../../assets/images/swap-img.jpg';
import harborImg from '../../assets/images/harbor-img.jpg';
import commodoImg from '../../assets/images/commodo-img.jpg';
import forInvestorsImg from '../../assets/images/for-investors.jpg';
import forBorrowersImg from '../../assets/images/for-borrowers.jpg';
import complianceKycImg from '../../assets/images/compliance-kyc.jpg';
import cosmosSdkLogo from '../../assets/images/cosmos-sdk.svg';
import missionImg1 from '../../assets/images/mission-img1.jpg';
import missionImg2 from '../../assets/images/mission-img2.jpg';
import missionImg3 from '../../assets/images/mission-img3.jpg';
import missionImg4 from '../../assets/images/mission-img4.jpg';

const SocialLink = ({ name, text, iconName, iconViewbox, link }) => {
    return (
        <div className='social-link'>
            <div className='left-icon'>
                <SvgIcon name={iconName} viewbox={iconViewbox} />
            </div>
            <div className='right-details'>
                <Button onClick={() => {
                    let newWindow = window.open(`${link}`, "_blank");
                    newWindow.opener = null;
                }} icon={<SvgIcon name='arrow-link' viewbox='0 0 16 16' />} type="link" className="with-icon"><h4>{name}</h4></Button>
                <p>{text}</p>
            </div>
        </div>
    )
}

gsap.registerPlugin(ScrollTrigger);

const HomePage = () => {
    const [transactionCount, setTransactionCount] = useState(0);
    const [blockHeight, setBlockHeight] = useState(0)
    const [blockTime, setBlockTime] = useState(0)
    const [stakingApr, setStakingApr] = useState(0)
    const [index, setIndex] = useState(0);

    const marqueeRef = useRef(null);
    const marqueeRef2 = useRef(null);

    function handleMouseOver() {
        if (marqueeRef.current) {
            marqueeRef.current.stop();
        }
    }

    function handleMouseLeave() {
        if (marqueeRef.current) {
            marqueeRef.current.start();
        }
    }

    function handleMouseOver2() {
        if (marqueeRef2.current) {
            marqueeRef2.current.stop();
        }
    }

    function handleMouseLeave2() {
        if (marqueeRef2.current) {
            marqueeRef2.current.start();
        }
    }

    const component = useRef();
    const slider = useRef();
    const animationLottie1 = {
        loop: true,
        autoplay: true,
        animationData: RocketAnimation,
        renderer: 'svg',
    }

    const fetchTransactionCount = () => {
        axios
            .get("https://tx.comdex.one/tx/v1/comdex/status")
            .then((result) => {
                setTransactionCount(result?.data?.total_txs_num)
            })
            .catch((error) => {
                console.log(error?.message, "Transaction api");
            });
    };

    const fetchBlockHeight = () => {
        axios
            .get("https://tx.comdex.one/tx/v1/comdex/status")
            .then((result) => {
                setBlockHeight(result?.data?.block_height)
            })
            .catch((error) => {
                console.log(error?.message, "Blockheight Api")
            });
    };

    const fetchBlockTime = () => {
        axios
            .get("https://tx.comdex.one/tx/v1/comdex/status")
            .then((result) => {
                setBlockTime(Number(result?.data?.block_time || 0).toFixed(2))
            })
            .catch((error) => {
                console.log(error?.message, "Block Time")
            });
    };

    const fetchStakingAPR = () => {
        axios
            .get("https://chains.cosmos.directory/comdex")
            .then((result) => {
                setStakingApr(Number((result?.data?.chain?.params?.estimated_apr || 0) * 100 || 0).toFixed(2))
            })
            .catch((error) => {
                console.log(error?.message, "Staking Apr")
            });
    };

    useEffect(() => {
        fetchTransactionCount()
        fetchBlockHeight()
        fetchBlockTime()
        fetchStakingAPR()
    }, [])

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true
    };

    const settingsInside = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2500,
        slidesToShow: 1,
        slidesToScroll: 2,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const TEXTS = ['Tokenization', 'Collateralisation', 'Exchange', 'Borrowing', "Lending", 'Fractionalisation'];

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <div className="homepage-wrapper" ref={component}>
            <img className="bannertop" src={bannerTop} alt='' />
            <section className="banner-section" style={{ backgroundImage: `url(${bannerBg})` }}>
                <Container>
                    <Row className="banner-row">
                        <Col>
                            {/* <h1>Interchain RWA Infrastructure <br /> for Tokenization</h1> */}
                            <h1>Interchain RWA Infrastructure for <br />  <span> <TextTransition className="home_animation_text" springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition></span>     </h1>
                            <div className="text-center">
                                <a href="#introducingSection" rel="noreferrer"><Button type="primary" size="large">Explore</Button></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <img className="rightred-bg1" src={RedRightBg2} alt='' />
            </section>
            <section className="inside-section">
                <img className="rightred-bg1" src={RedRightBg} alt='' />
                <Row>
                    <Col>
                        <div className="headings">
                            <p>Inside</p>
                            <h1>Modular Architecture</h1>
                            <div className="divider"></div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Marquee pauseOnHover="true">
                            <div className="slider-row">
                                <div className="inside-card">
                                    <SvgIcon name='asset-tokenization' viewbox="0 0 40 42" />
                                    <h3>Asset Tokenization</h3>
                                    <p>
                                        Facilitates the conversion of tangible Real world assets into valuable on-chain NFTs.
                                    </p>
                                </div>
                                <div className="inside-card">
                                    <SvgIcon name='collateralisation' viewbox="0 0 35 43" />
                                    <h3>Collateralisation</h3>
                                    <p>
                                        Provides a mechanism for creation of debt assets through collateralisation of whitelisted assets.
                                    </p>
                                </div>
                                <div className="inside-card">
                                    <SvgIcon name='lending' viewbox="0 0 47 42" />
                                    <h3>Lending</h3>
                                    <p>
                                        Enables issuance of decentralised credit through Real world asset-backed lending pools.
                                    </p>
                                </div>
                                <div className="inside-card">
                                    <SvgIcon name='borrowing' viewbox="0 0 46 42" />
                                    <h3>Borrowing</h3>
                                    <p>
                                        Streamlines the borrowing process, enabling direct liquidity access through secured digital assets on-chain.
                                    </p>
                                </div>
                                <div className="inside-card">
                                    <SvgIcon name='exchange' viewbox="0 0 40 42" />
                                    <h3>Exchange</h3>
                                    <p>
                                        Enables direct and secure exchange of assets, with immediate peer to peer
                                        settlements on-chain.
                                    </p>
                                </div>
                                <div className="inside-card">
                                    <SvgIcon name='oracle-icon' viewbox="0 0 43 43" />
                                    <h3>Oracle</h3>
                                    <p>
                                        Fetches and validates asset prices by initiating requests to external oracle ensuring accuracy and reliability.
                                    </p>
                                </div>
                                <div className="inside-card">
                                    <SvgIcon name='auctions-icon' viewbox="0 0 43 43" />
                                    <h3>Auctions</h3>
                                    <p>
                                        Facilitates diverse auction types on the chain, enabling smooth liquidation across applications. Supports multiple auction models to meet different needs.
                                    </p>
                                </div>
                                <div className="inside-card">
                                    <SvgIcon name='rewards-icon-alt' viewbox="0 0 44 44" />
                                    <h3>Rewards</h3>
                                    <p>
                                        Enables distribution of variety of incentives throughout the ecosystem, enhancing engagement and participation.
                                    </p>
                                </div>
                            </div>
                        </Marquee>
                    </Col>
                </Row>
            </section>
            {/* <section className="statics-section">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='transactions' viewbox='0 0 14.5 21.5' />
                                            <h2>{commaSeparator(transactionCount || 0)}</h2>
                                            <p>No. of Transactions</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='block-height' viewbox='0 0 21 21' />
                                            <h2>{commaSeparator(blockHeight || 0)}</h2>
                                            <p>Block Height</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='block-time' viewbox='0 0 14.5 21.5' />
                                            <h2>{commaSeparator(blockTime || 0)}s</h2>
                                            <p>Block Time</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='transaction-fee' viewbox='0 0 14.5 21.5' />
                                            <h2>{stakingApr || 0}%</h2>
                                            <p>Staking APR</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <section className="introducing-section" id="introducingSection" ref={slider}>
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <p>Discover</p>
                                <h1>DeFi Ecosystem</h1>
                                <div className="divider"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="discover-list">
                                <div className="discover-list-card">
                                    <div className="discover-card-img">
                                        <img src={swapImg} alt={swapImg} />
                                    </div>
                                    <div className="discover-card-bottom">
                                        <h3>cSwap</h3>
                                        <div className="socil-links">
                                            <a href="https://twitter.com/cSwap_DEX" target="_blank"><SvgIcon name="twitter" viewbox="0 0 55.819 46.915" /></a>
                                            <a href=" https://t.me/cSwap_DEX" target="_blank"><SvgIcon name="telegram" viewbox="0 0 54.209 46.163" /></a>
                                        </div>
                                        <p>
                                            cSwap is an IBC-enabled hybrid dex built on the Comdex chain. cSwap uses AMM liquidity pools which bring cross-chain markets and limit orders to DeFi.
                                        </p>
                                    </div>
                                </div>
                                <div className="discover-list-card">
                                    <div className="discover-card-img">
                                        <img src={harborImg} alt={harborImg} />
                                    </div>
                                    <div className="discover-card-bottom">
                                        <h3>Harbor</h3>
                                        <div className="socil-links">
                                            <a href="https://twitter.com/Harbor_Protocol " target="_blank"><SvgIcon name="twitter" viewbox="0 0 55.819 46.915" /></a>
                                            <a href="https://t.me/Composite_Money" target="_blank"><SvgIcon name="telegram" viewbox="0 0 54.209 46.163" /></a>
                                        </div>
                                        <p>
                                            Harbor Protocol is the dApp on the Comdex chain (powered by the Cosmos SDK and CosmWasm smart contracts) that enables safelisted assets to be locked in Vaults and mint Composite.
                                        </p>
                                    </div>
                                </div>
                                <div className="discover-list-card">
                                    <div className="discover-card-img">
                                        <img src={commodoImg} alt={commodoImg} />
                                    </div>
                                    <div className="discover-card-bottom">
                                        <h3>Commodo</h3>
                                        <div className="socil-links">
                                            <a href="https://twitter.com/Commodo_Finance " target="_blank"><SvgIcon name="twitter" viewbox="0 0 55.819 46.915" /></a>
                                            <a href="https://t.me/commodo_finance " target="_blank"><SvgIcon name="telegram" viewbox="0 0 54.209 46.163" /></a>
                                        </div>
                                        <p>
                                            Commodo is a decentralised, open-source, IBC-native, collateralized lending-borrowing platform on Cosmos.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="rwaverview-section">
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <h1>RWA Overview</h1>
                                <div className="divider"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <img className="card-img" src={forInvestorsImg} alt='' />
                                    <div className="upper-section">
                                        <small>01</small>
                                        <h3>For Investors</h3>
                                        <p>
                                            Offers real revenue-based yields and enables DeFi participants to gain enhanced exposure to tangible asset-backed yields, broadening investment avenues on-chain.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <img className="card-img" src={forBorrowersImg} alt='' />
                                    <div className="upper-section">
                                        <small>02</small>
                                        <h3>For Borrowers</h3>
                                        <p>
                                            Empowers institutions to borrow by leveraging real-world assets as collateral, providing a straightforward path to accessing liquidity and capital.                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <img className="card-img" src={complianceKycImg} alt='' />
                                    <div className="upper-section">
                                        <small>03</small>
                                        <h3>Compliance & KYC</h3>
                                        <p>
                                            Offers a comprehensive infrastructure for regulatory compliance and KYC, providing essential screening to ensure client trust and security to investors.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="about-section">
                <img className="leftblue-bg1" src={BlueLeftBg} alt='' />
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <p>TECHNOLOGY</p>
                                <h1>Powering Comdex</h1>
                                <div className="divider"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='powering-row'>
                        <Col md='6' className='left-col'>
                            <div className="logos-row">
                                <img src={cosmosSdkLogo} alt={cosmosSdkLogo} />
                                <Divider type="vertical" />
                                <img src={cosmwasmLogo} alt={cosmwasmLogo} />
                            </div>
                            <h1>Built on Cosmos SDK</h1>
                            <h3>{"&"}</h3>
                            <h1>Interoperable Smart Contracts</h1>
                            <p className="mb-5">IBC-enabled DeFi Infrastructure Layer for building an interoperable ecosystem of solutions</p>
                            <Button type="secondary" onClick={() => {
                                let newWindow = window.open("https://blog.comdex.one/comdex-integrates-cosmwasm-e819b4502deb", "_blank");
                                newWindow.opener = null;
                            }}>Learn More</Button>
                        </Col>
                        <Col md='6' className='right-col'>
                            <Divider type="vertical" />
                            <div>
                                <h4>Validators</h4>
                            </div>
                            <ul>
                                <li>
                                    <img src={Validator1} alt={Validator1} />
                                </li>
                                <li>
                                    <img src={Validator2} alt={Validator2} />
                                </li>
                                <li>
                                    <img src={Validator3} alt={Validator3} />
                                </li>
                                <li>
                                    <img src={Validator4} alt={Validator4} />
                                </li>
                                <li>
                                    <img src={Validator5} alt={Validator5} />
                                </li>
                                <li>
                                    <img src={Validator6} alt={Validator6} />
                                </li>
                                <li>
                                    <img src={Validator7} alt={Validator7} />
                                </li>
                                <li>
                                    <img src={Validator8} alt={Validator8} />
                                </li>
                                <li>
                                    <img src={Validator9} alt={Validator9} />
                                </li>
                                <li>
                                    <img src={Validator10} alt={Validator10} />
                                </li>
                                <li>
                                    <img src={Validator11} alt={Validator11} />
                                </li>
                                <li>
                                    <img src={Validator12} alt={Validator12} />
                                </li>
                                <li>
                                    <img src={Validator13} alt={Validator13} />
                                </li>
                                <li>
                                    <img src={Validator14} alt={Validator14} />
                                </li>
                            </ul>
                            <a href="https://www.mintscan.io/comdex/validators" target="_blank" rel="noreferrer noopener"><h3>+75 More</h3></a>
                            <div>
                                <a href="https://github.com/oak-security/audit-reports/tree/master/Comdex" target="_blank" rel="noreferrer noopener"><h4>Security Audits <SvgIcon name="arrow-right" viewbox="0 0 15 15" /></h4></a>
                            </div>
                            <img className="ak-logo" src={AkLogo} alt={AkLogo} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="developers-section">
                <img className="rightred-bg1" src={RedRightBg} alt='' />
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <p>DEVELOPERS</p>
                                <h1>Why build on Comdex</h1>
                                <div className="divider"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="icon-circle">
                                        <SvgIcon name='lock' viewbox='0 0 26 34' />
                                    </div>
                                    <h3>Secure</h3>
                                    <p>
                                        Build secure DeFi applications by leveraging the security of the Comdex chain, powered by the CMDX token. Built using Cosmos SDK and Cosmwasm, users can utilise modules on the Comdex chain to seamlessly deploy dApps to the chain.
                                    </p>
                                </li>
                                <li>
                                    <div className="icon-circle">
                                        <SvgIcon name='dev-friendly' viewbox='0 0 27 33' />
                                    </div>
                                    <h3>Dev-Friendly</h3>
                                    <p>
                                        An all encompassing DeFi focused chain to ensure all governance and development is designed for optimal synergy between all the application built on the chain.
                                    </p>
                                </li>
                                <li>
                                    <div className="icon-circle">
                                        <SvgIcon name='composable' viewbox='0 0 32 32' />
                                    </div>
                                    <h3>Composable</h3>
                                    <p>
                                        All dApps built on the Comdex chain are fully interoperable across the IBC and composable to enhance DeFi user experience and deliver seamless access to the ecosystem of products.
                                    </p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="discover-section">
                <img className="leftline-1" src={LineLeftBg} alt='' />
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <p>DISCOVER</p>
                                <h1>Our Mission</h1>
                                <div className="divider"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='discover-row'>
                        <Col lg='12' className='right-col'>
                            <Row>
                                <Col md='5' className='mb-4 pb-1'>
                                    <div className="right-card">
                                        <img className="card-img" src={missionImg1} alt="" />
                                        <div className="right-card-inner">
                                            <div className="left-icon">
                                                <div className="left-icon-inner">
                                                    <SvgIcon name='democratize-finance' viewbox='0 0 33.75 33.37' />
                                                </div>
                                            </div>
                                            <div className="right-col">
                                                <h3>Democratizing Finance</h3>
                                                <p>
                                                    Democratizing access to capital and ensuring equal participation in finance by aggregating liquidity across markets.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md='7' className='mb-4 pb-1'>
                                    <div className="right-card">
                                        <img className="card-img" src={missionImg2} alt="" />
                                        <div className="right-card-inner">
                                            <div className="left-icon">
                                                <div className="left-icon-inner">
                                                    <SvgIcon name='disintermediation-icon' viewbox="0 0 30 26" />
                                                </div>
                                            </div>
                                            <div className="right-col">
                                                <h3>Disintermediation</h3>
                                                <p>
                                                    Streamline financial systems by removing intermediaries, reducing costs, and enhancing transaction throughput.                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md='7' className='mb-4 pb-1'>
                                    <div className="right-card">
                                        <img className="card-img" src={missionImg3} alt="" />
                                        <div className="right-card-inner">
                                            <div className="left-icon">
                                                <div className="left-icon-inner">
                                                    <SvgIcon name='search' viewbox="0 0 27 27.007" />
                                                </div>
                                            </div>
                                            <div className="right-col">
                                                <h3>Transparency & Compliance</h3>
                                                <p>
                                                    Our on-chain KYC-compliant system upholds transparency and trust with verifiable, secure transactions, ensuring accountable on-chain liquidity.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md='5' className='mb-4 pb-1'>
                                    <div className="right-card">
                                        <img className="card-img" src={missionImg4} alt="" />
                                        <div className="right-card-inner">
                                            <div className="left-icon">
                                                <div className="left-icon-inner">
                                                    <SvgIcon name='bridging-icon' viewbox="0 0 27 27" />
                                                </div>
                                            </div>
                                            <div className="right-col">
                                                <h3>Bridging TradFi and DeFi</h3>
                                                <p>
                                                    Unlock seamless movement of capital between DeFi and Traditional markets, providing efficiency and borderless transactions with enhanced liquidity.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="pressrelease-section">
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <p>recognization</p>
                                <h1>Press Features</h1>
                                <div className="divider"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Row className='pressrelease-row'>
                    <Col>
                        <Marquee pauseOnHover="true">
                            <div className="pressrelease-row-inner">
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://cryptonews.net/news/defi/2770571/", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage2} alt={PressImage2}
                                    />
                                </div>
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://www.crowdfundinsider.com/2022/04/188653-abhishek-singh-comdex-ceo-talks-about-the-potential-of-commodities-market-for-retail-investors/", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage4} alt={PressImage4}
                                    />
                                </div>
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://www.forbes.com/sites/lawrencewintermeyer/2022/03/25/crypto-trading-will-grow-in-2022-as-dfmi-matures-and-drives-efficiencies/?sh=1ca1e0f17953", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage5} alt={PressImage5} />
                                </div>
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://www.ibtimes.com/defi-nfts-metaverse-indian-startups-are-ruling-blockchain-game-3436730", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage6} alt={PressImage6} />
                                </div>
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://hackernoon.com/when-defi-meets-tradefi-comdex-co-founder-abhishek-singh-discusses-commodities-in-crypto", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage7} alt={PressImage7} />
                                </div>
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://techbullion.com/the-case-for-commodities-as-an-asset-class-how-comdex-is-reimagining-it/", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage8} alt={PressImage8} />
                                </div>
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://www.fool.com/investing/2022/03/05/defi-ceo-how-decentralized-synthetics-could-democr/", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage9} alt={PressImage9} />
                                </div>
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://www.newsbtc.com/news/company/how-a-game-changing-decentralized-synthetic-exchange-aims-to-unlock-the-true-value-of-commodities-and-digital-assets-on-chain/", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage10} alt={PressImage10} />
                                </div>
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://www.techtimes.com/amp/articles/269635/20211221/comdex-s-platform-bridges-cefi-defi-gap-through-portfolio-diversification.htm", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage11} alt={PressImage11} />
                                </div>
                                <div>
                                    <img onClick={() => {
                                        let newWindow = window.open("https://www.nasdaq.com/articles/is-trust-a-necessary-prerequisite-to-access-market-liquidity", "_blank");
                                        newWindow.opener = null;
                                    }} src={PressImage12} alt={PressImage12} />
                                </div>
                            </div>
                        </Marquee>
                    </Col>
                </Row>
            </section>
            <section className="article-section">
                <img className="left-bg" src={RedLeftBg} alt='' />
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <p>explore</p>
                                <h1>Recent Articles</h1>
                                <div className="divider"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="acticle-list">
                                <ArticleCard
                                    // articleImg={articleImg1}
                                    articleImg={"https://miro.medium.com/v2/resize:fit:1400/format:webp/0*uQTU0fZY9ZGiF_6w"}
                                    name='Bringing sustainable DeFi to Cosmos'
                                    date='Jun 28, 2022'
                                    description='The Cosmos ecosystem has seen tremendous growth in the past year, led by a strong community of buidlers...'
                                    link="https://blog.comdex.one/bringing-sustainable-defi-to-cosmos-7a62e06be5ff"
                                />
                                <ArticleCard
                                    // articleImg={articleImg2}
                                    articleImg={"https://miro.medium.com/v2/resize:fit:1400/format:webp/0*TmMFqiBrNv__u4nH"}
                                    name='Comdex: Providing a DeFi Infrastructure Layer...'
                                    date='May 04, 2022'
                                    description='On March 24th, we held our first community Town Hall event. Validators, community members & the Comdex team....'
                                    link="https://blog.comdex.one/comdex-providing-a-defi-infrastructure-layer-for-the-cosmos-ecosystem-af706b7f2d3c"
                                />
                                <ArticleCard
                                    // articleImg={articleImg3}
                                    articleImg={"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*7Qyb7b3aOb5TJ8vb8-SvUw.png"}
                                    name='Comdex Hackathon Powered by Dorahacks'
                                    date='Jun 13, 2023'
                                    description='Welcome to the inaugural Hackathon by Comdex on Dorahacks! This Hackathon is all about crafting ingenious...'
                                    link="https://blog.comdex.one/comdex-hackathon-powered-by-dorahacks-c5d2db212f88"
                                />
                            </div>
                            <div className="text-center mt-5 pt-3">
                                <Button type="secondary" href="https://blog.comdex.one/" target="_blank">View All</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="social-section">
                <img className="leftblue-bg1" src={BlueLeftBg} alt='' />
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <p>our community</p>
                                <h1>Join the Community</h1>
                                <div className="divider"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center mt-5 pt-4'>
                        <Col md='6' lg='4'>
                            <SocialLink name="Twitter"
                                iconName="twitter_side"
                                iconViewbox="0 0 55.819 46.915"
                                text="Stay updated about the latest happening in Comdex."
                                link="https://twitter.com/ComdexOfficial" />
                        </Col>
                        <Col md='6' lg='4'>
                            <SocialLink name="Telegram"
                                iconName="telegram"
                                iconViewbox="0 0 54.209 46.163"
                                text="Connect, discuss & learn with the other community members."
                                link="https://t.me/ComdexChat" />
                        </Col>
                        <Col md='6' lg='4'>
                            <SocialLink name="Discord"
                                iconName="discord"
                                iconViewbox="0 0 65.002 49.503"
                                text="Participate in discussions & report bugs in Comdex Ecosystem products."
                                link="https://discord.gg/comdexofficial" />
                        </Col>
                        <Col md='6' lg='4'>
                            <SocialLink name="Medium"
                                iconName="medium"
                                iconViewbox="0 0 58.333 48"
                                text="Read blogs & articles about Comdex Ecosystem."
                                link="https://blog.comdex.one/" />
                        </Col>
                        <Col md='6' lg='4'>
                            <SocialLink name="Github"
                                iconName="github"
                                iconViewbox="0 0 60 59.495"
                                text="Keep in check with Comdex Developments."
                                link="https://github.com/comdex-official" />
                        </Col>
                        <Col md='6' lg='4'>
                            <SocialLink name="Forum"
                                iconName="forum"
                                iconViewbox="0 0 27.205 27.205"
                                text="Participate in discussions for governance and key decision-making."
                                link="https://forum.comdex.one/ " />
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default HomePage
