import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const FunctionsInGo = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'DEV LESSONS BASICS',
                                        href: 'dev-lessons-basics',
                                    },
                                    {
                                        title: 'FUNCTIONS IN GO',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Functions in Go</h1>
                                <p>
                                    In this lesson, we will cover functions in Go, including how to declare and call functions, pass arguments to functions, and return values from functions.
                                </p>

                                <div className="learn-module">
                                    <h1>Lesson Objectives:</h1>
                                    <div className="learn-m-card">
                                        <p>By the end of this lesson, you should be able to:</p>
                                        <ul>
                                            <li>
                                                Understand how to declare and call functions in Go.
                                            </li>
                                            <li>
                                                Pass arguments to functions in Go.
                                            </li>
                                            <li>
                                                Return values from functions in Go.
                                            </li>
                                        </ul>
                                    </div>

                                    <h3>Declaring and Calling Functions</h3>

                                    <p>
                                        Functions in Go are declared using the func keyword followed by the function name and the parameter list enclosed in parentheses. If the function returns a value, the return type is specified after the parameter list.
                                    </p>


                                    <p>Here is an example of a function that takes two integer arguments and returns their sum:</p>
                                    <CodeSnippet
codeData={`func add(x int, y int) int {    
    return x + y
    }`}
/>
                                    <p>To call this function, you simply provide the arguments in the order they are declared in the function definition:</p>
                                    <CodeSnippet
codeData={`sum := add(3, 5)
fmt.Println(sum) // Output: 8`}
/>

                                    <h3>Passing Arguments to Functions</h3>

                                    <p>
                                        In Go, function arguments can be passed by value or by reference. By value means that a copy of the value is passed to the function, while by reference means that a reference to the original value is passed to the function.
                                    </p>

                                    <p>Here is an example of a function that takes a slice of integers and modifies it in place:</p>
                                    <CodeSnippet
codeData={`func doubleValues(values []int) {    
    for i := 0; i < len(values); i++ {        
        values[i] *= 2    
    }
}`}
/>
                                    <p>
                                        To call this function, you simply pass a slice of integers:
                                    </p>
                                    <CodeSnippet
codeData={`numbers := []int{1, 2, 3, 4}
doubleValues(numbers)
fmt.Println(numbers) // Output: [2 4 6 8]`}
/>

                                    <p>Note that since slices are reference types in Go, the function modifies the original slice.</p>

                                    <h3>Returning Values from Functions</h3>
                                    <p>
                                        In Go, functions can return multiple values. The return type is specified after the parameter list, and the values are separated by commas in the return statement.
                                    </p>

                                    <p>Here is an example of a function that returns both the minimum and maximum values of a slice of integers:</p>
                                    <CodeSnippet
codeData={`func minMaxValues(values []int) (int, int) {    
    min, max := values[0], values[0]    
    for _, v := range values {        
        if v < min {            
            min = v        
        }        
        if v > max {            
            max = v        
            }    
        }    
        return min, max
    }`}
/>

                                    <p>
                                        To call this function, you can assign the return values to variables:
                                    </p>

                                    <p>goCopy code</p>
                                    <CodeSnippet
codeData={`numbers := []int{5, 2, 9, 1, 7}
min, max := minMaxValues(numbers)
fmt.Printf("Min: %d, Max: %d\n", min, max) // Output: Min: 1, Max: 9`}
/>

                                    <h3>Conclusion</h3>
                                    <p>
                                        In this lesson, we covered the basics of functions in Go, including how to declare and call functions, pass arguments to functions, and return values from functions. With this knowledge, you should be able to write your own functions and use them in your Go programs.
                                    </p>

                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/arrays-and-slices'><b>Intermidaite Go Programming.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default FunctionsInGo;