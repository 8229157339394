import { Button, Input } from "antd";
import React from "react";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import "./index.less";

import Eventbg1 from '../../assets/images/event-bg1.png';
import EventImg1 from '../../assets/images/event-1.jpg';
import EventImg2 from '../../assets/images/event-2.jpg';
import EventImg3 from '../../assets/images/event-3.jpg';
import TopBlueBg from '../../assets/images/blue-texture-left.png';
import RedRightBg from '../../assets/images/red-texture-right2.png';
import BlueLeftBg from '../../assets/images/blue-texture-left2.png';
import { Link } from "react-router-dom";

const Events = () => {
    
    return (
        <div className="homepage-wrapper eventslist-wrapper">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col sm='7'>
                            <h1>Events</h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <img className="eventbanner-bg" src={Eventbg1} alt={Eventbg1} />
            </section>
            <section>
                <Container>
                    <Row>
                        <Col className='text-right filter-row'>
                            <Input placeholder="Search.." suffix={<SvgIcon name='search' viewbox='0 0 27 27.007' />} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="eventlist-section">
                <img className="red-right" src={RedRightBg} alt='' />
                <img className="blue-left" src={BlueLeftBg} alt='' />
                <Container>
                    <Row>
                        <Col sm='12' className='mb-4'>
                            <h2>Upcoming Events</h2>
                        </Col>
                    </Row>
                    <Row className='event-list-row'>
                        <Col md='6'>
                            <img className="event-img" src={EventImg1} alt="Coming to Istanbul in 2023" />
                        </Col>
                        <Col md='6' className='event-content'>
                            <h4>Coming to Istanbul in 2023</h4>
                            <h2>The legendary Cosmos Conference</h2>
                            <p>
                                We consider Istanbul a giant opportunity for Cosmos and are very excited to bring Cosmoverse to Türkiye and its 8 million potential users.
                            </p>
                            <p>
                                For more details & updates, follow us on Twitter and join our Telegram community chat: hhtp.hbububjh...
                            </p>
                            <Link to='/event-details'><Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button></Link>
                        </Col>
                    </Row>

                    <div className="past-events-list">
                        <Row>
                            <Col sm='12' className='mb-4'>
                                <h2>Past Events</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='6'>
                                <Row className='event-list-row'>
                                    <Col md='12'>
                                        <img className="event-img" src={EventImg1} alt="Coming to Istanbul in 2023" />
                                    </Col>
                                    <Col md='12' className='event-content'>
                                        <h4>Coming to Istanbul in 2023</h4>
                                        <h2>Cosmohgcytcghh bhcgdftcg</h2>
                                        <p>
                                            We consider Istanbul a giant opportunity for Cosmos and are very excited to bring Cosmoverse to Türkiye and its 8 million potential users.
                                        </p>
                                        <p>
                                            For more details & updates, follow us on Twitter and join our Telegram community chat: hhtp.hbububjh...
                                        </p>
                                        <Link to='/event-details'><Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button></Link>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md='6'>
                                <Row className='event-list-row'>
                                    <Col md='12'>
                                        <img className="event-img" src={EventImg2} alt="Coming to Istanbul in 2023" />
                                    </Col>
                                    <Col md='12' className='event-content'>
                                        <h4>Coming to Istanbul in 2023</h4>
                                        <h2>The legendary Cosmos Conference</h2>
                                        <p>
                                            We consider Istanbul a giant opportunity for Cosmos and are very excited to bring Cosmoverse to Türkiye and its 8 million potential users.
                                        </p>
                                        <p>
                                            For more details & updates, follow us on Twitter and join our Telegram community chat: hhtp.hbububjh...
                                        </p>
                                        <Link to='/event-details'><Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button></Link>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md='6'>
                                <Row className='event-list-row'>
                                    <Col md='12'>
                                        <img className="event-img" src={EventImg1} alt="Coming to Istanbul in 2023" />
                                    </Col>
                                    <Col md='12' className='event-content'>
                                        <h4>Coming to Istanbul in 2023</h4>
                                        <h2>The legendary Cosmos Conference</h2>
                                        <p>
                                            We consider Istanbul a giant opportunity for Cosmos and are very excited to bring Cosmoverse to Türkiye and its 8 million potential users.
                                        </p>
                                        <p>
                                            For more details & updates, follow us on Twitter and join our Telegram community chat: hhtp.hbububjh...
                                        </p>
                                        <Link to='/event-details'><Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button></Link>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md='6'>
                                <Row className='event-list-row'>
                                    <Col md='12'>
                                        <img className="event-img" src={EventImg3} alt="Coming to Istanbul in 2023" />
                                    </Col>
                                    <Col md='12' className='event-content'>
                                        <h4>Coming to Istanbul in 2023</h4>
                                        <h2>The legendary Cosmos Conference</h2>
                                        <p>
                                            We consider Istanbul a giant opportunity for Cosmos and are very excited to bring Cosmoverse to Türkiye and its 8 million potential users.
                                        </p>
                                        <p>
                                            For more details & updates, follow us on Twitter and join our Telegram community chat: hhtp.hbububjh...
                                        </p>
                                        <Link to='/event-details'><Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button></Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.com/invite/7vjPvWKKMT' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Events;