import { Button, Modal } from "antd";
import React, { useState } from "react";
import { SvgIcon } from "../../../components/common";
import dexes6 from '../../../assets/images/leap.svg';
import dexes7 from '../../../assets/images/cosmostation.svg';
import keplrIcon from '../../../assets/images/keplr.svg';

const GetcmdxWalletsModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button type="secondary" onClick={showModal}>Choose Wallet</Button>
            <Modal width={700} title="Wallets" className="comdex-modal" centered open={isModalOpen} onCancel={handleCancel} footer={false} closeIcon={<SvgIcon name='close' viewbox='0 0 18 18' />}>
                <ul className="dexes-list">
                    <li><a href="https://www.leapwallet.io/" target="_blank" rel="noreferrer noopener"><img src={dexes6} alt='leap' /></a></li>
                    <li><a href="https://wallet.cosmostation.io/cosmos" target="_blank" rel="noreferrer noopener"><img src={dexes7} alt='cosmostation' /></a></li>
                    <li><a href="https://wallet.keplr.app/" target="_blank" rel="noreferrer noopener"><img src={keplrIcon} alt='keplr' /></a></li>
                </ul>
            </Modal>
        </>
    )
}

export default GetcmdxWalletsModal;