import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

import BuildGo from '../../../assets/images/build-simple.png';

const CosmosModuleOneFour = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'WHAT IS COSMOS',
                                    href: '',
                                },
                                {
                                    title: 'MODULE 1',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Setting up the development environment</h1>
                                <p>
                                    To start developing blockchain applications using the Cosmos SDK, you need to set up your development environment. This involves installing the required software, setting up a local blockchain network for testing and development, and configuring the SDK to work with your development environment.
                                </p>

                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                
                                <div className="learn-module">
                                    <h1>What will you learn in Module 1.4</h1>
                                    <div className="learn-m-card">
                                        <ul>
                                            <li>
                                               Understanding the benefits of using Cosmos SDK for building blockchain applications
                                            </li>
                                            <li>
                                                Key features of Cosmos SDK such as modularity, flexibility, and interoperability
                                            </li>
                                            <li>
                                                Real-world use cases of Cosmos SDK
                                            </li>
                                        </ul>
                                    </div>

                                    <p>Here are the steps to set up your development environment:</p>

                                    <h3>1. Install the required software:</h3>
                                    <p>
                                        To start developing blockchain applications using the Cosmos SDK, you will need to install several software tools, including the Go programming language, Git version control system, and other development tools. These tools are essential for building and testing your applications. You can download and install these tools from their official websites.
                                    </p>

                                    <p>
                                        Go is the main programming language used in the Cosmos SDK, and it is used to write and compile smart contracts, create new blockchain applications, and build tools for interacting with the blockchain. Git is used for version control and allows you to track changes to your code over time.
                                    </p>

                                    <img className="w-100 mb-4" src={BuildGo} alt='BuildGo' />
                                    

                                    <div className="d-flex mb-5">
                                        <div className="mr-3">
                                            <p>Useful Link : </p>
                                        </div>
                                        <div>
                                            <p>
                                                Go - https://go.dev/ <br />
                                                Go - https://go.dev/ <br />
                                                Go - https://go.dev/
                                            </p>
                                        </div>
                                    </div>

                                    <h3>2. Set up a local blockchain network:</h3>
                                    
                                    <p>
                                        After you have installed the required software, the next step is to set up a local blockchain network for testing and development purposes. This can be done using the gaiacli tool provided by the Cosmos SDK. The gaiacli tool is a command-line interface that allows you to create and manage your local blockchain network.
                                    </p>
                                    <p>
                                        You can follow the official Cosmos SDK documentation to set up a local network and create a test account. This involves setting up the gaiacli tool, creating a new chain, and configuring your network parameters. You can use this local network to test your applications and interact with the blockchain.
                                    </p>

                                    <h3>3. Configure the SDK:</h3>
                                    <p>
                                        Once you have set up your local blockchain network, you need to configure the Cosmos SDK to work with your development environment. This involves setting up your environment variables, including your GOPATH, GOBIN, and PATH. These variables are used by the Go compiler to locate and compile your code.
                                    </p>
                                    <p>
                                        You can also configure your network parameters using the config.toml file. This file contains various configuration options for your network, such as the consensus algorithm used, the block time, and other parameters. You can customize these options to suit your needs.
                                    </p>

                                    <h3>4. Development tools and resources:</h3>
                                    <p>
                                        There are several development tools and resources available for working with the Cosmos SDK. These include the Cosmos SDK documentation, which provides detailed information on how to use the SDK, and the Cosmos SDK GitHub repository, which contains sample code and other resources.
                                    </p>
                                    <p>
                                        You can also use development tools such as Visual Studio Code (VSCode) and Goland for writing and debugging your code. These tools provide features such as code highlighting, auto-completion, and debugging, which can help you write high-quality code more efficiently. In summary, setting up your development environment is a crucial step in building blockchain applications using the Cosmos SDK. 
                                    </p>
                                    <p>
                                        By installing the necessary software, setting up a local blockchain network, configuring the SDK, and using the available development tools and resources, you can start building your blockchain applications and testing them in a local environment.
                                    </p>
                                    
                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with the first section <Link to='/'><b>Building a Basic Cosmos Application.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmosModuleOneFour;