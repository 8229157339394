import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const CreateComdexModule = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'CREATE YOUR OWN COMDEX MODULE',
                                        href: 'dev-lessons-advance',
                                    },
                                    {
                                        title: 'MODULE STRUCTURE',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Creating a structure of the application</h1>
                                <p>
                                    To create a structure for a blockchain application that enables users to lend and borrow digital assets from each other, use the Ignite CLI to generate the necessary code.
                                </p>

                                <p>Create a module with a dependency on the standard Cosmos SDK bank module by running the following command:</p>

                                <div className="learn-module">
                                <CodeSnippet
codeData={`ignite scaffold module loan --dep bank`}
/>

                                    <p>Now, we will create our proto:</p>
                                    <CodeSnippet
codeData={`message Loan {
    uint64 id = 1 ;
    uint64 app_id = 2
    [(gogoproto.customname) = "AppId",
        (gogoproto.moretags) = "yaml:\"app_id\""];
    
    uint64 pair_id = 3 [
        (gogoproto.customname) = "PairID",    
        (gogoproto.moretags) = "yaml:\"pair_id\""];
    
    string owner = 4 [(gogoproto.moretags) = "yaml:\"owner\""];
    string amount_in = 5 [    
        (gogoproto.customtype) = "github.com/cosmos/cosmos-sdk/types.Int",    
        (gogoproto.moretags) = "yaml:\"amount_in\"",    
        (gogoproto.nullable) = false
    ];
    string amount_out = 6 [    
        (gogoproto.customtype) = "github.com/cosmos/cosmos-sdk/types.Int",    
        (gogoproto.moretags) = "yaml:\"amount_out\"",    
        (gogoproto.nullable) = false
    ];
    string fee = 7 [    
        (gogoproto.customtype) = "github.com/cosmos/cosmos-sdk/types.Int",    
        (gogoproto.moretags) = "yaml:\"fee\"",    
        (gogoproto.nullable) = false
    ];
    google.protobuf.Timestamp created_at = 8 [    
        (gogoproto.nullable) = false,    
        (gogoproto.stdtime) = true,    
        (gogoproto.moretags) = "yaml:\"created_at\""
    ];
    int64 duration_days = 9 [    
        (gogoproto.moretags) = "yaml:\"duration_days\""
    ];
    string lender = 10 [(gogoproto.moretags) = "yaml:\"owner\""];
    string state = 11 [(gogoproto.moretags) = "yaml:\"state\""];
}`}
/>

                                    <p>Here, is the basic struct of our loan which any user can request.</p>

                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/importing-methods'><b>Importing Methods.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CreateComdexModule;