import { Button } from "antd";
import React from "react";
import { SvgIcon } from "../";
import "./index.less";

const ArticleCard = ({ articleImg, name, description, link, date }) => {
    return (
        <a href={link} target="_blank">
            <div className="article-card">
                <div className="article-card-inner">
                    <div className="article-card-img">
                        <img src={articleImg} alt={name} />
                    </div>
                    <div className="article-card-bottom">
                        <div className="datetime">
                            {date}
                        </div>
                        <h3>{name}</h3>
                        <p>{description}</p>
                        <Button onClick={() => { let newWindow = window.open(`${link}`, "_blank"); newWindow.opener = null; }} type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default ArticleCard