import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const ApproveRequest = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'CREATE YOUR OWN COMDEX MODULE',
                                        href: 'dev-lessons-advance',
                                    },
                                    {
                                        title: 'Approve a Loan',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Approve a Loan</h1>
                                <p>
                                    In the loan approval process, another account can approve a loan request made by a borrower and agree to the terms proposed. This involves transferring the requested funds from the lender to the borrower.
                                </p>

                                <p>
                                    For a loan to be eligible for approval, its status must be "requested," indicating that the borrower has requested a loan and is waiting for a lender to agree to the terms and provide the funds. Once a lender decides to approve the loan, they can transfer the funds to the borrower.
                                </p>

                                <p>
                                    After loan approval, the loan status changes to "approved," indicating that the funds have been successfully transferred and the loan agreement is now in effect.
                                </p>

                                <div className="learn-module">
                                <CodeSnippet
codeData={`func (k Keeper) Approve(ctx sdk.Context, loanID uint64, 
    lender string) error {    
        loan, found := k.GetLoan(ctx, loanID)    
        if !found {        
            return sdkerrors.Wrapf(sdkerrors.ErrKeyNotFound, "key %d doesn't exist for loanID", loanID)    
        }    
        if loan.State != "requested" {        
        return sdkerrors.Wrapf(types.ErrWrongLoanState, "%v", loan.State)    
        }    
    
        pair, found := k.Asset.GetPair(ctx, loan.PairID)    
        if !found {        
        return assettypes.ErrorPairDoesNotExist    
        }    
    
        assetOut, found := k.Asset.GetAsset(ctx, pair.AssetOut)    
        if !found {        
            return assettypes.ErrorAssetDoesNotExist    
        }    
    
        lenderAddr, err := sdk.AccAddressFromBech32(lender)    
        if err != nil {        
            return err    
        }    
    
        borrowerAddr, err := sdk.AccAddressFromBech32(loan.Owner)    
        if err != nil {        
            return err
        }
    
        err = k.bankKeeper.SendCoinsFromAccountToModule(ctx, lenderAddr, types.ModuleName, sdk.NewCoins(sdk.NewCoin(assetOut.Denom, loan.AmountOut)))    
        if err != nil {        
            return err    
        }    
    
        err = k.bankKeeper.SendCoinsFromModuleToAccount(ctx, types.ModuleName, borrowerAddr, sdk.NewCoins(sdk.NewCoin(assetOut.Denom, loan.AmountOut)))    
        if err != nil {        
            return err    
        }    
        loan.Lender = lender    
        loan.State = "approved"    
        return nil
    }`}
/>

                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/repay-request'><b>Repay Request.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ApproveRequest;