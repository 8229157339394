import { Breadcrumb, Button } from "antd";
import React from "react";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../../components/common";
import LeftNav from "../../LeftNav";
import "../../index.less";

const ExampleOfRustContract = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'Getting started with CosmWasm',
                                        href: 'gettingstarted-with-cosmWasm',
                                    },
                                    {
                                        title: 'Example of Rust Contract',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <div className="mb-5">
                                    <h1>Example of Rust Contract</h1>
                                
                                    <div className="mb-4 pt-3">
                                        <p>
                                            This is a very basic contract that will have a counter that can be increased, decreased, or queried. Once you have generated a template as mentioned above ,
                                        </p>
                                        <h3>1. Write Your Contract in src/contract.rs</h3>
                                        <p>Replace the contents of src/contract.rs with the following code</p>
                                        <CodeSnippet 
                                            codeData={`use cosmwasm_std::{

Binary, Deps, DepsMut, Env, MessageInfo, Response, StdError, StdResult, Uint128,
};

use crate::state::{read, store, State};

pub const COUNTER_KEY: &[u8] = b"counter";

pub fn instantiate(
    deps: DepsMut,
    _env: Env,
    _info: MessageInfo,
    _msg: InstantiateMsg,
) -> StdResult<Response> {
    let state = State { count: Uint128::zero() };
    store(deps.storage, COUNTER_KEY, &state)
}

pub fn execute(
    deps: DepsMut,
    env: Env,
    info: MessageInfo,
    msg: ExecuteMsg,
) -> StdResult<Response> {

    let mut state: State = read(deps.storage, COUNTER_KEY)?;


    match msg {
        ExecuteMsg::Increment {} => {
            state.count += Uint128::one();
            store(deps.storage, COUNTER_KEY, &state)?;
        }

        ExecuteMsg::Decrement {} => {
            if state.count.u128() > 0 {
                state.count -= Uint128::one();
            }
            store(deps.storage, COUNTER_KEY, &state)?;
        }
    }

    Ok(Response::default())
}

pub fn query(deps: Deps, _env: Env, msg: QueryMsg) -> StdResult<Binary> {
    let state: State = read(deps.storage, COUNTER_KEY)?;

    match msg {
        QueryMsg::GetCount {} => to_binary(&state),
    }
}
`}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <h3>2. Define Messages in src/msg.rs</h3>
                                        <p>Next, you'll need to define the messages that your contract accepts. Replace the contents of src/msg.rs with the following code:</p>
                                        <CodeSnippet 
                                            codeData={`use cosmwasm_std::Uint128;
use schemars::JsonSchema;
use serde::{Deserialize, Serialize};

#[derive(Serialize, Deserialize, Clone, Debug, PartialEq, JsonSchema)]
pub struct InstantiateMsg {
pub count: Uint128,
}

#[derive(Serialize, Deserialize, Clone, Debug, PartialEq, JsonSchema)]
#[serde(rename_all = "snake_case")]
pub enum ExecuteMsg {
Increment {},
Decrement {},
}

#[derive(Serialize, Deserialize, Clone, Debug, PartialEq, JsonSchema)]
#[serde(rename_all = "snake_case")]
pub enum QueryMsg {
GetCount {},
}`}
                                        />
                                    </div>
                                    
                                    <div className="mb-4">
                                        <h3>3. Define State in src/state.rs</h3>
                                        <p>You'll also need to define the state of your contract. Create a new file, src/state.rs, and add the following code:</p>
                                        <CodeSnippet 
                                            codeData={`use cosmwasm_std::{ReadonlyStorage, StdResult, Storage, Uint128};
use cosmwasm_storage::{singleton, singleton_read, ReadonlySingleton, Singleton};
use schemars::JsonSchema;
use serde::{Deserialize, Serialize};

pub static COUNTER_KEY: &[u8] = Item::new("counter");

#[derive(Serialize, Deserialize, Clone, Debug, PartialEq, JsonSchema)]
pub struct State {
pub count: Uint128,
}

pub fn store(storage: &mut dyn Storage, key: &[u8], item: &State) -> StdResult<()> {
Singleton::new(storage, key).save(item)
}

pub fn read(storage: &dyn Storage, key: &[u8]) -> StdResult<State> {
ReadonlySingleton::new(storage, key).load()
}`}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <h3>4. Write Query Code in query.rs</h3>
                                        <p>Here is a simple query code in query.rs file:</p>
                                        <CodeSnippet 
                                            codeData={`use cosmwasm_std::{Deps, StdResult, Binary, Env};
use crate::{msg::QueryMsg, state::{State, read}};

pub fn query(deps: Deps, _env: Env, msg: QueryMsg) -> StdResult<Binary> {
match msg {
QueryMsg::GetCount {} => query_count(deps),
}
}

fn query_count(deps: Deps) -> StdResult<Binary> {
let state: State = read(deps.storage, COUNTER_KEY)?;
to_binary(&state)
}`}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p>In summary, in a CosmWasm contract:</p>
                                        <ul className="list-2 pl-3">
                                            <li>contract.rs defines how the contract behaves.</li>
                                            <li>msg.rs defines the messages that the contract can accept.</li>
                                            <li>state.rs defines the state of the contract and how to interact with it.</li>
                                            <li>query.rs defines how to query the state of the contract</li>
                                        </ul>

                                        <p>
                                            That's it! This is a very basic CosmWasm contract, but it should give you an idea of how to structure your contracts. For more information on these use the following links:
                                        </p>

                                        <ul className="list-2 pl-3">
                                            <li>CosmWasm Docs : <a href="https://docs.cosmwasm.com/docs/" target="_blank" rel="noreferrer noopener">Link</a> </li>
                                            <li>Common CosmWasm Contract: <a href="https://github.com/CosmWasm/cw-plus" target="_blank" rel="noreferrer noopener">Link</a> </li>
                                            <li>Cosmwasm Book : <a href="https://book.cosmwasm.com/index.html" target="_blank" rel="noreferrer noopener">Link</a></li>
                                        </ul>
                                    </div>
                                    
                                </div>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ExampleOfRustContract;