import React from "react";
import { Collapse, Button } from "antd";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import Lottie from 'react-lottie';
import "./index.less";

import faqGlobe from '../../assets/images/faq-globe.png';
import TopBlueBg from '../../assets/images/blue-texture-left.png';
import FaqAnimation from '../../assets/json/FAQ.json';
import LineBgRight from '../../assets/images/line-bg-right.png';
import LineBgLeft from '../../assets/images/line-bg-left3.png';
import BlueBgLeft from '../../assets/images/blue-texture-left.png';

const { Panel } = Collapse;

const Faq = () => {
    const animationLottie1 = {
        loop: true,
        autoplay: true,
        animationData: FaqAnimation,
        renderer: 'svg',
    }
    return (
        <div className="homepage-wrapper faq-wrapper">
            <section className="banner-section faq-banner">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col md='7'>
                            <h1>Frequently asked <br /> questions</h1>
                        </Col>
                        <Col md='5'>
                            <Lottie
                                options={animationLottie1}
                                height={550}
                                width={660}
                                style={{ maxWidth: '100%' }}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="faq-list">
                <img className="faq-globe" src={faqGlobe} alt="faq-globe" />
                <img className="line-right" src={LineBgRight} alt='Line' />
                <img className="line-left" src={LineBgLeft} alt='Line' />
                <img className="blue-bg-left" src={BlueBgLeft} alt='Line' />
                <Container>
                    <Row>
                        <Col lg='7'>
                            <h3>General FAQs</h3>
                            <Collapse accordion defaultActiveKey={['1']} expandIcon={({ isActive }) => <>{isActive ? <SvgIcon name='minus-icon' viewbox='0 0 14 3' /> : <SvgIcon name='plus-icon' viewbox='0 0 14 15' />}</>}>
                                <Panel header="What is Comdex?" key="1">
                                    <p>
                                        Comdex is a DeFi infrastructure layer for the interchain, built to provide a foundation for the development and deployment of DeFi in Cosmos. The Comdex chain comprises several on-chain modules designed to mimic the prevalent primitives of finance and DeFi to enable the creation of any DeFi application with a faster go-to-market.
                                    </p>
                                </Panel>
                                <Panel header="Where can I buy the CMDX token?" key="2">
                                    <p>
                                        The CMDX token is available on <a href="https://cswap.one/"  rel="noreferrer noopener" target="_blank">cSwap </a> and a suite of DEXes across the Cosmos ecosystem. Users can trade on and operate these DEXes with compatible wallets.
                                    </p>
                                </Panel>
                                <Panel header="Is Comdex chain audited?" key="3">
                                    <p>
                                        The Comdex chain has been audited by Oak Security. The audit report is public and can be found here:   <a href="https://github.com/oak-security/audit-reports/tree/master/Comdex" target="_blank" rel="noopener noreferrer"> https://github.com/oak-security/audit-reports/tree/master/Comdex </a>
                                    </p>
                                </Panel>
                                <Panel header="What is the utility of CMDX token?" key="4">
                                    <p>
                                        The $CMDX token plays a significant role in the Comdex chain and ecosystem of products. The token is utilized for <br />
                                        - Staking to secure the network and power the chain's staking economy <br />
                                        - Stakers of CMDX obtain voting power necessary in participating in the governance of significant decisions regarding chain development and management of on-chain dApps <br />
                                        - Network transaction fees are collected in CMDX <br /><br />
                                        As Comdex's ecosystem of products grows, CMDX will continue to find increasing utilities across the products.
                                    </p>
                                </Panel>
                                <Panel header="How to build on Comdex chain?" key="5">
                                    <>
                                        <p>
                                            Comdex chain can be used to build a variety of DeFi Apps using the plug-and-play modules available on the Comdex chain.
                                        </p>
                                        <p>
                                            Docs -  <a href="https://docs.comdex.one/build-dapp" target="_blank" rel="noopener noreferrer">https://docs.comdex.one/build-dapp</a> <br />
                                            Github -  <a href="https://github.com/comdex-official" target="_blank" rel="noopener noreferrer">https://github.com/comdex-official</a>
                                        </p>
                                    </>
                                </Panel>
                                <Panel header="How to avail Comdex ecosystem fund?" key="6">
                                    <p>
                                        The Comdex ecosystem fund is focused on incentivizing developers and builders who utilize the Comdex chain and/or build products that complement Comdex's existing ecosystem of products. For more information, visit the <a href="/community-dev-fund " target="_blank" rel="noreferrer">Community Dev Fund page</a> .
                                    </p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.com/invite/7vjPvWKKMT' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Faq;