import React, { useState } from "react";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { MdContentCopy } from 'react-icons/md';
import { FiCheckCircle } from 'react-icons/fi';

const CodeSnippet = ({codeData}) => {
    const [copy, setCopy] = useState(false)
    return (
        <section>
            <div className="copy_code_snippet_container">
                <div className="code_title"></div>
                <div className="copy_code">
                    {!copy ? <span onClick={() => {
                        navigator.clipboard.writeText(codeData)
                        setCopy(true);
                        setTimeout(() => {
                            setCopy(false);
                        }, 2000);
                    }}><MdContentCopy /> Copy</span> : <span><FiCheckCircle /> Copied</span>}

                </div>
            </div>
            <SyntaxHighlighter
                language="go"
                style={atomOneDark}
                customStyle={{
                    padding: "25px",
                    fontSize: "14px",
                    borderRadius: "0 0 10px 10px"

                }}
            >
                {codeData}
            </SyntaxHighlighter>
        </section>
    )
}

export default CodeSnippet