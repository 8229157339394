import { Button } from "antd";
import React from "react";
import { Col, Container, Row } from "../../components/common";
import { Link } from "react-router-dom";
import "./index.less";

import GlobeImg from '../../assets/images/mediakit-globe.png';
import ComdexLogo from '../../assets/images/comdex-logo.svg';
import ComdexIcon from '../../assets/images/comdex-icon.svg';
import ComdexCoin from '../../assets/images/comdex-coin.svg';
import CswapLogo from '../../assets/images/cswap-logo.svg';
import CswapIcon from '../../assets/images/cswap-icon.svg';
import CswapCoin from '../../assets/images/cswap-coin.svg';
import HarborLogo from '../../assets/images/harbor-logo.svg';
import HarborCoin from '../../assets/images/harbor-coin.svg';
import CommodoLogo from '../../assets/images/commodo-logo.svg';
import CommodoCoin from '../../assets/images/commodo-coin.svg';
import CompositeLogo from '../../assets/images/composite-logo.svg';
import CompositeCoin from '../../assets/images/composite-coin.svg';
import AbhishekSingh from '../../assets/images/Abhishek-Singh.png';
import SiddarthPatil from '../../assets/images/Siddarth-Patil.png';
import TopBlueBg from '../../assets/images/blue-texture-left.png';
import LeftLine from '../../assets/images/line-bg-left.png';
import LeftBg from '../../assets/images/blue-texture-left2.png';
import LeftBg2 from '../../assets/images/blue-texture-left.png';
import LeftLine2 from '../../assets/images/line-bg-left2.png';
import kitFolder from '../../assets/images/COMDEX-ALL-LOGO.zip';

const MediaPressKit = () => {
    return (
        <div className="homepage-wrapper mediakit-wrapper">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col sm='7'>
                            <h1>Media Press <br />Kit</h1>
                            <p>
                                Download our logo and use it on your website or advertising.
                            </p>
                            <div className="mt-5 pt-4">
                                <Link to={kitFolder} target="_blank"><Button type="primary">Download All</Button></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="mediakit-section">
                <img className="globe-img" src={GlobeImg} alt="Globe" />
                <img className="left-line" src={LeftLine} alt='' />
                <img className="left-bg" src={LeftBg} alt='' />
                <img className="left-line2" src={LeftLine2} alt='' />
                <img className="left-bg2" src={LeftBg2} alt='' />
                <Container>
                    <Row className='kit-row'>
                        <Col sm='12'>
                            <h2>Comdex</h2>
                            <p>Please utilize these assets when promoting Comdex in communications, articles, websites, and social media.</p>
                        </Col>
                        <Col sm='12' className='logo-row'>
                            <h3>Logo</h3>
                            <ul className="logo-list">
                                <li>
                                    <img src={ComdexLogo} alt={ComdexLogo} />
                                </li>
                                <li>
                                    <img src={ComdexIcon} alt={ComdexIcon} />
                                </li>
                                <li>
                                    <img src={ComdexCoin} alt={ComdexCoin} />
                                </li>
                            </ul>
                        </Col>
                        <Col sm='12' className='color-row'>
                            <h3>Primary Color</h3>
                            <ul className="color-list">
                                <li>
                                    <div className="color-code" style={{ backgroundColor: '#FE4350' }}></div>
                                    <div className="color-name">
                                        <h4>Comdex Red</h4>
                                        <p>#FE4350</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="color-code" style={{ backgroundColor: '#0F489F' }}></div>
                                    <div className="color-name">
                                        <h4>Comdex Red</h4>
                                        <p>#0F489F</p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className='kit-row'>
                        <Col sm='12'>
                            <h2>cSwap</h2>
                            <p>Please utilize these assets when promoting cSwap in communications, articles, websites, and social media.</p>
                        </Col>
                        <Col sm='12' className='logo-row'>
                            <h3>Logo</h3>
                            <ul className="logo-list">
                                <li>
                                    <img src={CswapLogo} alt={CswapLogo} />
                                </li>
                                <li>
                                    <img src={CswapIcon} alt={CswapIcon} />
                                </li>
                                <li>
                                    <img src={CswapCoin} alt={CswapCoin} />
                                </li>
                            </ul>
                            {/* <Button type="secondary">Download</Button> */}
                        </Col>
                        <Col sm='12' className='color-row'>
                            <h3>Primary Color</h3>
                            <ul className="color-list">
                                <li>
                                    <div className="color-code" style={{ backgroundColor: '#FE4350' }}></div>
                                    <div className="color-name">
                                        <h4>Comdex Red</h4>
                                        <p>#FE4350</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="color-code" style={{ backgroundColor: '#F15626' }}></div>
                                    <div className="color-name">
                                        <h4>cSwap Orange</h4>
                                        <p>#F15626</p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className='kit-row'>
                        <Col sm='12'>
                            <h2>Harbor</h2>
                            <p>Please utilize these assets when promoting Harbor in communications, articles, websites, and social media.</p>
                        </Col>
                        <Col sm='12' className='logo-row'>
                            <h3>Logo</h3>
                            <ul className="logo-list">
                                <li>
                                    <img src={HarborLogo} alt={HarborLogo} />
                                </li>
                                <li>
                                    <img src={HarborCoin} alt={HarborCoin} />
                                </li>
                            </ul>
                            {/* <Button type="secondary">Download</Button> */}
                        </Col>
                        <Col sm='12' className='color-row'>
                            <h3>Primary Color</h3>
                            <ul className="color-list">
                                <li>
                                    <div className="color-code" style={{ backgroundColor: '#665AA6' }}></div>
                                    <div className="color-name">
                                        <h4>cSwap Orange</h4>
                                        <p>#665AA6</p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className='kit-row'>
                        <Col sm='12'>
                            <h2>Commodo</h2>
                            <p>Please utilize these assets when promoting Commodo in communications, articles, websites, and social media.</p>
                        </Col>
                        <Col sm='12' className='logo-row'>
                            <h3>Logo</h3>
                            <ul className="logo-list">
                                <li>
                                    <img src={CommodoLogo} alt={CommodoLogo} />
                                </li>
                                <li>
                                    <img src={CommodoCoin} alt={CommodoCoin} />
                                </li>
                            </ul>
                            {/* <Button type="secondary">Download</Button> */}
                        </Col>
                        <Col sm='12' className='color-row'>
                            <h3>Primary Color</h3>
                            <ul className="color-list">
                                <li>
                                    <div className="color-code" style={{ backgroundColor: '#84CEA9' }}></div>
                                    <div className="color-name">
                                        <h4>Commodo Green I</h4>
                                        <p>#84CEA9</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="color-code" style={{ backgroundColor: '#041711' }}></div>
                                    <div className="color-name">
                                        <h4>Commodo Green II</h4>
                                        <p>#041711</p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className='kit-row'>
                        <Col sm='12'>
                            <h2>Composite</h2>
                            <p>Please utilize these assets when promoting Composite in communications, articles, websites, and social media.</p>
                        </Col>
                        <Col sm='12' className='logo-row'>
                            <h3>Logo</h3>
                            <ul className="logo-list">
                                <li>
                                    <img src={CompositeLogo} alt={CompositeLogo} />
                                </li>
                                <li>
                                    <img src={CompositeCoin} alt={CompositeCoin} />
                                </li>
                            </ul>
                            {/* <Button type="secondary">Download</Button> */}
                        </Col>
                        <Col sm='12' className='color-row'>
                            <h3>Primary Color</h3>
                            <ul className="color-list">
                                <li>
                                    <div className="color-code" style={{ backgroundColor: '#BEC9F8' }}></div>
                                    <div className="color-name">
                                        <h4>Commodo Green I</h4>
                                        <p>#BEC9F8</p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className='kit-row'>
                        <Col sm='12'>
                            <h2>Photos</h2>
                            <p>Utilize these assets when promoting Comdex in communications, articles, websites, and social media.</p>
                        </Col>
                        <Col sm='12' className='logo-row'>
                            <ul className="communication-list">
                                <li>
                                    <div className="images">
                                        <img src={AbhishekSingh} alt='Abhishek Singh' />
                                    </div>
                                    <p>Abhishek Singh</p>
                                </li>
                                <li>
                                    <div className="images">
                                        <img src={SiddarthPatil} alt='Siddarth Patil' />
                                    </div>
                                    <p>Siddarth Patil</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                </Container>
            </section>
        </div>
    )
}

export default MediaPressKit;