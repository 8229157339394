import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const CliQuery = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'CREATE YOUR OWN COMDEX MODULE',
                                        href: 'dev-lessons-advance',
                                    },
                                    {
                                        title: 'CLI QUERY',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Creating Commands for Query</h1>
                                <p>
                                    We first need to create proto for Query and then generate the .pb files for the same.
                                </p>
                                <p>
                                    Here is the proto file we created.
                                </p>

                                <div className="learn-module">
                                <CodeSnippet
codeData={`syntax = "proto3";
package comdex.loan.v1beta1;

import "gogoproto/gogo.proto";
import "google/api/annotations.proto";
import "cosmos/base/query/v1beta1/pagination.proto";
import "comdex/loan/v1beta1/params.proto";
import "comdex/loan/v1beta1/loan.proto";

option go_package = "github.com/comdex-official/comdex/x/loan/types";

service Query {
rpc Params(QueryParamsRequest) returns (QueryParamsResponse) {    
    option (google.api.http).get = "/comdex-official/comdex/loan/params";
}
rpc QueryLoan(QueryLoanRequest) returns (QueryLoanResponse) {    
    option (google.api.http).get = "/comdex/lend/v1beta1/loans/{id}";
}
}

message QueryParamsRequest {}

message QueryParamsResponse {
Params params = 1 [(gogoproto.nullable) = false];
}

message QueryLoanRequest {
uint64 id = 1;
}

message QueryLoanResponse {
Loan loan = 1
[(gogoproto.nullable) = false, (gogoproto.moretags) = "yaml:\"loan\""];
}`}
/>

                                    <p>After generating the .pb files we need to create the query commands.</p>
                                    <p>Here is the query command which we created.</p>

                                    <CodeSnippet
codeData={`func queryLoan() *cobra.Command {    
    cmd := &cobra.Command{        
    Use:   "loan [id]",        
    Short: "Query a loan position",        
    Args:  cobra.ExactArgs(1),        
    RunE: func(cmd *cobra.Command, args []string) error {            
        ctx, err := client.GetClientQueryContext(cmd)            
        if err != nil {                
            return err            
        }            

        id, err := strconv.ParseUint(args[0], 10, 64)            
        if err != nil {                
            return err            
        }            

        queryClient := types.NewQueryClient(ctx)            

        res, err := queryClient.QueryLoan(                
            context.Background(),                
            &types.QueryLoanRequest{                    
                Id: id,                
        },            
        )            
        if err != nil {                
            return err            
        }            

        return ctx.PrintProto(res)        
        },    
    }

    flags.AddQueryFlagsToCmd(cmd)

    return cmd
}`}
/>

                                    <p>
                                        We also need to define queryserver methods:
                                    </p>
                                    <CodeSnippet
codeData={`func queryLoan() *cobra.Command {    
    cmd := &cobra.Command{        
        Use:   "loan [id]",        
        Short: "Query a loan position",        
        Args:  cobra.ExactArgs(1),        
        RunE: func(cmd *cobra.Command, args []string) error {            
            ctx, err := client.GetClientQueryContext(cmd)            
            if err != nil {                
                return err            
        }            

        id, err := strconv.ParseUint(args[0], 10, 64)            
        if err != nil {                
            return err            
        }            

        queryClient := types.NewQueryClient(ctx)            

        res, err := queryClient.QueryLoan(                
            context.Background(),                
            &types.QueryLoanRequest{                    
                Id: id,                
            },
        )            
        if err != nil {                
            return err            
        }            

            return ctx.PrintProto(res)        
        },    
    }    

    flags.AddQueryFlagsToCmd(cmd)    

    return cmd
}`}
/>

                                    <p>Our query for a loan using loanID is ready to use.</p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/cli-txn'><b>CLI txn.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CliQuery;