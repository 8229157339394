import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const StoringAndRetrievingData = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'storing and retrieving data in the key-value store',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Storing and Retrieving data in the Key-Value Store</h1>
                                <p>
                                    Here's an example function that stores data in a key-value store and then displays it using the Cosmos SDK in Go:
                                </p>
                                <CodeSnippet
codeData={`package main
                                
import (
"fmt"

sdk "github.com/cosmos/cosmos-sdk/types"
"github.com/cosmos/cosmos-sdk/store/dbadapter"
"github.com/cosmos/cosmos-sdk/store/tracekv"
"github.com/cosmos/cosmos-sdk/store/types"
)

func storeData(ctx sdk.Context, storeKey sdk.StoreKey) {
// set a key-value pair in the store
key := []byte("hello")
value := []byte("world")
ctx.KVStore(storeKey).Set(key, value)
}
func displayData(ctx sdk.Context, storeKey sdk.StoreKey) {
// get the value associated with the key from the store
key := []byte("hello")
retrievedValue := ctx.KVStore(storeKey).Get(key)

// print the retrieved value
fmt.Printf("retrieved value: %s\n", retrievedValue)
}`}
/>

                                <CodeSnippet
codeData={`func (cms *CommitMultiStore) Start(ctx sdk.Context, 
    txBytes []byte) sdk.Context
    
    func (cms *CommitMultiStore) EndAndCommit(ctx sdk.Context)`}
/>

                                <p>
                                    The storeData() function takes a sdk.Context object and a sdk.StoreKey object as input. It uses the context object to create a new KV store using the KVStore() method and the provided store key. It then uses the Set() method of the KV store interface to set a key-value pair in the store. In this case, the key is the byte slice []byte("hello"), and the value is the byte slice []byte("world"). This stores the string "world" in the store with the key "hello".
                                </p>
                                <p>
                                    The displayData() function also takes a sdk.Context object and a sdk.StoreKey object as input. It uses the context object to create a new KV store using the KVStore() method and the provided store key. It then uses the Get() method of the KV store interface to retrieve the value associated with the key "hello" from the store. In this case, the value retrieved is the byte slice []byte("world"). Finally, the function prints the retrieved value to the console using the fmt.Printf() function.
                                </p>

                                <div className="nextup-bottom">
                                    <h2>Next Up</h2>
                                    <p className="bottom-link"> 
                                        Dive right into Cosmos with <Link to='/building-custommodules'><b>Building a Custom Module from Scratch.</b></Link>
                                    </p>
                                </div>
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default StoringAndRetrievingData;