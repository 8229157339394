import { Button, Modal } from "antd";
import React, { useState } from "react";
import { SvgIcon } from "../../../components/common";
import "./index.less";

import dexes1 from '../../../assets/images/cswap-logo.svg';
import dexes2 from '../../../assets/images/osmosis.svg';
import dexes3 from '../../../assets/images/crescent.svg';
import dexes4 from '../../../assets/images/kujira.svg';
import dexes5 from '../../../assets/images/hopers.svg';
import kado from '../../../assets/images/KADO.svg';
import { FIAT_URL } from "../../../common/common";

const GetcmdxModal = ({ text, title, buyWithFiat }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFiatModalOpen, setIsFiatModalOpen] = useState(false)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleKadoClick = () => {
        handleCancel()
        setIsFiatModalOpen(true)
    }
    const handlekadoModalClose = () => {
        setIsFiatModalOpen(false)
    }

    return (
        <>
            <Button type="secondary" onClick={showModal} className="m-2">{text}</Button>
            <Modal width={buyWithFiat ? 500 : 700} title={title} className="comdex-modal" centered open={isModalOpen} onCancel={handleCancel} footer={false} closeIcon={<SvgIcon name='close' viewbox='0 0 18 18' />}>
                {!buyWithFiat &&
                    <ul className="dexes-list">
                        <li><a href="https://app.cswap.one/" rel="noreferrer" target='_blank'><img src={dexes1} alt='cswap' /></a> </li>
                        <li><a href="https://app.osmosis.zone/?from=ATOM&to=CMDX " rel="noreferrer" target='_blank'><img src={dexes2} alt='osmosis' /></a></li>
                        <li><a href="https://app.crescent.network/swap " rel="noreferrer" target='_blank'><img src={dexes3} alt='crescent' /></a></li>
                        <li><a href="https://fin.kujira.app/trade/kujira16y344e8ryydmeu2g8yyfznq79j7jfnar4p59ngpvaazcj83jzsms6tju67?q=all " rel="noreferrer" target='_blank'><img src={dexes4} alt='kujira' /></a></li>
                        <li><a href=" https://www.hopers.io/swap " rel="noreferrer" target='_blank'><img src={dexes5} alt='hopers' /></a></li>
                    </ul>
                }
                {buyWithFiat &&
                    <ul className="dexes-list" style={{ "grid-template-columns": "repeat(2, 1fr)" }}>
                        <li onClick={handleKadoClick} style={{ cursor: "pointer" }}><img src={kado} alt='kado' /><span style={{ color: "white", fontSize: "18px", fontWeight: "500" }} className="ml-2"> Kado</span></li>

                    </ul>
                }
            </Modal>

            {
                <Modal
                    centered
                    open={isFiatModalOpen}
                    onCancel={handlekadoModalClose}
                    footer={false}
                // closeIcon={<SvgIcon name='close' viewbox='0 0 18 18' />}
                >
                    <iframe
                        src={`${FIAT_URL}&onToAddress=`}
                        width="465"
                        height="700"
                        frameBorder={0}
                        style={{ border: '0px', borderRadius: '12px' }}
                    />
                </Modal>

            }
        </>
    )
}

export default GetcmdxModal;