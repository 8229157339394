import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const StateManagement = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'understanding state management',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Understanding State Management</h1>
                                <p>
                                    State management is a fundamental aspect of any blockchain system, and the Cosmos SDK provides a flexible and modular state management framework. At the core of this framework are the KV stores, which allow developers to store and retrieve key-value pairs in a decentralized and secure manner.
                                </p>
                                <p>
                                    KV stores in Cosmos SDK are key-value stores that allow developers to store and retrieve data in a decentralized and secure manner. The KVStore interface is implemented by a variety of concrete stores, each of which provides a different type of storage backend, including in-memory stores, LevelDB stores, and RocksDB stores.
                                </p>
                                <div className="pt-4">
                                    <p>
                                        The KVStore interface is defined as follows:
                                    </p>
                                    <CodeSnippet
codeData={`type KVStore interface {
    Get(key []byte) []byte
    Set(key []byte, value []byte)
    Delete(key []byte)
    Has(key []byte) bool
    Iterator(start, end []byte) Iterator
}`}
/>
                                    
                                </div>
                                <div className="pt-3">
                                    <p>
                                        The Get() method retrieves the value associated with a given key, while the Set() method sets the value associated with a given key. The Delete() method removes the key-value pair associated with a given key, and the Has() method checks if a given key is present in the store. The Iterator() method provides a way to iterate over a range of keys in the store.
                                    </p>
                                </div>
                                <div className="pt-3">
                                    <h3>CommitMultiStore:</h3>
                                    <p>
                                        The CommitMultiStore module provides functionality for creating and committing multi-store transactions in the Cosmos SDK. A multi-store transaction is a transaction that involves making changes to multiple KV stores atomically, i.e., either all the changes are committed or none of them are.
                                    </p>
                                    <p>
                                        The CommitMultiStore module is implemented as a wrapper around a MultiStore object, which is a collection of KV stores. The MultiStore object is responsible for coordinating the commits of the individual KV stores that it contains.
                                    </p>
                                    <p>
                                        The CommitMultiStore module provides two main methods:
                                    </p>
                                   
                                    <CodeSnippet
codeData={`func (cms *CommitMultiStore) Start(ctx sdk.Context, txBytes []byte) sdk.Context

func (cms *CommitMultiStore) EndAndCommit(ctx sdk.Context)`}
/>
                                    
                                </div>
                                <div className="pt-3">
                                    <p>
                                        The Start() method starts a new multi-store transaction and returns a new context object that is used to execute the transaction. The EndAndCommit() method completes the transaction and commits the changes to the KV stores. If any of the KV stores fails to commit its changes, all the changes are rolled back.
                                    </p>
                                </div>
                                <div className="pt-3">
                                    <h3>GasKVStore:</h3>
                                    <p>
                                        The GasKVStore module is a specialized KV store that adds gas metering functionality to the Cosmos SDK. Gas metering is an important aspect of blockchain systems, as it allows the network to limit the amount of computational resources that a transaction can consume. The GasKVStore ensures that each transaction is allocated a certain amount of gas, which is consumed as the transaction makes changes to the KV stores.
                                    </p>
                                    <p>
                                        The GasKVStore module is implemented as a wrapper around a KVStore object, and it provides the same interface as the KVStore interface. In addition to the basic KVStore methods, the GasKVStore provides a GasConsumed() method that returns the amount of gas consumed by the transaction so far.
                                    </p>
                                    <p>
                                        The GasKVStore is used in combination with the CommitMultiStore module to ensure that each transaction is allocated a certain amount of gas and that the gas is consumed as the transaction makes changes to the KV stores. If the transaction runs out of gas before it completes, the changes are rolled back, ensuring that the state of the network remains consistent.
                                    </p>
                                    <p>
                                        Overall, the KV stores in the Cosmos SDK provide a powerful and flexible state management framework for blockchain developers. With support for multi-store commits and gas metering, the KV stores are well-suited for building complex decentralized applications that require secure and efficient state management.
                                    </p>
                                </div>
                                <div className="nextup-bottom">
                                    <h2>Next Up</h2>
                                    <p className="bottom-link"> 
                                        Dive right into Cosmos with <Link to='/storing-retrieving-data'><b>Storing and retrieving data in the key-value store.</b></Link>
                                    </p>
                                </div>
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default StateManagement;