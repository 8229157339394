import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Layout, Button } from "antd";
import SvgSprite from "./utils/SvgSpriteLoader";
import svgFile from './assets/images/svg/svg-sprite.svg';
import NavigationBar from './containers/NavigationBar';
import 'antd/dist/reset.css';
import './App.less';
import FooterBar from './containers/FooterBar';
import HomePage from './containers/Homepage';
import { SvgIcon } from './components/common';

import Error404 from './containers/Error404';
import Introduction from './containers/Introduction';
import Explore from './containers/Explore';
import Faq from './containers/Faq';
import MediaPressKit from './containers/MediaPressKit';
import Getcmdx from './containers/Getcmdx';
import DeveloperPortal from './containers/DeveloperPortal';
import CommunityDevFund from './containers/CommunityDevFund';
import Events from './containers/Events';
import EventDetails from './containers/Events/EventDetails';
import Blogs from './containers/Blogs';
import BlogDetails from './containers/Blogs/BlogDetails';
import CosmosIntroduction from './containers/Introduction/CosmosIntroduction';
import ContactUs from './containers/ContactUs';
import CosmosModuleOne from './containers/Introduction/CosmosIntroduction/CosmosModuleOne';
import CosmosModuleTwo from './containers/Introduction/CosmosIntroduction/CosmosModuleTwo';
import CosmosModuleThree from './containers/Introduction/CosmosIntroduction/CosmosModuleThree';
import CosmosModuleThreeTwo from './containers/Introduction/CosmosIntroduction/CosmosModuleThreeTwo';
import CosmosModuleOneTwo from './containers/Introduction/CosmosIntroduction/CosmosModuleOneTwo';
import CosmosModuleOneThree from './containers/Introduction/CosmosIntroduction/CosmosModuleOneThree';
import CosmosModuleOneFour from './containers/Introduction/CosmosIntroduction/CosmosModuleOneFour';
import CosmosModuleTwoTwo from './containers/Introduction/CosmosIntroduction/CosmosModuleTwoTwo';
import CosmosModuleTwoThree from './containers/Introduction/CosmosIntroduction/CosmosModuleTwoThree';
import BlogDetailsCswap from './containers/Blogs/BlogDetailsCswap';
import WhatIsCosmosSdk from './containers/Introduction/CosmosIntroduction/WhatIsCosmosSdk';
import CosmosSDKBasics from './containers/Introduction/CosmosIntroduction/CosmosSDKBasics';
import StateManagement from './containers/Introduction/CosmosIntroduction/StateManagement';
import BuildingCustomModulesCosmosSdk from './containers/Introduction/CosmosIntroduction/BuildingCustomModulesCosmosSdk';
import UnderstandingIbc from './containers/Introduction/CosmosIntroduction/UnderstandingIbc';
import IntroductionToComdex from './containers/Introduction/CosmosIntroduction/IntroductionToComdex';
import ComdexArchitecture from './containers/Introduction/CosmosIntroduction/ComdexArchitecture';
import SettingUpDevnet from './containers/Introduction/CosmosIntroduction/SettingUpDevnet';
import DevLessonsBasics from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/DevLessonsBasics';
import IntroductionToGoProgramming from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/IntroductionToGoProgramming';
import GoSyntaxandVariables from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/GoSyntaxandVariables';
import ControlStructuresInGo from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/ControlStructuresInGo';
import FunctionsInGo from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/FunctionsInGo';
import ArraysAndSlices from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/ArraysAndSlices';
import PointersAndStruct from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/PointersAndStruct';
import ErrorHandlingInGo from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/ErrorHandlingInGo';
import ConcurrencyInGo from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/ConcurrencyInGo';
import DevLessonsWithRust from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/DevLessonsWithRust';
import IntroductionRustProgramming from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/IntroductionRustProgramming';
import RustSyntaxAndVariables from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/RustSyntaxAndVariables';
import ControlStructuresInRust from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/ControlStructuresInRust';
import FunctionsInRust from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/FunctionsInRust';
import OwnershipAndBorrowingInRust from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/OwnershipAndBorrowingInRust';
import StructsAndEnumsInRust from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/StructsAndEnumsInRust';
import ErrorHandlingInRust from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/ErrorHandlingInRust';
import ConcurrencyInRust from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/ConcurrencyInRust';
import WorkingWithFilesInRust from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/WorkingWithFilesInRust';
import CreateYourdApp from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/CreateYourdApp';
import BuildYourOwndApp from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Basics/BuildYourOwndApp';
import DevLessonsAdvance from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/DevLessonsAdvance';
import CreateComdexModule from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/CreateComdexModule';
import ImportingMethods from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/ImportingMethods';
import Transactions from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/Transactions';
import CreateRequest from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/CreateRequest';
import ApproveRequest from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/ApproveRequest';
import RepayRequest from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/RepayRequest';
import CancelRequest from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/CancelRequest';
import CliQuery from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/CliQuery';
import CliTxn from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/CliTxn';
import WhyLearnCosmosSdk from './containers/Introduction/CosmosIntroduction/WhyLearnCosmosSdk';
import SettingCosmosEnviroment from './containers/Introduction/CosmosIntroduction/SettingCosmosEnviroment';
import UnderstandingIgniteModuleCreation from './containers/Introduction/CosmosIntroduction/UnderstandingIgniteModuleCreation';
import StoringAndRetrievingData from './containers/Introduction/CosmosIntroduction/StoringAndRetrievingData';
import TheIbcWorking from './containers/Introduction/CosmosIntroduction/TheIbcWorking';
import SettingGoreLayer from './containers/Introduction/CosmosIntroduction/SettingGoreLayer';
import ScrollButton from './ScrollButton';
import EducationVideos from './containers/EducationVideos';
import GettingStartedWithCosmWasm from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/Getting-Started-CosmWasm/GettingStartedWithCosmWasm';
import WhatIsCosmWasm from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/Getting-Started-CosmWasm/WhatIsCosmWasm';
import HowToUseCosmWasm from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/Getting-Started-CosmWasm/HowToUseCosmWasm';
import PreRequisites from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/Getting-Started-CosmWasm/PreRequisites';
import CosmWasmDevelopment from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/Getting-Started-CosmWasm/CosmWasmDevelopment';
import ExampleOfRustContract from './containers/Introduction/CosmosIntroduction/Dev-Lessons-Advance/Getting-Started-CosmWasm/ExampleOfRustContract';

import taxureBg from './assets/images/textures.png';

const { Content, Footer } = Layout;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <React.Fragment>
      <SvgSprite url={svgFile} />
      <Router>
        <ScrollToTop />
        <Layout style={{ backgroundImage: `url(${taxureBg})` }}>
          <NavigationBar />
          <Content>
            <div className='social-right'>
              <Button type="link" href="https://twitter.com/ComdexOfficial" target="_blank" rel="noreferrer noopener">
                <SvgIcon name="twitter_side" viewbox="0 0 55.819 46.915" />
              </Button>
              <Button type="link" href="https://t.me/ComdexChat" target="_blank" rel="noreferrer noopener">
                <SvgIcon name="telegram" viewbox="0 0 54.209 46.163" />
              </Button>
              <Button type="link" href="https://forum.comdex.one/" target="_blank" rel="noreferrer noopener">
                <SvgIcon name="forum" viewbox="0 0 27.205 27.205" />
              </Button>
              <Button type="link" href="https://bit.ly/ComdexOfficialDiscord" target="_blank" rel="noreferrer noopener">
                <SvgIcon name="discord" viewbox="0 0 65.002 49.503" />
              </Button>
              <Button type="link" href="https://blog.comdex.one/" target="_blank" rel="noreferrer noopener">
                <SvgIcon name="medium" viewbox="0 0 58.333 48" />
              </Button>
              <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                <SvgIcon name="github" viewbox="0 0 60 59.495" />
              </Button>
              <Button type="link" href="https://www.reddit.com/r/ComdexOne/" target="_blank" rel="noreferrer noopener">
                <SvgIcon name="reddit" viewbox="0 0 18 18" />
              </Button>
              <Button type="link" href="https://www.linkedin.com/company/comdexofficial/" target="_blank" rel="noreferrer noopener">
                <SvgIcon name="linkedin" viewbox="0 0 18 18" />
              </Button>
              <Button type="link" href="/contact-us" target="_blank" rel="noreferrer noopener">
                <SvgIcon name="chat" viewbox="0 0 25.715 18" />
              </Button>
            </div>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/introduction" element={<Introduction />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/media-press-kit" element={<MediaPressKit />} />
              <Route path="/get-cmdx" element={<Getcmdx />} />
              <Route path="/developer-portal" element={<DeveloperPortal />} />
              <Route path="/community-dev-fund" element={<CommunityDevFund />} />
              <Route path="/events" element={<Events />} />
              <Route path="/event-details" element={<EventDetails />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blog-details" element={<BlogDetails />} />
              <Route path="/blog-details-cswap" element={<BlogDetailsCswap />} />
              <Route path="/cosmos-introduction" element={<CosmosIntroduction />} />
              <Route path="/cosmos-module-1" element={<CosmosModuleOne />} />
              <Route path="/cosmos-module-1.2" element={<CosmosModuleOneTwo />} />
              <Route path="/cosmos-module-1.3" element={<CosmosModuleOneThree />} />
              <Route path="/cosmos-module-1.4" element={<CosmosModuleOneFour />} />
              <Route path="/cosmos-module-2" element={<CosmosModuleTwo />} />
              <Route path="/cosmos-module-2.2" element={<CosmosModuleTwoTwo />} />
              <Route path="/cosmos-module-2.3" element={<CosmosModuleTwoThree />} />
              <Route path="/cosmos-module-3" element={<CosmosModuleThree />} />
              <Route path="/cosmos-module-3.2" element={<CosmosModuleThreeTwo />} />
              <Route path="/contact-us" element={<ContactUs />} />

              <Route path="/whatis-cosmos-sdk" element={<WhatIsCosmosSdk />} />
              <Route path="/why-learn-cosmos" element={<WhyLearnCosmosSdk />} />
              <Route path="/setting-cosmossdk-enviroment" element={<SettingCosmosEnviroment />} />
              <Route path="/cosmossdk-basics" element={<CosmosSDKBasics />} />
              <Route path="/ignite-module-creation" element={<UnderstandingIgniteModuleCreation />} />
              <Route path="/state-management" element={<StateManagement />} />
              <Route path="/storing-retrieving-data" element={<StoringAndRetrievingData />} />
              <Route path="/building-custommodules" element={<BuildingCustomModulesCosmosSdk />} />
              <Route path="/understanding-ibc" element={<UnderstandingIbc />} />
              <Route path="/ibc-working" element={<TheIbcWorking />} />
              <Route path="/introduction-comdex" element={<IntroductionToComdex />} />
              <Route path="/setting-relayer" element={<SettingGoreLayer />} />
              <Route path="/comdex-architecture" element={<ComdexArchitecture />} />
              <Route path="/settingup-devnet" element={<SettingUpDevnet />} />
              <Route path="/dev-lessons-basics" element={<DevLessonsBasics />} />
              <Route path="/introduction-togo-programming" element={<IntroductionToGoProgramming />} />
              <Route path="/gosyntax-variables" element={<GoSyntaxandVariables />} />
              <Route path="/control-structures-ingo" element={<ControlStructuresInGo />} />
              <Route path="/functions-ingo" element={<FunctionsInGo />} />
              <Route path="/arrays-and-slices" element={<ArraysAndSlices />} />
              <Route path="/pointers-and-struct" element={<PointersAndStruct />} />
              <Route path="/error-handling-ingo" element={<ErrorHandlingInGo />} />
              <Route path="/concurrency-ingo" element={<ConcurrencyInGo />} />
              <Route path="/devlessons-with-rust" element={<DevLessonsWithRust />} />
              <Route path="/introduction-rust-programming" element={<IntroductionRustProgramming />} />
              <Route path="/rustsyntax-and-variables" element={<RustSyntaxAndVariables />} />
              <Route path="/control-structures-inrust" element={<ControlStructuresInRust />} />
              <Route path="/functions-inrust" element={<FunctionsInRust />} />
              <Route path="/ownership-borrowing-inrust" element={<OwnershipAndBorrowingInRust />} />
              <Route path="/structs-enums-inrust" element={<StructsAndEnumsInRust />} />
              <Route path="/error-handling-inrust" element={<ErrorHandlingInRust />} />
              <Route path="/concurrency-inrust" element={<ConcurrencyInRust />} />
              <Route path="/working-withfiles-inrust" element={<WorkingWithFilesInRust />} />
              <Route path="/create-your-dApp" element={<CreateYourdApp />} />
              <Route path="/build-your-own-dApp" element={<BuildYourOwndApp />} />
              <Route path="/dev-lessons-advance" element={<DevLessonsAdvance />} />
              <Route path="/module-structure" element={<CreateComdexModule />} />
              <Route path="/importing-methods" element={<ImportingMethods />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/create-request" element={<CreateRequest />} />
              <Route path="/approve-request" element={<ApproveRequest />} />
              <Route path="/repay-request" element={<RepayRequest />} />
              <Route path="/cancel-request" element={<CancelRequest />} />
              <Route path="/cli-query" element={<CliQuery />} />
              <Route path="/cli-txn" element={<CliTxn />} />
              <Route path="/gettingstarted-with-cosmWasm" element={<GettingStartedWithCosmWasm />} />
              <Route path="/whatis-cosmWasm" element={<WhatIsCosmWasm />} />
              <Route path="/how-touse-cosmWasm" element={<HowToUseCosmWasm />} />
              <Route path="/pre-requisites" element={<PreRequisites />} />
              <Route path="/cosmWasm-development" element={<CosmWasmDevelopment />} />
              <Route path="/example-ofRust-contract" element={<ExampleOfRustContract />} />
              <Route path="/education-videos" element={<EducationVideos />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Content>

          <ScrollButton />

          <Footer className='footer-bottom'>
            <FooterBar />
          </Footer>
        </Layout>
      </Router>
    </React.Fragment>
  );
}

export default App;