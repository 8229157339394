import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const GoSyntaxandVariables = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'DEV LESSONS BASICS',
                                    href: 'dev-lessons-basics',
                                },
                                {
                                    title: 'GO SYNTAX AND VATIABLES',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Go Syntax and Variables</h1>
                                <p>
                                    In this lesson, we will cover the basic syntax and data types in Go, how to declare and initialize variables, and how to use basic operators.
                                </p>

                                <div className="learn-module">
                                    <h1>Lesson Objectives:</h1>
                                    <div className="learn-m-card">
                                        <p>By the end of this lesson, you should be able to:</p>
                                        <ul>
                                            <li>
                                                Understand the basic syntax and data types in Go.
                                            </li>
                                            <li>
                                                Declare and initialize variables in Go.
                                            </li>
                                            <li>
                                               Use basic operators in Go.
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Go is a statically typed programming language designed to make it easy to build efficient and scalable software. In this lesson, we will learn about the basic syntax and data types in Go.
                                    </p>

                                    <h3>1. Hello World program in Go</h3>
                                    <p>
                                        Let's start by creating a "Hello World" program in Go. Open your favorite text editor and type the following code:
                                    </p>
                                    <CodeSnippet
codeData={`package main
import "fmt"func main() {    
    fmt.Println("Hello, World!")
}`}
/>

                                    <p>
                                        Save the file with a .go extension, for example, hello.go. Open your terminal or command prompt and navigate to the directory where you saved the file. Run the following command to compile and run the program
                                    </p>
                                    <CodeSnippet
codeData={`go run hello.go`}
/>
                                    <p>You should see the message "Hello, World!" printed on the screen. Congratulations, you have written your first Go program!</p>
                                    
                                    <h3>2. Variables</h3>
                                    <p>
                                        Variables are used to store values in Go. In Go, you need to declare the type of the variable before using it. Here's an example:
                                    </p>
                                    <CodeSnippet
codeData={`package main
import "fmt"
func main() {    
    var x int = 10    
    var y float32 = 2.5    
    var message string = "Hello, World!"    
    
    fmt.Println(x)    
    fmt.Println(y)    
    fmt.Println(message)
    }`}
/>                                    
                                    <p>
                                        In this example, we have declared three variables of different types: x (int), y (float32), and message (string). We have assigned values to these variables and printed them using the fmt.Println function.
                                    </p>
                                    <p>You can also declare variables without specifying their type, and Go will infer the type based on the value you assign to them:</p>
                                    <CodeSnippet
codeData={`package main
import "fmt"
func main() {
        x := 10
        y := 2.5
        message := "Hello, World!"

        fmt.Println(x)
        fmt.Println(y)
        fmt.Println(message)
        }`}
/>
                                    
                                    <h3>3. Data Types</h3>
                                    <p>
                                        Go has several built-in data types. Let's take a look at some of the most common ones:
                                    </p>

                                    <ol type="1">
                                        <li>
                                            int: used to represent integer values (e.g., 0, 1, 2, -1, -2)
                                        </li>
                                        <li>
                                            float32, float64: used to represent floating-point values (e.g., 1.0, 2.5, -3.1415)
                                        </li>
                                        <li>
                                            bool: used to represent boolean values (true or false)
                                        </li>
                                        <li>
                                            string: used to represent text strings (e.g., "Hello, World!")
                                        </li>
                                        <li>
                                            
                                        </li>
                                    </ol>

                                    <p>
                                        Go also has several other data types, such as arrays, slices, maps, and structs. We will cover these in more detail in later lessons.
                                    </p>

                                    <h3>4. Operators</h3>

                                    <p>
                                        Go supports several operators that allow you to perform arithmetic, logical, and comparison operations. Here are some of the most common operators:
                                    </p>

                                    <ol type="1">
                                        <li>Arithmetic operators: +, -, *, /, % </li>
                                        <li>Comparison operators: ==, !=, {`<, <=, >, >`}= </li>
                                        <li>Logical operators: &&, ||, !</li>
                                    </ol>

                                    <p>Here's an example that shows how to use these operators:</p>
                                    <CodeSnippet
codeData={`package main

import "fmt"

func main() {

    x := 10
    y := 5

    fmt.Println(x + y) // prints 15
    fmt.Println(x - y) // prints 5
    fmt.Println(x * y) // prints 50
    fmt.Println(x / y) // prints 2
    fmt.Println(x % y) // prints 0
    fmt.Println(x == y) // prints false
    fmt.Println(x != y) // prints true
    fmt.Println(x < y) // prints false
    fmt.Println(x <= y) // prints false
    fmt.Println(x > y) // prints true
}`}
/>

                                    <p>
                                        Go also supports the standard comparison operators, including ==, !=, {`<, >, <=, and >=.`} Here's an example of using these operators in Go: <br />
yamlCopy code
                                    </p>
                                    <CodeSnippet
codeData={`a := 5 b := 7 fmt.Println(a == b) // Output: false fmt.Println(a != b) // 
Output: true fmt.Println(a < b) // Output: true fmt.Println(a > b) // 
Output: false fmt.Println(a <= b) // Output: true fmt.Println(a >= b) // 
Output: false`}
/>
                                    
                                    <h3>Conclusion</h3>
                                    <p>
                                        In this lesson, we covered the basic syntax and data types in Go, how to declare and initialize variables, and how to use basic operators. This is the foundation you'll need to write Go programs, so make sure you're comfortable with these concepts before moving on to more complex topics.
                                    </p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/control-structures-ingo'><b>Control Structures in Go.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default GoSyntaxandVariables;