import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const RepayRequest = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'CREATE YOUR OWN COMDEX MODULE',
                                        href: 'dev-lessons-advance',
                                    },
                                    {
                                        title: 'repay REQUEST',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Repay a Loan</h1>
                                <p>
                                    The RepayLoan function is in charge of managing the repayment of a loan by transferring the borrowed funds, as well as any agreed-upon fees, from the borrower to the lender. Additionally, the collateral that was provided as part of the loan agreement will be released from the escrow account and returned to the borrower.
                                </p>
                                <p>
                                    There are certain conditions that must be met before the RepayLoan function can be called. Firstly, the borrower of the loan must sign the transaction, ensuring that only the borrower can initiate the repayment process. Secondly, the loan must be in an approved status, indicating that it has been approved and is ready for repayment.
                                </p>
                                <p>
                                    To implement the RepayLoan function, we must first ensure that these conditions have been met before proceeding with the repayment process. Once the necessary checks have been performed, the function can proceed with transferring the funds and releasing the collateral from the escrow account.
                                </p>

                                <div className="learn-module">
                                <CodeSnippet
codeData={`func (k Keeper) Repay(ctx sdk.Context, loanID uint64) error {    
    loan, found := k.GetLoan(ctx, loanID)    
    if !found {        
        return sdkerrors.Wrapf(sdkerrors.ErrKeyNotFound, "key %d doesn't exist for loanID", loanID)    
    }    
    if loan.State != "approved" {        
        return sdkerrors.Wrapf(types.ErrWrongLoanState, "%v", loan.State)    
    }    

    pair, found := k.Asset.GetPair(ctx, loan.PairID)    
    if !found {        
        return assettypes.ErrorPairDoesNotExist    
    }

    assetIn, found := k.Asset.GetAsset(ctx, pair.AssetIn)    
    if !found {        
        return assettypes.ErrorAssetDoesNotExist    
    }    

    assetOut, found := k.Asset.GetAsset(ctx, pair.AssetOut)    
    if !found {        
        return assettypes.ErrorAssetDoesNotExist    
    }    


    lenderAddr, err := sdk.AccAddressFromBech32(loan.Lender)    
    if err != nil {        
        return err    
    }    

    borrowerAddr, err := sdk.AccAddressFromBech32(loan.Owner)    
    if err != nil {        
        return err    
    }    

    err = k.bankKeeper.SendCoinsFromAccountToModule(ctx, borrowerAddr, types.ModuleName, sdk.NewCoins(sdk.NewCoin(assetOut.Denom, loan.AmountOut)))    
    if err != nil {        
        return err    
    }    

    err = k.bankKeeper.SendCoinsFromModuleToAccount(ctx, types.ModuleName, lenderAddr, sdk.NewCoins(sdk.NewCoin(assetOut.Denom, loan.AmountOut), sdk.NewCoin(assetIn.Denom, loan.Fee)))    
    if err != nil {        
        return err    
    }    

    err = k.bankKeeper.SendCoinsFromModuleToAccount(ctx, types.ModuleName, borrowerAddr, sdk.NewCoins(sdk.NewCoin(assetIn.Denom, loan.AmountIn)))    
    if err != nil {        
        return err    
    }    

    k.DeleteLoan(ctx, loanID)    

    return nil
}`}
/>

                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/cancel-request'><b>Cancel Request.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default RepayRequest;