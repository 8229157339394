import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const WhatIsCosmosSdk = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'WHAT IS COSMOS',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>What is Cosmos SDK?</h1>
                                <p>
                                    The Cosmos SDK is a modular framework for building decentralized applications (dApps) and blockchain networks. It provides developers with the tools and resources necessary to create their own custom blockchain networks that can interoperate with other networks in the Cosmos ecosystem.
                                </p>
                                <p>
                                    At a high level, the Cosmos SDK can be thought of as consisting of two layers: the network layer and the application layer. The network layer provides the underlying infrastructure for the blockchain network, while the application layer is where the specific dApps and smart contracts reside.
                                </p>
                                <p>
                                    The network layer of the Cosmos SDK is responsible for managing the consensus protocol that allows nodes on the network to agree on a shared state of the blockchain. The consensus protocol used by Cosmos is based on the Tendermint consensus algorithm, which is designed to be fast, efficient, and highly secure. The Tendermint consensus algorithm is based on a practical Byzantine fault tolerance (PBFT) consensus mechanism, which solves the Byzantine Generals' Problem (BGP).
                                </p>
                                <p>
                                    The Byzantine Generals' Problem (BGP) is a classic computer science problem that refers to the challenge of achieving consensus among a group of distributed nodes in the presence of faulty or malicious nodes. The problem is named after the scenario of a group of Byzantine generals who must coordinate their attack on a common enemy, but some of the generals may be traitors who seek to undermine the plan.
                                </p>
                                <p>
                                    In the context of blockchain networks, the BGP is relevant because it represents the challenge of achieving consensus among nodes that may be untrustworthy or malicious. The Tendermint consensus algorithm solves this problem by requiring that at least two-thirds of the nodes on the network are honest and follow the protocol correctly. This ensures that the network can reach consensus even in the presence of faulty or malicious nodes.
                                </p>
                                <p>
                                    The application layer of the Cosmos SDK is where developers can create their own custom dApps and smart contracts that run on top of the blockchain network. This layer provides developers with a wide range of tools and resources, including a software development kit (SDK) that makes it easy to build and deploy custom applications on the network.
                                </p>
                                <p>
                                    Overall, the Cosmos SDK is a powerful framework that enables developers to build custom blockchain networks that are fast, efficient, and highly secure. By providing a modular architecture and a range of powerful tools and resources, the Cosmos SDK makes it easy for developers to create innovative dApps and smart contracts that can interoperate with other networks in the Cosmos ecosystem.
                                </p>
                                <div className="nextup-bottom">
                                    <h2>Next Up</h2>
                                    <p className="bottom-link">
                                        Dive right into Cosmos with <Link to='/why-learn-cosmos'><b>Why learn Cosmos SDK?</b></Link>
                                    </p>
                                </div>
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default WhatIsCosmosSdk;