import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const CosmosModuleThreeTwo = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'WHAT IS COSMOS',
                                    href: '',
                                },
                                {
                                    title: 'MODULE 3',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Working with other blockchains using IBC (Inter-blockchain communication)</h1>
                                <p>
                                    The Cosmos Hub is the flagship blockchain in the Cosmos network, which is designed to facilitate the interoperability of various blockchains. It serves as a hub for communication between different blockchains, allowing them to interact with each other using the Inter-blockchain communication (IBC) protocol.
                                </p>

                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                
                                <div className="learn-module">
                                    <h1>What will you learn in Module 3.2</h1>
                                    <div className="learn-m-card">
                                        <ul>
                                            <li>
                                                Understanding the concept of inter-blockchain communication (IBC)
                                            </li>
                                            <li>
                                                Overview of the IBC protocol and its architecture
                                            </li>
                                            <li>
                                                Setting up IBC connections between blockchains
                                            </li>
                                            <li>
                                                Transferring tokens and other data between blockchains using IBC
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        The Cosmos Hub is built on the Cosmos SDK and uses a modular architecture to support a wide range of functionalities. The Hub is designed to be secure, scalable, and fast, making it an ideal platform for building decentralized applications and services.
                                    </p>

                                    <p>
                                        In the Cosmos network, the Hub plays a critical role in connecting various independent blockchains, allowing them to exchange value, data, and other assets. By integrating with the Hub, developers can create a network of interconnected blockchains that can operate seamlessly and securely.
                                    </p>

                                    <p>
                                        The Cosmos Hub also serves as a validator network, where validators are responsible for securing the network and processing transactions. Validators are selected based on their stake in the network and their ability to maintain the network's security and performance.
                                    Overall, the Cosmos Hub provides a reliable and secure platform for building decentralized applications and services that can interoperate with other blockchains in the Cosmos network.
                                    </p>

                                    <p>
                                        Inter-blockchain communication (IBC) is a protocol used for communication between independent blockchains, enabling cross-chain data transfer and interaction. It allows different blockchains to exchange data and value with each other in a secure and decentralized manner.
                                        The IBC protocol is designed to facilitate the secure and reliable transfer of tokens, data, and other digital assets between different blockchains. It uses a standardized set of rules and protocols that allow blockchains to communicate with each other in a standardized and interoperable manner.
                                    </p>

                                    <p>
                                        The IBC protocol consists of two main components: the IBC module, which is a set of software tools and APIs that enable communication between different blockchains, and the IBC relayer, which is a software application that facilitates the transfer of data and tokens between blockchains.
                                    </p>

                                    <p>
                                        To set up an IBC connection between blockchains, you need to configure the IBC modules on both chains and establish a connection between them. This involves defining the source and destination chains, selecting the appropriate channels and ports, and configuring the appropriate cryptographic keys and signatures.
                                    </p>

                                    <p>
                                        Once the IBC connection is established, you can transfer tokens and other digital assets between the connected blockchains. This can be done using a variety of different protocols and mechanisms, depending on the specific use case and requirements of the application.
                                    </p>

                                    <p>
                                        Overall, IBC is an important and powerful tool for enabling interoperability between independent blockchains, enabling a wide range of new use cases and applications in the blockchain space. It also provides a powerful mechanism for enhancing the security and reliability of blockchain networks, by enabling cross-chain security mechanisms and allowing blockchains to share resources and data in a secure and decentralized manner.
                                    </p>

                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with the first section <Link to='/'><b>Working with other blockchains using IBC (Inter-blockchain communication)</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmosModuleThreeTwo;