import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const ErrorHandlingInGo = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'DEV LESSONS BASICS',
                                    href: 'dev-lessons-basics',
                                },
                                {
                                    title: 'Error handling in go',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Error Handling in Go</h1>
                                <p>
                                    In this lesson, we will cover error handling in Go, including understanding Go's error-handling approach, using the error interface, and returning errors from functions and handling them.
                                </p>

                                <div className="learn-module">
                                    <h1>Lesson Objectives:</h1>
                                    <div className="learn-m-card">
                                        <p>By the end of this lesson, you should be able to:</p>
                                        <ul>
                                            <li>
                                                Understand Go's error-handling approach.
                                            </li>
                                            <li>
                                                Use the error interface in Go.
                                            </li>
                                            <li>
                                               Return errors from functions and handle them in Go.
                                            </li>
                                        </ul>
                                    </div>

                                    <h3>Understanding Go's Error-Handling Approach</h3>

                                    <p>
                                        In Go, errors are treated as values that can be returned from functions. Go's approach to error handling emphasizes explicit error checking and handling rather than exceptions. This means that instead of throwing exceptions when an error occurs, functions in Go return error values that must be checked and handled explicitly.
                                    </p>

                                    <p>
                                        Go's error-handling approach encourages programmers to handle errors at the point where they occur rather than letting them propagate up the call stack. This makes it easier to reason about error handling and to debug problems when they occur.
                                    </p>

                                    <h3>Using the Error Interface</h3>
                                    <p>
                                        The error interface in Go is a built-in interface that defines the Error() method. The Error() method returns a string representation of the error. By convention, errors in Go are represented as values that implement the error interface.
                                    </p>
                                    <CodeSnippet
codeData={`type error interface {    
    Error() string
    }`}
/>
                                    <p>
                                        To create a new error in Go, you can use the errors.New() function, which takes a string argument and returns a new error value that implements the error interface.
                                    </p>
                                    <CodeSnippet
codeData={`import "errors"
                                    
func foo() error {    
    return errors.New("an error occurred")
}`}
/>
                                   
                                    <p>
                                        In the above example, the foo() function returns a new error value that contains the message "an error occurred". This error value can be checked and handled by the calling function.
                                    </p>

                                    <h3>Returning Errors from Functions and Handling Them</h3>
                                    <p>
                                        Functions in Go can return error values using the error interface. When a function returns an error, the calling function can check the error value to determine whether the function succeeded or failed
                                    </p>
                                    <CodeSnippet
codeData={`func readFile(filename string) ([]byte, error) {    
    data, err := ioutil.ReadFile(filename)    
    if err != nil {        
        return nil, err    
    }    
    return data, nil
}`}
/>

                                    <p>
                                        In the above example, the readFile() function reads the contents of a file and returns them as a byte slice. If an error occurs while reading the file, the readFile() function returns nil and the error value returned by ioutil.ReadFile(). The calling function can then check the error value to determine whether the file was read successfully.
                                    </p>
                                    <CodeSnippet
codeData={`func main() {    
    data, err := readFile("file.txt")    
    if err != nil {        
        fmt.Println("Error reading file:", err)        
        return    
    }    
    fmt.Println(string(data))
}`}
/>
                                    <p>
                                        In the above example, the main() function calls the readFile() function and checks the error value returned. If an error occurred, the error is printed to the console. Otherwise, the contents of the file are printed to the console.
                                    </p>
                                    <h3>Conclusion</h3>
                                    <p>
                                        In summary, error handling is a critical aspect of writing reliable and robust software in Go. By understanding Go's error-handling approach, using the error interface, and returning errors from functions and handling them, you can write Go code that is more reliable and easier to debug.
                                    </p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/concurrency-ingo'><b>Concurrency in Go.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ErrorHandlingInGo;