import React from "react";
import { Container, SvgIcon } from "../../components/common";
import { Button } from "antd";
import "./index.less"

const Error404 = () => {
    return (
        <div className="homepage-wrapper">
            <section className="nopage-wrapper">
                <Container>
                    <div className="icon-col">
                        <SvgIcon className="icon-left" name="fouricon1" viewbox="0 0 96 121" />
                        <SvgIcon className="icon-center" name="planet-icon" viewbox="0 0 124.696 124.696" />
                        <SvgIcon className="icon-right" name="fouricon1" viewbox="0 0 96 121" />
                    </div>
                    <h2>OOPS!</h2>
                    <p>Page not found</p>
                    <Button type="secondary" href="/">BACK TO HOME</Button>
                </Container>
            </section>
        </div>
    )
}

export default Error404