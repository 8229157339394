import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const DevLessonsAdvance = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'CREATE YOUR OWN COMDEX MODULE',
                                }
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Create your own Comdex Module</h1>
                                <p>
                                    Learn how to code or improve your coding skills. These lessons are designed to provide step-by-step guidance on creating your first Module on Comdex.
                                </p>
                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                <div className="course-outline">
                                    <h1>Course Outline</h1>
                                    <div className='course-row'>
                                        <div className='left-col'>
                                            <h3>Module </h3>
                                            <h2>1</h2>
                                        </div>
                                        <div className='right-col'>
                                            <h3>Creating Module on Comdex</h3>
                                            <ul>
                                                <li><Link to='/module-structure'>Module Structure</Link></li>
                                                <li><Link to='/importing-methods'>Importing Methods</Link></li>
                                                <li><Link to='/transactions'>Transactions</Link></li>
                                                <li><Link to='/create-request'>Create Request</Link></li>
                                                <li><Link to='/approve-request'>Approve Request</Link></li>
                                                <li><Link to='/repay-request'>Repay Request</Link></li>
                                                <li><Link to='/cancel-request'>Cancel Request</Link></li>
                                                <li><Link to='/cli-query'>CLI Query</Link></li>
                                                <li><Link to='/cli-txn'>CLI Txn</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <h2>Loan Module</h2>
                                        <p>
                                            Decentralized finance (DeFi) is a rapidly growing sector of the blockchain ecosystem that offers innovative financial products and services, including lending, borrowing, trading, and flash loans. These services are accessible to anyone with an internet connection and a digital wallet, without complex onboarding processes or the submission of personal documents. DeFi is an attractive alternative to traditional banking systems, as it is faster, cheaper, and more convenient.
                                        </p>
                                        <p>
                                            In this tutorial, you will learn how to create a DeFi platform that enables users to lend and borrow digital assets from each other. The platform is powered by a blockchain, providing a decentralized and immutable record of all transactions. This ensures transparency, security, and resistance to fraud.
                                        </p>
                                        <p>
                                            A loan is a financial transaction in which one party, the borrower, receives a certain amount of assets and agrees to pay back the loan amount plus a fee to the lender by a predetermined deadline. The borrower provides collateral as security for the loan, which may be seized by the lender if the borrower fails to pay back the loan.
                                        </p>
                                        <p>
                                            A loan has several properties that define its terms and conditions, such as id, amount, fee, collateral, and deadline. The state of a loan describes its current status, such as requested, approved, paid, cancelled, or liquidated.
                                        </p>
                                        <p>
                                            In a loan transaction, there are two parties involved: the borrower and the lender. The borrower requests the loan and agrees to pay it back, while the lender approves the loan and provides the borrower with the loan amount.
                                        </p>
                                        <p>
                                            As a borrower, you can perform actions such as requesting a loan, cancelling a loan, or repaying a loan. Requesting a loan allows you to specify the terms and conditions of the loan while cancelling a loan enables you to withdraw your request. Repaying a loan allows you to pay back the loan amount plus the fee to the lender by the loan terms.
                                        </p>
                                        <p>
                                            As a lender, you can approve a loan or liquidate a loan. Approving a loan allows you to accept the terms and conditions of the loan and send the loan amount to the borrower. Liquidating a loan allows you to seize the collateral if the borrower is unable to pay back the loan by the deadline.
                                        </p>
                                        <p>
                                            By performing these actions, lenders and borrowers can interact with each other and facilitate the lending and borrowing of digital assets on the platform. The platform provides access to financial instruments and services that allow users to manage their assets and achieve their financial goals in a secure and transparent manner.
                                        </p>
                                    </div>
                                    <div className="mt-5">
                                        <h3>References</h3>
                                        <p className="mb-1"><a href="https://github.com/pratikasr/comdex/tree/development/x/loan" target="_blank" rel="noreferrer noopener">https://github.com/pratikasr/comdex/tree/development/x/loan</a></p>
                                    </div>
                                </div>
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default DevLessonsAdvance;