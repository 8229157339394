import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const BuildingCustomModulesCosmosSdk = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'builiding a custom module from scratch',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Building a Custom Module from Scratch</h1>
                                <p>
                                    State management is a fundamental aspect of any blockchain system, and the Cosmos SDK provides a flexible and modular state management framework. At the core of this framework are the KV stores, which allow developers to store and retrieve key-value pairs in a decentralized and secure manner.
                                </p>
                                <p>
                                    KV stores in Cosmos SDK are key-value stores that allow developers to store and retrieve data in a decentralized and secure manner. The KVStore interface is implemented by a variety of concrete stores, each of which provides a different type of storage backend, including in-memory stores, LevelDB stores, and RocksDB stores.
                                </p>
                                <ol type="1">
                                    <li>
                                        Choose a module name and create a new module directory under the x/ directory of your Cosmos SDK application. For example, if you want to create a module named mymodule, you would create a new directory at x/mymodule.
                                    </li>
                                    <li>
                                        Create a new module using the ignite scaffold command. This will generate a new module directory with the basic structure for a Cosmos SDK module, including a keeper package, a codec package, and a module package.
                                    </li>
                                    <li>
                                        Modify the generated code to implement your custom module logic. This typically involves modifying the following files:
                                        <ul className="list-2">
                                            <li>
                                                module.go: This file defines the main interface for your module and is where you implement your custom state, messages, queries, and keepers.
                                            </li>
                                            <li>handler.go: This file defines the message handlers for your module's transactions.</li>
                                            <li>querier.go: This file defines the query handlers for your module's queries.</li>
                                            <li>keeper.go: This file defines the main implementation for your module's keepers, which are responsible for managing the module's state and handling transactions and queries.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Register your module with the app.go file of your Cosmos SDK application. This involves adding your module's main interface to the list of modules registered with the NewApp() function.
                                        <p className="pt-3">scssCopy code</p>
                                        <CodeSnippet
codeData={`func NewApp(...) *baseapp.BaseApp { ... app := &MyApp{ ... // 
Register custom modules accountsmodule.NewAppModule(), 
bankmodule.NewAppModule(), mymodule.NewAppModule(), ... } ... }`}
/>
                                    </li>
                                    <li>Implement your module by editing the generated files in the x/mymodule/ folder. You will need to define the state, the transactions, the queries, and the handlers for your module.</li>
                                    <li>
                                        Build and run your Cosmos SDK application to test your module.
                                        <CodeSnippet
codeData={`$ make install $ <app-name> start`}
/>
                                    </li>
                                    <li>
                                        Finally, you can publish your module to the Cosmos SDK community by creating a GitHub repository and submitting a pull request to the Cosmos SDK repository.
                                    </li>
                                </ol>
                                <p>For more details visit: https://docs.ignite.com/</p>
                                <div className="nextup-bottom">
                                    <h2>Next Up</h2>
                                    <p className="bottom-link"> 
                                        Dive right into Cosmos with <Link to='/understanding-ibc'><b>Understanding IBC.</b></Link>
                                    </p>
                                </div>
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default BuildingCustomModulesCosmosSdk;