import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const SettingGoreLayer = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'setting up go relayer',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Setting Up Go Relayer</h1>
                                <p>
                                    Setting up a Go relayer between two Cosmos chains involves a few steps. Here is a high-level overview of the process:
                                </p>

                                <ol type="1">
                                    <li>
                                        Install the Go programming language and the Relayer package. You can find installation instructions for both on their respective websites.
                                    </li>
                                    <li>
                                        Create a configuration file for each of the chains you want to relay. This file should include the chain's ID, its RPC endpoint, and any other relevant information. You can find examples of configuration files in the Relayer documentation.
                                    </li>
                                    <li>
                                        <p>Initialize the relayer by running the following command:</p>
                                        <CodeSnippet
codeData={`rly init`}
/>

                                        <p>
                                            This will create a directory structure for the relayer and generate a configuration file for it.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Add the two chains to the relayer by running the following commands:</p>
                                        <CodeSnippet
codeData={ `rly chains add -f <path-to-config-file-1>
rly chains add -f <path-to-config-file-2>
rly keys add <chain1> user1
rly keys add <chain2> user2`}
/>
                                        
                                        <p>
                                            This will register the two chains with the relayer and enable it to communicate with them.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Create a connection between the two chains by running the following commands:</p>
                                        <CodeSnippet
codeData={`rly paths generate <src-chain-id> <dst-chain-id> <path-name>
rly paths show <path-name>`}
/>
                                        
                                        <p>
                                            The first command will generate a path between the two chains, and the second command will display the path details.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Finally, start the relayer by running the following command:</p>
                                        <CodeSnippet
codeData={`rly start <path-name>`}
/>
                                        <p>This will start the relayer and begin relaying packets between the two chains.</p>
                                    </li>
                                </ol>

                                <div className="nextup-bottom">
                                    <h2>Next Up</h2>
                                    <p className="bottom-link"> 
                                        Dive right into Cosmos with <Link to='/introduction-comdex'><b>Introduction to Comdex.</b></Link>
                                    </p>
                                </div>
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default SettingGoreLayer;