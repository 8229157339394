import React, { useState } from "react";
import { Container, SvgIcon } from "../../components/common";
import { Menu, Drawer, Button, Tabs } from "antd"
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive';
import "./index.less"

import GetMenuIcon from '../../assets/images/menu-icon.png';

const MenuItems = ({ menuIcon, menuTitle, infoText, url, target }) => {
    return (
        <>
            {url ?
                <Link to={url} target={target} className="menulink-item">
                    <div className="left-icon">
                        <SvgIcon name={menuIcon} viewbox='' />
                    </div>
                    <div className="right-col">
                        <div className="title">{menuTitle}</div>
                        <div className="menu-text">{infoText}</div>
                    </div>
                </Link>
                :
                <div className="menulink-item">
                    <div className="left-icon">
                        <SvgIcon name={menuIcon} />
                    </div>
                    <div className="right-col">
                        <div className="title">{menuTitle}</div>
                        <div className="menu-text">{infoText}</div>
                    </div>
                </div>
            }
        </>
    )
}

const items = [
    {
        label: 'Individuals',
        key: 'individuals',
        children: [
            {

                key: 'introduction',
                label: <MenuItems menuIcon='introduction' menuTitle='Introduction' infoText='DeFi infrastructure layer for Cosmos ecosystem' url='introduction' />,
            },
            {
                key: 'explore',
                label: <MenuItems menuIcon='explore' menuTitle='Explore' infoText='Explore global network of dApps' url='explore' />,
            },
            {
                key: 'faq',
                label: <MenuItems menuIcon='faq' menuTitle='FAQ' infoText='Have questions we have answers' url='faq' />,
            },
            {
                key: 'media-press-kit',
                label: <MenuItems menuIcon='media-press-kit' menuTitle='Media/Press Kit' infoText='Download our logos and media stuff' url='media-press-kit' />,
            },
            {
                key: 'get-cmdx',
                className: 'getcmdx-li',
                label: <div className="get-cmdx-bottom">
                            <MenuItems menuIcon='get-cmdx' menuTitle='Get CMDX' infoText='Grab your CMDX' url='get-cmdx' />
                            <img className="getmenu-icon" src={GetMenuIcon} alt='Get cmdx' />
                        </div>,
            },
        ],
    },
    {
        label: 'Developers',
        key: 'developers',
        children: [
            {

                key: 'developer-portal',
                label: <MenuItems menuIcon='developer-portal' menuTitle='Developer Portal' infoText='Learn how to start building on Comdex' url='developer-portal' />,
            },
            {
                key: 'docs',
                label: <MenuItems menuIcon='docs' menuTitle='Docs' infoText='Dive into Comdex chain and modules documentation' url='https://docs.comdex.one/' target="_blank" />,
            },
            {
                key: 'community-dev-fund',
                label: <MenuItems menuIcon='community-dev-fund' menuTitle='Community Dev Fund' infoText='Start building next generation dApps on Comdex' url='community-dev-fund' />,
            },
            {
                key: 'education-videos',
                label: <MenuItems menuIcon='education-videos' menuTitle='Education Videos' infoText='Unlocking knowledge through visual learning.' url='education-videos' />,
            }
        ],
    },
    {
        label: 'Ecosystem',
        key: 'ecosystem',
        children: [
            {

                key: 'ecosystemexplore',
                label: <MenuItems menuIcon='explore' menuTitle='Explore' infoText='Explore global network of dApps' url='explore' />,
            },
            // {
            //     key: 'events',
            //     label: <MenuItems menuIcon='events' menuTitle='Events' infoText='Coming Soon' />,
            //     className: 'non-url'
            // },
            {
                key: 'blogs',
                label: <MenuItems menuIcon='blogs' menuTitle='Blogs' infoText='Stay tuned with the latest news and updates' url='blogs' />,
            }
        ],
    },
];

const mobileMenu1 = [
    {

        key: 'introduction',
        label: <MenuItems menuIcon='introduction' menuTitle='Introduction' infoText='DeFi infrastructure layer for Cosmos ecosystem' url='introduction' />,
    },
    {
        key: 'explore',
        label: <MenuItems menuIcon='explore' menuTitle='Explore' infoText='Explore global network of dApps' url='explore' />,
    },
    {
        key: 'faq',
        label: <MenuItems menuIcon='faq' menuTitle='FAQ' infoText='Have questions we have answers' url='faq' />,
    },
    {
        key: 'media-press-kit',
        label: <MenuItems menuIcon='media-press-kit' menuTitle='Media/Press Kit' infoText='Download our logos and media stuff' url='media-press-kit' />,
    },
]

const mobileMenu2 = [
    {

        key: 'developer-portal',
        label: <MenuItems menuIcon='developer-portal' menuTitle='Developer Portal' infoText='Learn how to start building on Comdex' url='developer-portal' />,
    },
    {
        key: 'docs',
        label: <MenuItems menuIcon='docs' menuTitle='Docs' infoText='Dive into Comdex chain and modules documentation' url='https://docs.comdex.one/' target="_blank" />,
    },
    {
        key: 'community-dev-fund',
        label: <MenuItems menuIcon='community-dev-fund' menuTitle='Community Dev Fund' infoText='Start building next generation dApps on Comdex' url='community-dev-fund' />,
    },
    {
        key: 'education-videos',
        label: <MenuItems menuIcon='education-videos' menuTitle='Education Videos' infoText='Unlocking knowledge through visual learning.' url='education-videos' />,
    }
]

const mobileMenu3 = [
    {
        key: 'ecosystemexplore',
        label: <MenuItems menuIcon='explore' menuTitle='Explore' infoText='Explore global network of dApps' url='explore' />,
    },
    // {
    //     key: 'events',
    //     label: <MenuItems menuIcon='events' menuTitle='Events' infoText='Coming Soon' />,
    //     className: 'non-url'
    // },
    {
        key: 'blogs',
        label: <MenuItems menuIcon='blogs' menuTitle='Blogs' infoText='Stay tuned with the latest news and updates' url='blogs' />,
    }
]

const itemsTabs = [
    {
      key: '1',
      label: `Individuals`,
      children: <>
        <Menu items={mobileMenu1} /> 
        <div className="drawer-bottom">
            <MenuItems menuIcon='get-cmdx' menuTitle='Get CMDX' infoText='Grab your CMDX' url='get-cmdx' />
            <img className="getmenu-icon" src={GetMenuIcon} alt='Get cmdx' />
        </div>
      </>,
    },
    {
      key: '2',
      label: `Developers`,
      children: <Menu items={mobileMenu2} />,
    },
    {
      key: '3',
      label: `Ecosystem`,
      children: <Menu items={mobileMenu3} />,
    },
];

const NavigationBar = () => {
    const [open, setOpen] = useState(false);

    const [current, setCurrent] = useState('mail');

    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="header-wrapper">
                <Container>
                    <div className="header-inner">
                        <div className="logo">
                            <Link to="/"><SvgIcon name='logo' viewbox='0 0 157.066 34.926' /></Link>
                        </div>
                        <MediaQuery minWidth={991}>
                            <div className="center-menu">
                                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
                            </div>
                            <div className="right-menu">
                                <Link to='/get-cmdx'><Button type="primary">Get CMDX</Button></Link>
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={991}>
                            <div className="menu-icon" onClick={showDrawer}><SvgIcon name="menu" viewbox="0 0 27 21.569" /></div>
                            <Drawer maskStyle={{opacity: '0.1'}} height='410' className='mobile-drawer' title="" placement="top" onClose={onClose} open={open}>
                                <Tabs onClick={onClose} defaultActiveKey="1" items={itemsTabs} />
                            </Drawer>
                        </MediaQuery>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default NavigationBar