import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const CosmosModuleTwoTwo = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'WHAT IS COSMOS',
                                    href: '',
                                },
                                {
                                    title: 'MODULE 2',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Understanding the anatomy of a Cosmos application</h1>
                                <p>
                                    A Cosmos application is composed of different components that work together to facilitate the execution of transactions on the blockchain network. Understanding the anatomy of a Cosmos application is crucial for building and deploying applications on the network. The following are the main components of a Cosmos application:
                                </p>

                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                
                                <div className="learn-module">
                                    <h1>What will you learn in Module 2.2</h1>
                                    <div className="learn-m-card">
                                        <ul>
                                            <li>
                                                Overview of the different components of a Cosmos application
                                            </li>
                                            <li>
                                                Understanding the roles of the ABCI server, SDK modules, and other components
                                            </li>
                                            <li>
                                                Examining the interactions between the different components during application execution
                                            </li>
                                            <li>
                                                Understanding the lifecycle of a Cosmos transaction
                                            </li>
                                            <li>
                                                Best practices for designing and organizing a Cosmos application
                                            </li>
                                        </ul>
                                    </div>

                                    <h3>ABCI Server:</h3>
                                    <p>
                                        The Application Blockchain Interface (ABCI) server is responsible for handling communication between the Cosmos application and the Tendermint consensus engine. The ABCI server receives transactions from clients and passes them to the Tendermint consensus engine for validation and inclusion in the blockchain.
                                    </p>

                                    <h3>SDK Modules:</h3>
                                    <p>
                                        The Cosmos SDK is designed with a modular architecture that allows developers to create and integrate custom modules into their applications. SDK modules are responsible for handling the business logic of the application, such as managing state transitions and validating transactions.
                                    </p>

                                    <h3>State Machine:</h3>
                                    <p>
                                        The state machine is responsible for managing the state of the Cosmos application. It is responsible for processing transactions and updating the state of the application accordingly.
                                    </p>

                                    <h3>KV Store:</h3>
                                    <p>
                                        The key-value (KV) store is a database that is used to store the state of the application. It is used by the state machine to store and retrieve data related to the application state.
                                    </p>

                                    <h3>Clients:</h3>
                                    <p>
                                        Clients are applications that interact with the Cosmos application. They send transactions to the ABCI server, which then processes and validates them.
                                    </p>
                                    <p>
                                        During application execution, the different components of the Cosmos application interact with each other to facilitate the execution of transactions. When a client sends a transaction to the ABCI server, the server passes it to the SDK modules for processing. The modules then validate the transaction and update the state of the application using the KV store. The state machine then processes the transaction and updates the state of the application. <br />
                                        
                                        Understanding the lifecycle of a Cosmos transaction is also crucial for building and deploying applications on the network. The lifecycle of a Cosmos transaction includes the following stages:
                                    </p>

                                    <h3>1. Transaction Creation:</h3>
                                    <p>
                                        A client creates a transaction and sends it to the ABCI server.
                                    </p>

                                    <h3>2. Transaction Validation:</h3>
                                    <p>
                                        The ABCI server receives the transaction and passes it to the SDK modules for validation. The modules validate the transaction and ensure that it meets the required criteria.
                                    </p>

                                    <h3>3. State Transition:</h3>
                                    <p>
                                        If the transaction is valid, the SDK modules update the state of the application using the KV store. The state machine then processes the transaction and updates the state of the application.
                                    </p>

                                    <h3>4. Transaction Inclusion:</h3>
                                    <p>
                                        The Tendermint consensus engine validates the transaction and includes it in the next block in the blockchain.
                                    </p>
                                    <p>
                                        The Tendermint consensus engine validates the transaction and includes it in the next block in the blockchain.
                                    </p>

                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with the first section <Link to='/'><b>Writing smart contracts using Cosmos SDK.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmosModuleTwoTwo;