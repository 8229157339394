import React from "react";
import { Button, Divider } from "antd";
import Lottie from 'react-lottie';
import { Col, Container, Row, SvgIcon } from "../../components/common";
import MediaQuery from 'react-responsive';
import "./index.less";

import cosmosLogo from '../../assets/images/cosmos.svg';
import cosmwasmLogo from '../../assets/images/cosmwasm.svg';
import Validator1 from '../../assets/images/validator-1.png';
import Validator2 from '../../assets/images/validator-2.png';
import Validator3 from '../../assets/images/validator-3.png';
import Validator4 from '../../assets/images/validator-4.png';
import Validator5 from '../../assets/images/validator-5.png';
import Validator6 from '../../assets/images/validator-6.png';
import Validator7 from '../../assets/images/validator-7.png';
import Validator8 from '../../assets/images/validator-8.png';
import Validator9 from '../../assets/images/validator-9.png';
import Validator10 from '../../assets/images/validator-10.png';
import Validator11 from '../../assets/images/validator-11.png';
import Validator12 from '../../assets/images/validator-12.png';
import Validator13 from '../../assets/images/validator-13.png';
import Validator14 from '../../assets/images/validator-14.png';
import AkLogo from '../../assets/images/ak.png';
import inCubeOne from '../../assets/images/in-cube-1.png';
import inCubeTwo from '../../assets/images/in-cube-2.png';
import inCubeThree from '../../assets/images/cube-2.png';
import inCubeFour from '../../assets/images/cube-3.png';
import HelixImg1 from '../../assets/images/helix1.png';
import HelixImg2 from '../../assets/images/helix2.png';
import SolarImg from '../../assets/images/solar1.png';
import SecureAnimation from '../../assets/json/secure.json';
import ComposableAnimation from '../../assets/json/composable.json';
import DevfriendlyAnimation from '../../assets/json/devfriendly.json';
import DevAnimation from '../../assets/json/dev-portal.json';
import TexureLeft from '../../assets/images/blue-texture-left.png';
import TexureLeft2 from '../../assets/images/blue-texture-left2.png';
import TexureRight from '../../assets/images/blue-texture-right.png';
import LineBgLeft from '../../assets/images/line-bg-left.png';

const Introduction = () => {
    const animationLottie1 = {
        loop: true,
        autoplay: true,
        animationData: SecureAnimation,
        renderer: 'svg',
    }
    const animationLottie2 = {
        loop: true,
        autoplay: true,
        animationData: ComposableAnimation,
        renderer: 'svg',
    }
    const animationLottie3 = {
        loop: true,
        autoplay: true,
        animationData: DevAnimation,
        renderer: 'svg',
    }
    const animationLottie4 = {
        loop: true,
        autoplay: true,
        animationData: DevfriendlyAnimation,
        renderer: 'svg',
    }
    return (
        <div className="homepage-wrapper introduction-wrapper">
            <section className="banner-section">
                <img src={SolarImg} alt="Welcome to Comdex" className="welcome-solar" />
                <img className="blue-left" src={TexureLeft} alt="" />
                <img className="blue-right" src={TexureRight} alt="" />
                <Container>
                    <Row className="banner-row">
                        <Col>
                            <h1>Welcome to Comdex</h1>
                            <p>Comdex is an Interchain DeFi infrastructure layer to build applications to power DeFi in the Cosmos ecosystem and beyond by utilising on-chain modules of the prevailing primitives in the worlds of DeFi and Finance.</p>
                            <div className="text-center">
                                <a href="#secure-section" > <Button type="secondary" size="large">Learn More</Button></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="comdex-intro" id="secure-section">
                <Container>
                    <Row className='row-1'>
                        <Col md='5'>
                            <h2>Secure</h2>
                            <p>
                                Build secure DeFi applications by leveraging the security of the Comdex chain, powered by the CMDX token. Built using Cosmos SDK and Cosmwasm, users can utilise modules on the Comdex chain to seamlessly deploy dApps to the chain.
                            </p>
                        </Col>
                        <Col md='7' className='col-img'>
                            <img className="cube-1" src={inCubeOne} alt={inCubeOne} />
                            <img className="cube-2" src={inCubeTwo} alt={inCubeTwo} />
                            <img className="cube-3" src={inCubeThree} alt={inCubeThree} />
                            <img className="cube-4" src={inCubeFour} alt={inCubeFour} />
                            <MediaQuery minWidth={600}>
                                <Lottie
                                    options={animationLottie1}
                                    height={380}
                                    width={380}
                                    style={{ maxWidth: '100%' }}
                                />
                            </MediaQuery>
                            <MediaQuery maxWidth={599}>
                                <Lottie
                                    options={animationLottie1}
                                    height={250}
                                    width={250}
                                    style={{ maxWidth: '100%' }}
                                />
                            </MediaQuery>
                        </Col>
                    </Row>
                    <Row className='row-2'>
                        <Col md='7' className='col-img'>
                            <img className="helix-img" src={HelixImg1} alt={HelixImg1} />
                            <img className="cube-1" src={inCubeOne} alt={inCubeOne} />
                            <img className="cube-2" src={inCubeThree} alt={inCubeThree} />
                            <MediaQuery minWidth={600}>
                                <Lottie
                                    options={animationLottie4}
                                    height={480}
                                    width={480}
                                    style={{ maxWidth: '100%' }}
                                />
                            </MediaQuery>
                            <MediaQuery maxWidth={599}>
                                <Lottie
                                    options={animationLottie4}
                                    height={250}
                                    width={250}
                                    style={{ maxWidth: '100%' }}
                                />
                            </MediaQuery>
                        </Col>
                        <Col md='5'>
                            <h2>Dev-Friendly</h2>
                            <p>
                                An all encompassing DeFi focused chain to ensure all governance and development is designed for optimal synergy between all the application built on the chain.
                            </p>
                        </Col>
                    </Row>
                    <Row className='row-3'>
                        <Col md='5'>
                            <h2>Composable</h2>
                            <p>
                                All dApps builts on the Comdex chain are fully interoperable across the IBC and composable to enhance DeFi user experience and deliver seamless access to the ecosystem of products.
                            </p>
                        </Col>
                        <Col md='7' className='col-img'>
                            <img className="helix-img" src={HelixImg2} alt={HelixImg2} />
                            <img className="cube-1" src={inCubeOne} alt={inCubeOne} />
                            <img className="cube-2" src={inCubeThree} alt={inCubeThree} />
                            <MediaQuery minWidth={600}>
                                <Lottie
                                    options={animationLottie2}
                                    height={400}
                                    width={400}
                                    style={{ maxWidth: '100%' }}
                                />
                            </MediaQuery>
                            <MediaQuery maxWidth={599}>
                                <Lottie
                                    options={animationLottie2}
                                    height={250}
                                    width={250}
                                    style={{ maxWidth: '100%' }}
                                />
                            </MediaQuery>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="about-section">
                <img className="left-bg" src={TexureLeft2} alt="Comdex" />
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <p>TECHNOLOGY</p>
                                <h1>Powering Comdex</h1>
                                <div className="divider"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='powering-row'>
                        <Col sm='6' className='left-col'>
                            <div className="logos-row">
                                <img src={cosmosLogo} alt={cosmosLogo} />
                                <Divider type="vertical" />
                                <img src={cosmwasmLogo} alt={cosmwasmLogo} />
                            </div>
                            <h1>Built on Cosmos SDK</h1>
                            <h3>{"&"}</h3>
                            <h1>Interoperable Smart Contracts</h1>
                            <p>IBC-enabled DeFi Infrastructure Layer for building an interoperable ecosystem of solutions</p>
                            <Button type="secondary" onClick={() => {
                                let newWindow = window.open("https://blog.comdex.one/comdex-integrates-cosmwasm-e819b4502deb", "_blank");
                                newWindow.opener = null;
                            }}>Learn More</Button>
                        </Col>
                        <Col sm='6' className='right-col'>
                            <Divider type="vertical" />
                            <div>
                                <h4>Validators</h4>
                            </div>
                            <ul>
                                <li>
                                    <img src={Validator1} alt={Validator1} />
                                </li>
                                <li>
                                    <img src={Validator2} alt={Validator2} />
                                </li>
                                <li>
                                    <img src={Validator3} alt={Validator3} />
                                </li>
                                <li>
                                    <img src={Validator4} alt={Validator4} />
                                </li>
                                <li>
                                    <img src={Validator5} alt={Validator5} />
                                </li>
                                <li>
                                    <img src={Validator6} alt={Validator6} />
                                </li>
                                <li>
                                    <img src={Validator7} alt={Validator7} />
                                </li>
                                <li>
                                    <img src={Validator8} alt={Validator8} />
                                </li>
                                <li>
                                    <img src={Validator9} alt={Validator9} />
                                </li>
                                <li>
                                    <img src={Validator10} alt={Validator10} />
                                </li>
                                <li>
                                    <img src={Validator11} alt={Validator11} />
                                </li>
                                <li>
                                    <img src={Validator12} alt={Validator12} />
                                </li>
                                <li>
                                    <img src={Validator13} alt={Validator13} />
                                </li>
                                <li>
                                    <img src={Validator14} alt={Validator14} />
                                </li>
                            </ul>
                            <a href="https://www.mintscan.io/comdex/validators" target="_blank" rel="noreferrer"><h3>+75 More</h3></a>
                            <div>
                                <a href="https://github.com/oak-security/audit-reports/tree/master/Comdex" target="_blank" rel="noreferrer"><h4>Security Audits <SvgIcon name="arrow-right" viewbox="0 0 15 15" /></h4></a>
                            </div>
                            <img className="ak-logo" src={AkLogo} alt={AkLogo} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="plugmodule-section">
                <img className="line-bg" src={LineBgLeft} alt='' />
                <Container>
                    <Row className='align-items-center'>
                        <Col md='7'>
                            <h2 className="mb-4">Plug-and-Play Modules</h2>
                            <p>
                                The Comdex chain comprises of a variety of modules built to replicate the prevailing transactional primitives in DeFi and Finance. Each module is built to enable the creation of any DeFi application through its plug-and-play design. Build highly composable applications to power interchain DeFi by deploying using Comdex's modules.
                            </p>
                            <div className="mt-5">
                                <Button type="primary" href="/developer-portal " target="_blank" rel="noreferrer noopener">Developer Portal</Button>
                            </div>
                        </Col>
                        <Col md='5'>
                            <MediaQuery minWidth={600}>
                                <Lottie
                                    options={animationLottie3}
                                    height={450}
                                    width={550}
                                    style={{ maxWidth: '100%' }}
                                />
                            </MediaQuery>
                            <MediaQuery maxWidth={599}>
                                <Lottie
                                    options={animationLottie3}
                                    height={450}
                                    width={550}
                                    style={{ maxWidth: '100%' }}
                                />
                            </MediaQuery>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.com/invite/7vjPvWKKMT' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Introduction