import React from "react";
import { NavLink } from "react-router-dom";
import { SvgIcon } from "../../../components/common";
import { Collapse } from "antd";
import "./index.less";

const { Panel } = Collapse;

const LeftNav = () => {
    return (
        <div className='introductiondtl-left'>
            <Collapse accordion defaultActiveKey={['1']} ghost expandIconPosition='end' 
                expandIcon={() => (<SvgIcon name='drop-icon' viewbox='0 0 12 8' />)}>
                <Panel header={<NavLink to='/cosmos-introduction'>Intro to Cosmos SDK for Beginners</NavLink>} key="1">
                    <NavLink to='/whatis-Cosmos-sdk'>
                        Introduction to Cosmos SDK
                    </NavLink>
                    <NavLink to='/cosmossdk-basics'>
                        Cosmos SDK Basics
                    </NavLink>
                    <NavLink to='/state-management'>
                        State Management with Cosmos SDK
                    </NavLink>
                    <NavLink to='/building-custommodules'>
                        Building Custom Modules in Cosmos SDK
                    </NavLink>
                    <NavLink to='/understanding-ibc'>
                        Interacting with External Networks
                    </NavLink>
                </Panel>
                <Panel header={<NavLink to='/introduction-comdex'>Introduction to Comdex</NavLink>} key="2">
                    <Collapse accordion ghost expandIconPosition='end' 
                expandIcon={() => (<SvgIcon name='drop-icon' viewbox='0 0 12 8' />)}>
                        <Panel header={<NavLink to='/dev-lessons-basics'>Comdex Architecture</NavLink>} key="21">
                            <div className="pl-2 mb-1">
                                <NavLink to='/comdex-architecture'>
                                    Modules
                                </NavLink>
                            </div>
                        </Panel>
                        <Panel header={<NavLink to='/settingup-devnet'>Setting Up Devnet</NavLink>} key="22">
                            <div className="pl-2 mb-3">
                                <NavLink to='/settingup-devnet'>
                                    Setting Up Devnet
                                </NavLink>
                            </div>
                        </Panel>
                    </Collapse>
                </Panel>
                <Panel header={<NavLink to='/dev-lessons-basics'>Dev Lessons(Basics)</NavLink>} key="3">
                    <div className="mb-2">
                        <NavLink to='/dev-lessons-basics'>
                            Get Fimiliar with Go
                        </NavLink>
                    </div>
                    <Collapse accordion ghost expandIconPosition='end' 
                expandIcon={() => (<SvgIcon name='drop-icon' viewbox='0 0 12 8' />)}>
                        <Panel header={<NavLink to='/introduction-togo-programming'>Introduction to Go Programming</NavLink>} key="31">
                            <div className="pl-2 mb-3">
                                <NavLink to='/introduction-togo-programming'>
                                    Introduction to Go Programming
                                </NavLink>
                                <NavLink to='/gosyntax-variables'>
                                    Go Syntax and Variables
                                </NavLink>
                                <NavLink to='/control-structures-ingo'>
                                    Control Structures in Go
                                </NavLink>
                                <NavLink to='/functions-ingo'>
                                    Functions in Go
                                </NavLink>
                            </div>
                        </Panel>
                        <Panel header={<NavLink to='/arrays-and-slices'>Intermediate Go Programming</NavLink>} key="32">
                            <div className="pl-2 mb-3">
                                <NavLink to='/arrays-and-slices'>
                                    Arrays and Slices
                                </NavLink>
                                <NavLink to='/pointers-and-struct'>
                                    Pointers and Structs
                                </NavLink>
                                <NavLink to='/error-handling-ingo'>
                                    Error Handling in Go
                                </NavLink>
                            </div>
                        </Panel>
                        <Panel header={<NavLink to='/concurrency-ingo'>Advanced Go Programming</NavLink>} key="33">
                            <div className="pl-2 mb-3">
                                <NavLink to='/concurrency-ingo'>
                                    Concurrency in Go
                                </NavLink>
                            </div>
                        </Panel>
                    </Collapse>
                    <div className="mb-2">
                        <NavLink to='/devlessons-with-rust'>
                            Get Fimiliar with Rust
                        </NavLink>
                    </div>
                    <Collapse accordion ghost expandIconPosition='end' 
                expandIcon={() => (<SvgIcon name='drop-icon' viewbox='0 0 12 8' />)}>
                        <Panel header={<NavLink to='/introduction-rust-programming'>Introduction to Rust Programming</NavLink>} key="34">
                            <div className="pl-2 mb-3">
                                <NavLink to='/introduction-rust-programming'>
                                    Introduction to Rust Programming
                                </NavLink>
                                <NavLink to='/rustsyntax-and-variables'>
                                    Rust Syntax and Variables
                                </NavLink>
                                <NavLink to='/control-structures-inrust'>
                                    Control Structures in Rust
                                </NavLink>
                                <NavLink to='/functions-inrust'>
                                    Functions in Rust
                                </NavLink>
                            </div>
                        </Panel>
                        <Panel header={<NavLink to='/ownership-borrowing-inrust'>Intermediate Rust Programming</NavLink>} key="35">
                            <div className="pl-2 mb-3">
                                <NavLink to='/ownership-borrowing-inrust'>
                                    Ownership and Borrowing in Rust
                                </NavLink>
                                <NavLink to='/structs-enums-inrust'>
                                    Structs and Enums in Rust
                                </NavLink>
                                <NavLink to='/error-handling-inrust'>
                                    Error Handling in Rust
                                </NavLink>
                            </div>
                        </Panel>
                        <Panel header={<NavLink to='/concurrency-inrust'>Advanced Rust Programming</NavLink>} key="36">
                            <div className="pl-2 mb-3">
                                <NavLink to='/concurrency-inrust'>
                                    Concurrency in Rust
                                </NavLink>
                            </div>
                        </Panel>
                    </Collapse>
                    <div className="mb-4">
                        <NavLink to='/working-withfiles-inrust'>
                            Working with Files and I/O in Rust
                        </NavLink>
                    </div>
                    <Collapse accordion ghost expandIconPosition='end' 
                expandIcon={() => (<SvgIcon name='drop-icon' viewbox='0 0 12 8' />)}>
                        <Panel className="px-0" header={<NavLink to='/create-your-dApp'>Create your First dApp on Comdex</NavLink>} key="37">
                            <Collapse ghost expandIconPosition='end' 
                expandIcon={() => (<SvgIcon name='drop-icon' viewbox='0 0 12 8' />)}>
                                <Panel header={<NavLink to='/build-your-own-dApp'>Creating dApp on Comdex</NavLink>} key="38">
                                    <div className="pl-2 mb-3">
                                        <NavLink to='/build-your-own-dApp'>
                                            Creating dApp on Comdex
                                        </NavLink>
                                    </div>
                                </Panel>
                            </Collapse>
                        </Panel>
                    </Collapse>
                </Panel>
                <Panel header={<NavLink to='/dev-lessons-advance'>Dev Lessons(Advance)</NavLink>} key="4">
                    <Collapse accordion ghost expandIconPosition='end' 
                expandIcon={() => (<SvgIcon name='drop-icon' viewbox='0 0 12 8' />)}>
                        <Panel header={<NavLink to='/module-structure'>Create your own Comdex Module</NavLink>} key="41">
                            <div className="pl-2 mb-3">
                                <NavLink to='/module-structure'>Module Structure</NavLink>
                                <NavLink to='/importing-methods'>Importing Methods</NavLink>
                                <NavLink to='/transactions'>Transactions</NavLink>
                                <NavLink to='/create-request'>Create Request</NavLink>
                                <NavLink to='/approve-request'>Approve Request</NavLink>
                                <NavLink to='/repay-request'>Repay Request</NavLink>
                                <NavLink to='/cancel-request'>Cancel Request</NavLink>
                                <NavLink to='/cli-query'>CLI Query</NavLink>
                                <NavLink to='/cli-txn'>CLI Txn</NavLink>
                            </div>
                        </Panel>
                        <Panel header={<NavLink to='/gettingstarted-with-cosmWasm'>Getting started with CosmWasm</NavLink>} key="42">
                            <div className="pl-2 mb-3">
                                <NavLink to='/whatis-cosmWasm'>What is CosmWasm</NavLink>
                                <NavLink to='/how-touse-cosmWasm'>How to use CosmWasm</NavLink>
                                <NavLink to='/pre-requisites'>Pre-Requisites</NavLink>
                                <NavLink to='/cosmWasm-development'>Development</NavLink>
                                <NavLink to='/example-ofRust-contract'>Example of Rust Contract</NavLink>
                            </div>
                        </Panel>
                    </Collapse>
                </Panel>
            </Collapse>
        </div>
    )
}

export default LeftNav;