import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const Transactions = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'CREATE YOUR OWN COMDEX MODULE',
                                        href: 'dev-lessons-advance',
                                    },
                                    {
                                        title: 'TRANSACTIONS',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Transaction Messages</h1>
                                <p>
                                    Here are the list of txn messages defined in tx.proto of the loan module. We will be using these transaction to create, approve, repay and cancel a loan.
                                </p>

                                <div className="learn-module">
                                <CodeSnippet
codeData={`message MsgCreateRequest {
    string from = 1 [(gogoproto.moretags) = "yaml:\"from\""];
    uint64 app_id = 2 [(gogoproto.moretags) = "yaml:\"app_id\""];
    uint64 pair_id = 3 [    
        (gogoproto.moretags) = "yaml:\"pair_id\""
    ];
    string amount_in = 4 [    
        (gogoproto.customtype) = "github.com/cosmos/cosmos-sdk/types.Int",    
        (gogoproto.moretags) = "yaml:\"amount_in\"",    
        (gogoproto.nullable) = false
    ];
    string amount_out = 5 [    
        (gogoproto.customtype) = "github.com/cosmos/cosmos-sdk/types.Int",    
        (gogoproto.moretags) = "yaml:\"amount_out\"",    
        (gogoproto.nullable) = false
    ];
    string fee = 6 [
        (gogoproto.customtype) = "github.com/cosmos/cosmos-sdk/types.Int",
        (gogoproto.moretags) = "yaml:\"fee\"",
        (gogoproto.nullable) = false
    ];
    int64 duration_days = 7 [
        (gogoproto.moretags) = "yaml:\"duration_days\""
    ];
    }
    
    message MsgCreateResponse {}
    
    message MsgApproveRequest {
    string from = 1 [(gogoproto.moretags) = "yaml:\"from\""];
    uint64 loan_id = 2 [(gogoproto.moretags) = "yaml:\"loan_id\""];
    }
    
    message MsgApproveResponse {}
    
    message MsgRepayRequest {
    string from = 1 [(gogoproto.moretags) = "yaml:\"from\""];
    uint64 loan_id = 2 [(gogoproto.moretags) = "yaml:\"loan_id\""];
    }
    
    message MsgRepayResponse {}
    
    message MsgCancelRequest {
    string from = 1 [(gogoproto.moretags) = "yaml:\"from\""];
    uint64 loan_id = 2 [(gogoproto.moretags) = "yaml:\"loan_id\""];
    }
    
    message MsgCancelResponse {}
    
    
    service Msg {
    rpc MsgCreate(MsgCreateRequest) returns (MsgCreateResponse);
    rpc MsgApprove(MsgApproveRequest) returns (MsgApproveResponse);
    rpc MsgRepay(MsgRepayRequest) returns (MsgRepayResponse);
    rpc MsgCancel(MsgCancelRequest) returns (MsgCancelResponse);
}`}
/>
                                    <p>Here, is the basic struct of our loan which any user can request.</p>

                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/create-request'><b>Create Request.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Transactions;