import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const CosmosModuleOne = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'WHAT IS COSMOS',
                                    href: '',
                                },
                                {
                                    title: 'MODULE 1',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Overview of Cosmos SDK and its architecture</h1>
                                <p>
                                    The Cosmos Network is a decentralized network of independent blockchains that are able to communicate with each other using the Inter-Blockchain Communication (IBC) protocol. The Cosmos SDK is a framework for building blockchain applications on top of the Cosmos Network that can be interoperable with other blockchains in the network.
                                </p>

                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                
                                <div className="learn-module">
                                    <h1>What will you learn in Module 1.1</h1>
                                    <div className="learn-m-card">
                                        <ul>
                                            <li>
                                                Introduction to the Cosmos Network and its ecosystem
                                            </li>
                                            <li>
                                                Understanding the role of the Cosmos SDK in building blockchain applications
                                            </li>
                                            <li>
                                                Overview of the Cosmos SDK architecture and its components
                                            </li>
                                            <li>
                                                Exploring the SDK's modules and how they work together to create applications
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        The Cosmos SDK provides developers with a flexible and modular platform to create custom blockchain applications with their desired features and functionality. It is designed to be modular, meaning developers can add or remove features as they see fit. This modularity also allows developers to use the Cosmos SDK for different use cases, from creating simple applications to building complex decentralised applications.
                                    </p>

                                    <p>
                                        The architecture of the Cosmos SDK consists of several components that work together to create blockchain applications:
                                    </p>

                                    <h3>The Application Blockchain Interface (ABCI): </h3>
                                    <p>
                                        This is the interface between the blockchain application and Tendermint Core, the consensus engine used by the Cosmos Network. ABCI defines how the application processes transactions and stores state. This interface ensures that the blockchain application can communicate with Tendermint Core.
                                    </p>

                                    <h3>The Cosmos SDK modules:</h3>
                                    <p>
                                        These are pre-built components that developers can use to add functionality to their blockchain applications. The SDK comes with a set of pre-built modules, such as staking, governance, and token issuance. Developers can also create their own modules to add custom functionality.
                                    </p>

                                    <h3>The SDK Command Line Interface (CLI):</h3>
                                    <p>
                                        This is a set of command-line tools that developers can use to interact with the Cosmos SDK and manage their blockchain applications. The CLI provides commands for creating new applications, adding modules, and testing the application.
                                    </p>

                                    <h3>The SDK software development kit (SDK):</h3>
                                    <p>
                                        This is a collection of software development tools, libraries, and documentation that developers can use to create Cosmos SDK applications. The SDK includes programming languages like Golang and Rust, which are commonly used in blockchain development.
                                    </p>
                                    <p>
                                        To create a Cosmos SDK application, developers can use the pre-built modules provided by the SDK or they can build their own modules. Modules work together to create a custom blockchain application that can interact with other blockchains in the Cosmos Network. For example, a developer can use the staking module to add the ability for users to earn rewards for participating in the network and use the governance module to allow users to vote on proposals that affect the network.
                                    </p>

                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with the first section <Link to='/'><b>Comparison with other blockchain frameworks.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmosModuleOne;