import { Button, Divider } from "antd";
import React from "react";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import { Link } from 'react-router-dom';
import "./index.less";

import BannerBg from '../../assets/images/dev-fund-bg.png';
import SyndicateIcon from '../../assets/images/Syndicate.png';
import SkipIcon from '../../assets/images/Skip.png';
import WhiteWhaleIcon from '../../assets/images/White-Whale.png';
import stablecoinIcon from '../../assets/images/stablecoin.png';
import bridgingIcon from '../../assets/images/bridging.png';
import infrastructureIcon from '../../assets/images/infrastructure.png';
import Cube1 from '../../assets/images/in-cube-1.png';
import Cube2 from '../../assets/images/in-cube-2.png';
import Cube3 from '../../assets/images/cube-2.png';
import Cube4 from '../../assets/images/cube-1.png';
import TopBlueBg from '../../assets/images/blue-texture-left.png';
import LineLeft from '../../assets/images/line-bg-left.png';
import BlueLeft from '../../assets/images/blue-texture-left.png';
import BlueRight from '../../assets/images/blue-texture-right.png';

const ExploreCard = ({ name, descriptions, iconName, type, live, commingsoon }) => {
    return (
        <div className='explore-card'>
            <div className="explore-card-inner">
                <div className="card-upper">
                    <div className="left-col">
                        <div className="icon-col">
                            <img src={iconName} alt={name} />
                        </div>
                        <h3>{name}</h3>
                    </div>
                    <div className="right-col">
                        <div className="name">{type}</div>
                        {live && <div className="status live"><span></span> Live</div>}
                        {commingsoon && <div className="status commingsoon"><span></span> COMING SOON</div>}
                    </div>
                </div>
                <div className="card-bottom">
                    <p>
                        {descriptions}
                    </p>
                </div>
            </div>
        </div>
    )
}

const CommunityDevFund = () => {
    return (
        <div className="homepage-wrapper decfund-wrapper">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col sm='7'>
                            <h1>Community Dev <br /> Fund</h1>
                            <p>
                                Funding projects that help expand the Comdex ecosystem of products.
                            </p>
                            <div className="mt-5">
                                <Link target="_blank"
                                    rel="noreferrer noopener"
                                    to="https://forum.comdex.one/t/how-to-apply-for-the-comdex-dev-fund/792">
                                    <Button type="primary" className="px-5">Apply</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <img className="explore-banner" src={BannerBg} alt={BannerBg} />
            </section>
            <section className="developers-section">
                <Container>
                    <Row>
                        <Col>
                            <div className="headings">
                                <h2>Why apply</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="icon-circle">
                                        <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                    </div>
                                    <h3>Early Stage <br /> Funding</h3>
                                    <p>
                                        Build secure DeFi applications by leveraging the security of the Comdex chain, powered by the CMDX token. Built using Cosmos SDK and Cosmwasm, users can utilise modules on the Comdex chain to seamlessly deploy dApps to the chain.
                                    </p>
                                </li>
                                <li>
                                    <div className="icon-circle">
                                        <SvgIcon name='support-icon' viewbox='0 0 36 34' />
                                    </div>
                                    <h3>Technical <br /> Support</h3>
                                    <p>
                                        An all encompassing DeFi focused chain to ensure all governance and development is designed for optimal synergy between all the application built on the chain.
                                    </p>
                                </li>
                                <li>
                                    <div className="icon-circle">
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                    </div>
                                    <h3>Interoperability within Comdex Ecosystem</h3>
                                    <p>
                                        All dApps built on the Comdex chain are fully interoperable across the IBC and composable to enhance DeFi user experience and deliver seamless access to the ecosystem of products.
                                    </p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="lookingfor-section">
                <img className="line-left" src={LineLeft} alt='' />
                <img className="blue-left" src={BlueLeft} alt='' />
                <Container>
                    <Row>
                        <Col sm='8' className='text-center mx-auto'>
                            <div className="headings">
                                <h2>What we are looking for? </h2>
                            </div>
                            <p>
                                We are all ears for developers, curious techies, community managers, and blockchain-crypto enthusiasts for their ideas and proposals.
                            </p>
                        </Col>
                    </Row>
                    <Row className='lookingfor-row'>
                        <Col lg='6' className='text-col'>
                            <h2>CMST Stablecoin use cases</h2>
                            <p>
                                Be a part of a vibrant DeFi ecosystem, powered by the Composite (CMST) stablecoin. Build DEXes, Money Markets, Asset Management tools, Derivatives, Payments and much more!
                            </p>
                        </Col>
                        <Col lg='6' className='image-col image-col1'>
                            <img className="cube-1" src={Cube1} alt='' />
                            <img className="cube-2" src={Cube2} alt='' />
                            <img className="cube-3" src={Cube3} alt='' />
                            <img className="cube-4" src={Cube4} alt='' />
                            <img className="main-img" src={stablecoinIcon} alt='' />
                        </Col>
                    </Row>
                    <Row className='lookingfor-row'>
                        <Col lg='6' className='image-col image-col2'>
                            <img className="cube-1" src={Cube1} alt='' />
                            <img className="cube-2" src={Cube2} alt='' />
                            <img className="cube-3" src={Cube3} alt='' />
                            <img className="cube-4" src={Cube4} alt='' />
                            <img className="main-img" src={bridgingIcon} alt='' />
                        </Col>
                        <Col lg='6' className='text-col'>
                            <h2>DeFi and CeFi Bridging</h2>
                            <p>
                                Use Comdex's chain infrastructure to build interoperable solutions that help integrate CeFi use cases and RWAs with DeFi primitives for global accessibility.
                            </p>
                        </Col>
                    </Row>
                    <Row className='lookingfor-row'>
                        <Col lg='6' className='text-col'>
                            <h2>Infrastructure & Tooling</h2>
                            <p>
                                Contribute to Comdex's ecosystem by critical building infrastructure and tooling.
                            </p>
                        </Col>
                        <Col lg='6' className='image-col image-col3'>
                            <img className="cube-1" src={Cube1} alt='' />
                            <img className="cube-2" src={Cube2} alt='' />
                            <img className="cube-3" src={Cube3} alt='' />
                            <img className="cube-4" src={Cube4} alt='' />
                            <img className="main-img" src={infrastructureIcon} alt='' />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="process-section">
                <img className="blue-right" src={BlueRight} alt='' />
                <Container>
                    <Row>
                        <Col sm='6' className='text-center mx-auto'>
                            <h1>The Process</h1>
                            <p>
                                Anyone with a novel idea can apply for the Comdex Community Dev Fund in 3 easy steps.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <div className="process-row">
                    <Divider />
                    <Container>
                        <ul>
                            <li>
                                <div className="numbers">
                                    01
                                </div>
                                <h3>Forum <br /> Discussion</h3>
                            </li>
                            <li>
                                <div className="numbers">
                                    02
                                </div>
                                <h3>On Chain <br /> Proposal</h3>
                            </li>
                            <li>
                                <div className="numbers">
                                    03
                                </div>
                                <h3>Milestone based vesting of tokens </h3>
                            </li>
                        </ul>
                        <div className="text-center">
                            <Button type="primary" className="px-5" href="https://forum.comdex.one/t/how-to-apply-for-the-comdex-dev-fund/792 " target="_blank">Apply</Button>
                        </div>
                    </Container>
                </div>
            </section>
            <section className="explore-section-alt eligible-projects">
                <img className="blue-left" src={BlueLeft} alt='' />
                <img className="blue-line-left" src={LineLeft} alt='' />
                <Container>
                    <Row>
                        <Col sm='12' className='text-center'>
                            <h1>Eligible Projects</h1>
                            <p>
                                DeFi dApps and integrations, developer tooling, and anything that supports expanding the ecosystem.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm='12'>
                            <ul className="list">
                                <li>
                                    <ExploreCard
                                        name='White Whale'
                                        descriptions='White Whale is an Interchain Liquidity Protocol. Through its interconnected Liquidity Hubs on every major Cosmos SDK Chain it aims to solve liquidity fragmentation and market inefficiencies between chains.'
                                        iconName={WhiteWhaleIcon}
                                        live
                                        type='dApp'
                                    />
                                </li>
                                <li>
                                    <ExploreCard
                                        name='Syndicate'
                                        descriptions='A solutions provider Maximizing liquidity, governance and value for Comdex Applications.'
                                        iconName={SyndicateIcon}
                                        commingsoon
                                        type='dApp'
                                    />
                                </li>
                                <li>
                                    <ExploreCard
                                        name='Skip'
                                        descriptions='Skip builds sovereign MEV solutions for app-chains to increase resistance and recapture MEV rewards for network participants.'
                                        iconName={SkipIcon}
                                        commingsoon
                                        type='dApp'
                                    />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.com/invite/7vjPvWKKMT' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CommunityDevFund;