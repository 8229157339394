import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const CreateRequest = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'CREATE YOUR OWN COMDEX MODULE',
                                        href: 'dev-lessons-advance',
                                    },
                                    {
                                        title: 'CREATE REQUEST',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Create a Loan Request</h1>
                                <p>
                                    The Request keeper method that will be called whenever a user requests a loan. Request creates a new loan with the provided data, sends the collateral from the borrower's account to a module account, and saves the loan to the blockchain's store.
                                </p>
                                <CodeSnippet
codeData={`func (k Keeper) Request(ctx sdk.Context, appID, pairID uint64, 
    owner string, amtIn, amtOut, fee sdk.Int, duration int64) error {    
        loanID := k.GetLoanID(ctx)    
        loan := types.Loan{        
            Id:           loanID + 1,        
            AppId:        appID,        
            PairID:       pairID,        
            Owner:        owner,        
            AmountIn:     amtIn,        
            AmountOut:    amtOut,        
            Fee:          fee,        
            CreatedAt:    ctx.BlockTime(),        
            DurationDays: duration,        
            Lender:       "",        
            State:        "requested",    
    }
            
    addr, err := sdk.AccAddressFromBech32(owner)    
    if err != nil {        
        return err    
    }    
    pair, found := k.Asset.GetPair(ctx, pairID)    
    if !found {
        return assettypes.ErrorPairDoesNotExist    
    }    
    assetIn, found := k.Asset.GetAsset(ctx, pair.AssetIn)    
    if !found {        
        return assettypes.ErrorAssetDoesNotExist    
    }
    
        err = k.bankKeeper.SendCoinsFromAccountToModule(ctx, addr, 
    types.ModuleName, sdk.NewCoins(sdk.NewCoin(assetIn.Denom, amtIn.Add(fee))))    
        if err != nil {        
        return err    
    }    
        k.SetLoanID(ctx, loan.Id)    
        k.SetLoan(ctx, loan)    
        return nil
    }`}
/>

                                <div className="learn-module">

                                    <h3>Basic message validation</h3>

                                    <p>
                                        When a loan is created, a certain message input validation is required. You want to throw error messages in case the end user tries impossible inputs.
                                    </p>
                                    <CodeSnippet
codeData={`func (msg *MsgCreateRequest) ValidateBasic() error {    
    _, err := sdk.AccAddressFromBech32(msg.GetFrom())    
        if err != nil {        
        return err    
        }    
        if msg.AppId == 0 {        
        return fmt.Errorf("app id should not be 0: %d ", msg.AppId)    
        }    
        if msg.AmountIn.IsNegative() || msg.AmountIn.IsZero() {        
        return fmt.Errorf("invalid coin amount: %s < 0", msg.AmountIn)    
        }    
        if msg.AmountOut.IsNegative() || msg.AmountOut.IsZero() {        
        return fmt.Errorf("invalid coin amount: %s < 0", msg.AmountOut)    
        }    
        if msg.PairId == 0 {        
            return fmt.Errorf("pair id should not be 0: %d ", msg.PairId)    
        }
    
    return nil
    }`}
/>

                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/approve-request'><b>Approve Request.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CreateRequest;