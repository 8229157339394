import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const CosmosModuleTwoThree = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'WHAT IS COSMOS',
                                    href: '',
                                },
                                {
                                    title: 'MODULE 2',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Writing smart contracts using Cosmos SDK</h1>
                                <p>
                                    Smart contracts are self-executing contracts with the terms of the agreement between buyer and seller directly written into lines of code. In blockchain-based systems, smart contracts are used to automate the execution of agreements between parties. Cosmos SDK provides support for writing and deploying smart contracts on its blockchain network.
                                </p>

                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                
                                <div className="learn-module">
                                    <h1>What will you learn in Module 2.3</h1>
                                    <div className="learn-m-card">
                                        <ul>
                                            <li>
                                                Overview of smart contract development with Cosmos SDK
                                            </li>
                                            <li>
                                                Understanding the different types of smart contracts supported by the SDK
                                            </li>
                                            <li>
                                               Creating a new smart contract using the SDK's built-in tools
                                            </li>
                                            <li>
                                                Testing the smart contract using the SDK's built-in tools
                                            </li>
                                            <li>
                                                Best practices for designing and implementing smart contracts in a Cosmos application
                                            </li>
                                        </ul>
                                    </div>

                                    <h3>Understanding the different types of smart contracts supported by the SDK :</h3>
                                    <p>
                                        The Cosmos SDK supports two types of smart contracts: 
                                    </p>
                                    
                                    <p>
                                        <div className="point-list mb-1">Cosmos SDK Native contracts:</div> 
                                        <div className="pl-2">
                                            These are smart contracts written in the Go programming language, which is used for developing the SDK. These contracts have access to the SDK's modules and can be used to interact with other smart contracts on the network.
                                        </div>
                                    </p>
                                    <p>
                                        <div className="point-list mb-1"> WebAssembly (Wasm) contracts: </div>
                                        <div className="pl-2">
                                            These are smart contracts written in the WebAssembly programming language, which can be compiled to run on the SDK's blockchain network. Wasm contracts have a higher level of security as they run in a sandboxed environment.
                                        </div>
                                    </p>

                                    <h3>Creating a new smart contract using the SDK's built-in tools:</h3>
                                    <p>
                                        To create a new smart contract, you will need to use the Cosmos SDK's built-in smart contract module. This module provides a framework for writing and deploying smart contracts on the blockchain network. <br />
                                        Testing the smart contract using the SDK's built-in tools: <br />
                                        The Cosmos SDK provides a suite of built-in tools for testing smart contracts. These tools allow developers to test the functionality of their smart contracts in a sandboxed environment before deploying them to the blockchain network.
                                    </p>

                                    <h3>Best practices for designing and implementing smart contracts in a Cosmos application:</h3>
                                    <ul className="list-2 pl-0">
                                        <li>Use secure coding practices to avoid vulnerabilities in the smart contract code.</li>
                                        <li>Test the smart contract thoroughly before deploying it to the network.</li>
                                        <li>Follow best practices for smart contract deployment, including proper access control, auditing, and monitoring.</li>
                                        <li>Use event-driven programming to create smart contracts that can respond to external events and interact with other contracts on the network.</li>
                                        <li>Document the smart contract code and provide clear instructions for its use.</li>
                                    </ul>

                                    <p>
                                        Rust is a systems programming language that is gaining popularity in blockchain development due to its safety, performance, and expressiveness. Cosmos SDK provides support for Rust-based smart contract development through the CosmWasm module.
                                    </p>
                                    <p>
                                        CosmWasm is a Rust-based smart contract runtime that provides a secure and efficient way to execute smart contracts on the Cosmos network. It allows developers to write smart contracts in Rust and compile them to a WebAssembly binary that can be executed within the Cosmos SDK.
                                    </p>

                                    <pre className="code-div">
                                        {`use cosmwasm_std::{
    entry_point, 
    Deps, 
    DepsMut, 
    Env, 
    MessageInfo, 
    Response, 
    StdError, 
    StdResult
};

// define a custom struct to store state
#[derive(Serialize, Deserialize)]
struct MyState {
    count: i32,
}

// define a custom message type
#[derive(Serialize, Deserialize)]
struct MyMessage {
    value: i32,
}

// define the contract API
#[entry_point]
fn execute(
    deps: DepsMut, 
    env: Env, 
    info: MessageInfo, 
    msg: MyMessage,
) -> StdResult<Response> {
    // load the contract state
    let mut state = get_state(deps.storage)?;

    // perform some validation
    if msg.value <= 0 {
        return Err(StdError::generic_err("Value must be greater than zero"));
    }

    // update the contract state
    state.count += msg.value;
    set_state(deps.storage, &state)?;

    // create a response
    let res = Response::new()
        .add_attribute("action", "increment")
        .add_attribute("value", msg.value.to_string())
        .add_attribute("total", state.count.to_string());

    Ok(res)
}

// helper function to load the contract state
fn get_state(storage: &dyn Storage) -> StdResult<MyState> {
    let state = match storage.get(CONFIG_KEY) {
        Some(data) => from_slice(&data)?,
        None => MyState { count: 0 },
    };
    Ok(state)
}

// helper function to update the contract state
fn set_state(storage: &mut dyn Storage, state: &MyState) -> StdResult<()> {
    storage.set(CONFIG_KEY, &to_vec(state)?);
    Ok(())
}

// define the storage key for the contract state
const CONFIG_KEY: &[u8] = b"config";
`}
                                    </pre>

                                    <p>In this example, we define a custom struct to store the contract state and a custom message type to represent the input to the contract. We then define the contract API using the entry_point macro, which takes in the contract input and returns a Response object. The API function loads the contract state, performs some validation, updates the state, and creates a response.</p>

                                    <p>
                                        To compile this smart contract to WebAssembly, we can use the cosmwasm-cli tool provided by CosmWasm. Here's an example command to compile the above contract:
                                    </p>

                                    <pre className="code-div">
                                        {`cosmwasm-cli compile my_contract.rs --release`}
                                    </pre>

                                    <p>
                                        This will generate a my_contract.wasm file that can be uploaded to the Cosmos network and executed as a smart contract.
                                    </p>

                                    <p>
                                        Overall, writing smart contracts in Rust using the CosmWasm module provides a secure and efficient way to execute custom logic on the Cosmos network. Rust's safety and performance features make it an ideal choice for building high-quality blockchain applications.
                                    </p>
                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with the first section <Link to='/'><b>Writing smart contracts using Cosmos SDK.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmosModuleTwoThree;