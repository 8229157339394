import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const PointersAndStruct = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'DEV LESSONS BASICS',
                                    href: 'dev-lessons-basics',
                                },
                                {
                                    title: 'Pointers and Struct',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Pointers and Struct</h1>
                                <p>
                                    In this lesson, we will cover pointers and structs in Go, including declaring and using pointers, creating and using structs, and understanding memory allocation and garbage collection in Go.
                                </p>

                                <div className="learn-module">
                                    <h1>Lesson Objectives:</h1>
                                    <div className="learn-m-card">
                                        <p>By the end of this lesson, you should be able to:</p>
                                        <ul>
                                            <li>
                                                Understand how to declare and use pointers in Go.
                                            </li>
                                            <li>
                                                Create and use structs in Go.
                                            </li>
                                            <li>
                                               Understand memory allocation and garbage collection in Go.
                                            </li>
                                        </ul>
                                    </div>

                                    <h3>Declaring and Using Pointers in Go</h3>

                                    <p>
                                        In Go, a pointer is a variable that stores the memory address of another variable. Pointers are used to indirectly access and manipulate the data stored in memory. In this section, we'll cover how to declare and use pointers in Go.
                                    </p>

                                    <h3>Declaring Pointers</h3>
                                    <p>
                                        To declare a pointer in Go, you use the * symbol before the type of the variable. Here's an example of how to declare a pointer to an int variable:
                                    </p>
                                    <CodeSnippet
codeData={`var myInt int = 42
var myIntPointer *int = &myInt`}
/>
                                    
                                    <p>
                                        In this example, we first declare a variable called myInt with a value of 42. We then declare a pointer called myIntPointer that points to the memory address of myInt using the & operator. It's worth noting that the zero value of a pointer is nil, which means it doesn't point to any valid memory location.
                                    </p>
                                    
                                    <h3>Accessing Pointers</h3>
                                    <p>
                                        To access the value of a variable through a pointer in Go, you use the * operator before the pointer variable. Here's an example of how to access the value of myInt through the myIntPointer pointer:
                                    </p>
                                    <CodeSnippet
codeData={`var myInt int = 42
var myIntPointer *int = &myInt

fmt.Println(*myIntPointer) // Output: 42`}
/>

                                    <p>In this example, we use the * operator to access the value stored at the memory location pointed to by myIntPointer. The output will be 42, which is the value of myInt</p>

                                    <h3>Modifying Pointers</h3>
                                    <p>In Go, you can also use pointers to modify the value of a variable indirectly. Here's an example of how to modify the value of myInt using the myIntPointer pointer:</p>
                                    <CodeSnippet
codeData={`var myInt int = 42
var myIntPointer *int = &myInt

*myIntPointer = 99

fmt.Println(myInt) // Output: 99`}
/>

                                    <p>In this example, we use the * operator to access the value stored at the memory location pointed to by myIntPointer. We then assign a new value of 99 to that memory location. As a result, the value of myInt is updated to 99.</p>

                                    <h3>Passing Pointers to Functions</h3>

                                    <p>In Go, you can pass pointers to functions as arguments. This allows the function to directly access and modify the data stored in memory. Here's an example of how to pass a pointer to myInt to a function called double</p>
                                    <CodeSnippet
codeData={`func double(x *int) {
    *x *= 2
    }
    
    var myInt int = 42
    var myIntPointer *int = &myInt
    
    double(myIntPointer)fmt.Println
    
    (myInt) // Output: 84`}
/>

                                    <p>In this example, we define a function called double that takes a pointer to an int as its argument. The function multiplies the value stored at the memory location pointed to by the pointer by 2.
We then declare myInt and myIntPointer as before, and pass myIntPointer to the double function. The output will be 84, which is the value of myInt after it has been doubled by the double function.</p>

                                    <h3>Conclusion</h3>
                                    <p>In this lesson, we covered how to declare and use pointers in Go. By understanding how to work with pointers, you'll be able to write more powerful and efficient code in Go.</p>

                                    <h3>Creating and Using Structs in Go</h3>
                                    <p>A struct is a user-defined type that allows you to group together values of different types under a single name. Structs in Go are similar to classes in object-oriented programming and are used to create complex data structures.</p>

                                    <h3>Declaring Structs</h3>
                                    <p>To declare a struct in Go, you use the type keyword followed by the name of the struct and its field definitions. Here's an example of a struct declaration:</p>
                                    <CodeSnippet
codeData={`type Person struct {    
    name string    
    age  int
    }`}
/>

                                    <p>In this example, we declare a struct called Person with two fields: name of type string and</p>

                                    <h3>Creating Structs</h3>
                                    <p>To create a new struct variable, you use the Person type that we declared earlier and specify the values of its fields. Here's an example of creating a Person struct:</p>
                                    <CodeSnippet
codeData={`p := Person{name: "John", age: 25}`}
/>

                                    <p>In this example, we create a new Person struct variable called p and initialize its name field to "John" and its age field to 25.</p>
                                    <p>You can also create a struct variable without initializing any fields. In this case, Go initializes all the fields of the struct to their zero values. Here's an example:</p>
                                    <CodeSnippet
codeData={`var p Person`}
/>

                                    <p>In this example, we create a new Person struct variable called p and all of its fields are initialized to their zero values: "" for name and 0 for age.</p>

                                    <h3>Accessing Struct Fields</h3>
                                    <p>To access the fields of a struct, you use the dot (.) operator followed by the name of the field. Here's an example of accessing the fields of the Person struct:</p>
                                    <CodeSnippet
codeData={`fmt.Println(p.name) // Output: John
fmt.Println(p.age)  // Output: 25`}
/>
                                   
                                    <p>In this example, we use the dot operator to access the name and age fields of the Person struct.</p>

                                    <h3>Modifying Struct Fields</h3>
                                    <p>To modify the fields of a struct, you use the dot operator followed by the name of the field and assign a new value to it. Here's an example of modifying the age field of the Person struct:</p>
                                    <CodeSnippet
codeData={`p.age = 30
fmt.Println(p.age) // Output: 30`}
/>

                                    <p>In this example, we use the dot operator to access the age field of the Person struct and set it to 30</p>
                                    
                                    <h3>Struct Embedding</h3>
                                    <p>Struct embedding is a way to compose structs in Go. It allows you to define a new struct that contains all the fields of an existing struct, as well as additional fields. Here's an example of using struct embedding</p>
                                    <CodeSnippet
codeData={`type Employee struct {    
    Person    
    salary int
}`}
/>
                                    <p>In this example, we define a new struct called Employee that embeds the Person struct we defined earlier, along with an additional salary field. This means that an Employee struct will have access to all the fields and methods of the Person struct.</p>

                                    <h3>Struct Methods</h3>
                                    <p>Struct methods in Go are functions that are associated with a particular struct type. They can be used to manipulate the data stored within the struct or perform actions based on the data stored within the struct.</p>

                                    <h3>Defining Struct Methods</h3>
                                    <p>To define a method on a struct in Go, you need to declare a function with a special receiver parameter. The receiver parameter is a reference to the instance of the struct that the method is being called on. The receiver parameter is defined using the following syntax:</p>
                                    <CodeSnippet
codeData={`func (receiver_name receiver_type) function_name(parameters) 
return_type {    
    // function body.
}`}
/>

                                    <p>
                                        Here, receiver_name is the name of the receiver parameter and receiver_type is the type of the struct that the method is associated with. The function_name, parameters, and return_type are the same as for a regular function.
                                    </p>
                                    <p>Here is an example of a struct method defined on a Person struct:</p>
                                    <CodeSnippet
codeData={`type Person struct {    
    name string    
    age  int
    }
    
    func (p Person) SayHello() {    
        fmt.Printf("Hello, my name is %s and I'm %d years old.\n", p.name, p.age)
}`}
/>

                                    <p>In this example, we define a method called SayHello() on the Person struct. The receiver parameter is named p and has the type Person. The method simply prints out a greeting that includes the name and age of the person.</p>

                                    <h3>Calling Struct Methods</h3>
                                    <p>To call a method on a struct in Go, you use the dot notation with the name of the method after the name of the struct variable. Here's an example of calling the SayHello() method on a Person struct:</p>
                                    <CodeSnippet
codeData={`p := Person{name: "John", age: 25}
p.SayHello() // Output: Hello, my name is John and I'm 25 years old.`}
/>
                                    
                                    <p>In this example, we create a new Person struct variable called p and initialize its name field to "John" and its age field to 25. We then call the SayHello() method on the p variable using the dot notation.</p>

                                    <h3>Modifying Struct Data with Methods</h3>
                                    <p>Struct methods in Go can also be used to modify the data stored within the struct. To do this, you can pass the struct as a pointer to the method instead of as a value. Here's an example of a method that modifies the data within a Person struct:</p>

                                    <CodeSnippet
codeData={`func (p *Person) UpdateAge(newAge int) {    
    p.age = newAge
}`}
/>

                                    <p>In this example, we define a method called UpdateAge() on the Person struct. The receiver parameter is named p and has the type *Person, which is a pointer to a Person struct. The method takes an int parameter called newAge and sets the age field of the Person struct to this value. <br />
Here's an example of calling the UpdateAge() method to modify the age field of a Person struct:</p>

                                    <CodeSnippet
codeData={`p := Person{name: "John", age: 25}
p.UpdateAge(30)
fmt.Println(p.age) // Output: 30`}
/>

                                    <p>
                                        In this example, we create a new Person struct variable called p and initialize its name field to "John" and its age field to 25. We then call the UpdateAge() method on the p variable using the dot notation and passing 30 as the argument. Finally, we print out the age field of the p variable and see that it has been updated.
                                    </p>

                                    <h3>Conclusion</h3>
                                    <p>
                                        In conclusion, struct methods in Go are a powerful tool for manipulating data stored within a struct. They can be used to perform actions on the data or modify the data itself. Struct methods are defined using a special receiver parameter that refers to the struct instance on which the method is being called. To call a method on a struct, you use the dot notation with the name of the method after the name of the struct variable. Struct methods can be defined on any type of struct in Go, making them a versatile tool for struct manipulation.
                                    </p>
                                    <p>
                                        Memory allocation and garbage collection are critical aspects of any programming language, and Go is no exception. Go provides a robust memory management system that allows developers to allocate and deallocate memory dynamically without having to worry about manual memory management.
                                    </p>
                                    <h3>Memory Allocation in Go</h3>
                                    <p>
                                        Memory allocation in Go is done through a process called new() or make(). Both of these functions allocate memory, but they have different use cases.
                                    </p>
                                    <p>
                                        The new() function is used to allocate memory for a new value of a specified type and return a pointer to that value. Here is an example of using the new() function:
                                    </p>
                                    <CodeSnippet
codeData={`p := new(int)
*p = 42
fmt.Println(*p) // Output: 42`}
/>

                                    <p>
                                        In this example, we allocate memory for an int using the new() function, and assign the value 42 to the memory location pointed to by the p pointer. We then print out the value of the int pointed to by p, which is 42.
                                    </p>
                                    <p>
                                        The make() function is used to allocate memory for a specific type of slice, map, or channel, and initialize it with a specified capacity. Here is an example of using the make() function:
                                    </p>
                                    <CodeSnippet
codeData={`s := make([]int, 0, 5)
s = append(s, 1, 2, 3, 4, 5)
fmt.Println(s) // Output: [1 2 3 4 5]`}
/>

                                    <p>
                                        In this example, we allocate memory for a slice of ints with a capacity of 5 using the make() function. We then append the values 1 through 5 to the slice, and print out the contents of the slice.
                                    </p>

                                    <h3>Garbage Collection in Go</h3>
                                    <p>
                                        Go's garbage collector is responsible for reclaiming memory that is no longer being used by a Go program. The garbage collector periodically scans the program's memory heap to find memory blocks that are no longer being used, and frees up the memory used by those blocks.
                                    </p>
                                    <p>
                                        Go's garbage collector uses a concurrent, tri-color, mark-and-sweep algorithm to determine which memory blocks are still in use and which are not. When the garbage collector starts, it assumes that all memory blocks are "white" (i.e., not in use). It then traverses the program's object graph, starting from the roots (global variables, stack frames, and registers), and marks all reachable memory blocks as "gray". It then recursively traverses the object graph, marking all reachable memory blocks as "gray" and pushing them onto a "gray stack". Once all reachable memory blocks have been marked as "gray", the garbage collector can safely reclaim any memory blocks that are still "white".
                                    </p>
                                    <p>
                                        Go's garbage collector is designed to minimize pause times and memory overhead. To achieve this, it uses a parallel, concurrent approach that allows garbage collection to occur concurrently with program execution. This helps to minimize pauses in program execution, since the garbage collector can run in the background while the program continues to execute. Additionally, the garbage collector is designed to minimize the memory overhead associated with garbage collection, by using a compacting algorithm that moves objects around in memory to minimize fragmentation and free up large contiguous blocks of memory.
                                    </p>
                                    <p>
                                        In summary, Go's memory allocation and garbage collection system is designed to be efficient, scalable, and easy to use. By using new() and make() to allocate memory, and following best practices for memory management, Go programs can achieve high levels of performance and scalability while avoiding the pitfalls of manual memory management.
                                    </p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/error-handling-ingo'><b>Error Handling in Go.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default PointersAndStruct;