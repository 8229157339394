import React from "react";
import { Button } from "antd";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import YouTubePlaylist from "@codesweetly/react-youtube-playlist";
import "./index.less";

import Eventbg1 from '../../assets/images/event-bg1.png';
import TopBlueBg from '../../assets/images/blue-texture-left.png';
import RedRightBg from '../../assets/images/red-texture-right2.png';
import BlueLeftBg from '../../assets/images/blue-texture-left2.png';
import LineLeft from '../../assets/images/line-bg-left.png';
import BlueRightBg from '../../assets/images/blue-texture-right.png';
import LineRight from '../../assets/images/line-bg-right.png';

const EducationVideos = () => {
    return (
        <div className="homepage-wrapper educationvideos-wrapper">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col sm='7'>
                            <h1>Education <br /> Videos</h1>
                            <p>
                                Unlocking knowledge through visual learning.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <img className="blogbanner-bg" src={Eventbg1} alt={Eventbg1} />
            </section>
            <section className="bloglist-section">
                <img className="red-right" src={RedRightBg} alt='' />
                <img className="left-line" src={LineLeft} alt='' />
                <img className="left-bg" src={BlueLeftBg} alt='' />
                <img className="left-line2" src={LineLeft} alt='' />
                <img className="left-bg2" src={BlueLeftBg} alt='' />
                <img className="right-line2" src={LineRight} alt='' />
                <img className="right-bg2" src={BlueRightBg} alt='' />
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>GoLang Unleashed: Empowering Developers with Go</h2>
                                <p className="mb-0">
                                    In this comprehensive series, we dive deep into the core concepts of GoLang, accompanied by practical examples and step-by-step tutorials. Whether you're a beginner or an experienced programmer, our videos are tailored to meet your learning needs and take you on a journey of mastery. Unlock the secrets of efficient concurrency, explore powerful built-in libraries, and discover best practices for structuring clean and maintainable Go code. With each video, you'll gain practical insights and hands-on experience that will accelerate your GoLang development journey on the Comdex chain.
                                </p>
                            </div>
                            <YouTubePlaylist
                                apiKey="AIzaSyCHJ1y8YwKsSkxuuWoEMlATBHWW9PrvWmY"
                                playlistId="PLRYvCwQTva8Nl414rC5iaCONpWRCf-Rbe"
                                uniqueName="Comdex"
                                description
                            />
                            {/* <div className="text-center mt-5">
                                <Button type="secondary">View All</Button>
                            </div> */}
                        </Col>
                    </Row>
                    <Row className='mt-5 pt-4'>
                        <Col>
                            <div>
                                <h2>Mastering CosmWasm: Building Next-Generation dApps</h2>
                                <p className="mb-0">
                                    This video series is designed specifically for developers and enthusiasts interested in learning CosmWasm development. This captivating series offers a comprehensive exploration of this groundbreaking technology accompanied by step-by-step tutorials. Each video delves into essential concepts and best practices, empowering you to leverage the capabilities of CosmWasm effectively.  Whether you're a seasoned developer or a newcomer to blockchain development, this series provides the knowledge and skills you need to create powerful decentralized applications using Cosmwasm on the Comdex chain.
                                </p>
                            </div>
                            <YouTubePlaylist
                                apiKey="AIzaSyCHJ1y8YwKsSkxuuWoEMlATBHWW9PrvWmY"
                                playlistId="PLRYvCwQTva8Pic4g9FYLxMjwmL5GVIq0L"
                                uniqueName="Comdex"
                                description
                            />
                            {/* <div className="text-center mt-5">
                                <Button type="secondary">View All</Button>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.com/invite/7vjPvWKKMT' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default EducationVideos;