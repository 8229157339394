import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const UnderstandingIgniteModuleCreation = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'understanding cosmos sdk architecture',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Understanding Cosmos SDK Architecture</h1>
                                <p>
                                    To use Ignite to create a module in Cosmos SDK, you can follow these steps:
                                </p>
                                <ol type="1">
                                    <li>
                                        Install Ignite.
                                    </li>
                                    <li>
                                        Create a new project.
                                    </li>
                                    <li>
                                        Scaffold a module.
                                    </li>
                                    <li>
                                        Implement the module's logic.
                                    </li>
                                    <li>
                                        Test the module.
                                    </li>
                                    <li>
                                        Build and deploy the module.
                                    </li>
                                </ol>
                                <p>
                                    Here are some more details about each step:
                                </p>
                                <div className="pt-3">
                                    <h3>1. Install Ignite.</h3>
                                    <p>
                                        To install Ignite, you can use the following command:
                                    </p>
                                    <CodeSnippet
codeData={`go get github.com/ignite/ignite`}
/>
                                    <p>This will download the Ignite binary and install it in your $GOPATH.</p>
                                </div>
                                <div className="pt-3">
                                    <h3>2. Create a new project</h3>
                                    <p>
                                       Once you have installed Ignite, you can create a new project using the following command:
                                    </p>
                                    <CodeSnippet
codeData={`ignite scaffold chain my-project`}
/>
                                    <p>This will create a new directory called my-project with the following files:</p>
                                    <ul className="list-2">
                                        <li>go.mod - The Go module file.</li>
                                        <li>main.go - The main application file.</li>
                                        <li>config.yaml - The project configuration file.</li>
                                    </ul>
                                    <p>The go.mod file specifies the dependencies for your project. The main.go file is the main application file. The config.yaml file is the project configuration file.</p>
                                </div>
                                <div className="pt-3">
                                    <h3>3. Scaffold a module</h3>
                                    <p>
                                       To scaffold a module, you can use the following command:
                                    </p>
                                    <CodeSnippet
codeData={`ignite scaffold module my-module`}
/>
                                    
                                    <p>This will create a new directory called my-module with the following files:</p>
                                    <ul className="list-2">
                                        <li>module.go - The module definition file.</li>
                                        <li>types.go - The module's types file.</li>
                                        <li>messages.go - The module's messages file.</li>
                                        <li>queries.go - The module's queries file.</li>
                                        <li>services.go - The module's services file.</li>
                                    </ul>
                                    <p>
                                        The module.go file defines the module's name, the module's types, and the module's messages. The types.go file defines the module's types. The messages.go file defines the module's messages. The queries.go file defines the module's queries. The services.go file defines the module's services.
                                    </p>
                                </div>
                                <div className="pt-3">
                                    <h3>4. Implement the module's logic</h3>
                                    <p>
                                       Once you have scaffolded the module, you can implement the module's logic by editing the files in the my-module directory.
                                    </p>
                                    <p>
                                        The module's logic is implemented in the services.go file. The services.go file defines the module's services. A service is a function that performs an operation on the module's data.
                                    </p>
                                    <p>
                                        For example, the following code defines a service that gets the balance of an account:
                                    </p>
                                    <CodeSnippet
codeData={`func (s *MyModuleService) GetBalance(ctx context.Context, req *types.GetBalanceRequest) (*types.GetBalanceResponse, error) { 
    
    // Get the account from the state. account := state.GetAccount(ctx, req.Address)
    
    // Get the balance from the account. balance := account.GetBalance()

    // Return the balance. return &types.GetBalanceResponse{ Balance: balance, }, nil } 
    `}
/>

                                </div>

                                <div className="pt-3">
                                    <h3>5. Test the module</h3>
                                    <p>Once you have implemented the module's logic, you can test the module using the following command:</p>
                                    <CodeSnippet
codeData={`ignite test my-module`}
/>
                                    
                                    <p>This will run the module's unit tests.</p>
                                    <p>The unit tests are located in the my-module directory. The unit tests test the module's logic.</p>
                                </div>

                                <div className="pt-3">
                                    <h3>6. Build and deploy the module</h3>
                                    <p>Once you have tested the module, you can build and deploy the module using the following command:</p>
                                    <CodeSnippet
codeData={`ignite build my-module`}
/>
                                    
                                    <p>This will build the module and create a binary file called my-module.</p>
                                    <p>You can then deploy the module to a Cosmos SDK node using the following command:</p>
                                    <CodeSnippet
codeData={`cosmos-sdk tx staking stake \

--from <your-address> \

--amount 1000000stake \

--chain-id <your-chain-id> \

--gas 100000 \

--module my-module \

--yes`}
/>
                                    
                                    <p>Use code with caution. <a href="https://bard.google.com/faq#coding" target="_blank" rel="noreferrer noopener">Learn more</a></p>
                                    <p>content_copy</p>
                                    <p>Once you have deployed the module, you can interact with it using the Cosmos SDK CLI.</p>
                                    <p>For example, the following command will get the balance of an account:</p>
                                    <CodeSnippet
codeData={`cosmos-sdk query my-module balance <account-address>`}
/>
                                    <p>This will return the balance of the account.</p>
                                </div>

                                <div className="nextup-bottom">
                                    <h2>Next Up</h2>
                                    <p className="bottom-link">
                                        Dive right into Cosmos with <Link to='/state-management'><b>State Management with Cosmos SDK.</b></Link>
                                    </p>
                                </div>
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default UnderstandingIgniteModuleCreation;