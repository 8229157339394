import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import ArticleCard from "../../../components/common/ArticleCard";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import "./index.less";

import Eventbg1 from '../../../assets/images/event-bg1.png';
import BlogBanner from '../../../assets/images/blog-img.png';
import blogDetails1 from '../../../assets/images/blog-details1.png';
import blogDetails2 from '../../../assets/images/blog-details2.png';
import articleImg1 from '../../../assets/images/recent-articles-1.jpg';
import articleImg2 from '../../../assets/images/recent-articles-2.jpg';
import articleImg3 from '../../../assets/images/recent-articles-3.jpg';
import TopBlueBg from '../../../assets/images/blue-texture-left.png';
import RedRightBg from '../../../assets/images/red-texture-right2.png';
import BlueLeftBg from '../../../assets/images/blue-texture-left2.png';
import LineLeft from '../../../assets/images/line-bg-left.png';
import BlueRightBg from '../../../assets/images/blue-texture-right.png';
import LineRight from '../../../assets/images/line-bg-right.png';

const BlogDetails = () => {
    return (
        <div className="homepage-wrapper eventslist-wrapper blog-details">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="text-center">
                        <Col sm='9' className='mx-auto'>
                            <h1>How $ATOM stakers can claim $HARBOR Airdrop?</h1>
                            <p>
                                Posted on June 12, 2023
                            </p>
                            <img className="blog-dtl-banner" src={BlogBanner} alt="Blog" />
                        </Col>
                    </Row>
                </Container>
                <img className="banner-bg" src={Eventbg1} alt={Eventbg1} />
            </section>
            <section className="blog-details-section">
                <img className="red-right" src={RedRightBg} alt='' />
                <img className="left-line" src={LineLeft} alt='' />
                <img className="left-bg" src={BlueLeftBg} alt='' />
                <img className="left-line2" src={LineLeft} alt='' />
                <img className="left-bg2" src={BlueLeftBg} alt='' />
                <img className="right-line2" src={LineRight} alt='' />
                <img className="right-bg2" src={BlueRightBg} alt='' />
                <Container>
                    <Row>
                        <Col sm='9' className='mx-auto'>
                            <p>
                                Cosmos $ATOM community has been airdropped ~47M $HARBOR tokens CLAIM NOW.
                            </p>
                            <p>
                                Harbor protocol is an Interchain stablecoin protocol on the Comdex chain (powered by the Cosmos SDK and CosmWasm smart contracts) that enables safe listed assets to be locked in Vaults and mint the $CMST stablecoin. $CMST is a stablecoin built to power DeFi in the Cosmos & beyond.
                            </p>
                            <p className="mb-5">
                                $HARBOR is the value accrual and governance token of the protocol, which plays a vital role in managing monetary policies and the risk of the protocol. <br />
                                $ATOM was chosen for the airdrop so $ATOM holders can participate in governance on Harbor to enable $ATOM as collateral and manage risk on the protocol.
                            </p>

                            <h3>Follow the steps below to claim the airdrop:</h3>

                            <p>Step 1: Visit the Harbor dApp LINK and Connect your wallet using Keplr/ Ledger.</p>

                            <img className="mt-4" src={blogDetails1} alt='Blog' />

                            <p>
                                Step 2: If you are eligible, $HARBOR tokens will show in YOUR AIRDROP DETAILS on the left. To claim your airdrop amount, please click on the complete mission button shown in the above image.
                            </p>

                            <p>
                                Step 3: Users can claim their amount once they complete the five tasks.
                            </p>

                            <img className="mt-4" src={blogDetails2} alt='Blog' />

                            <p>Visit the dApp Now and claim your airdrop before 26 Feb <Link to='/'><b>CLAIM NOW</b></Link>.</p>

                            <div className="text-center mt-5">
                                <Button type="secondary">Take me there</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="article-section recent-blog mt-5 pt-5">
                <Container>
                    <Row>
                        <Col>
                            <div className="headings text-left mb-0">
                                <h2 className="mb-2">Related Blogs</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="acticle-list">
                                <ArticleCard 
                                    articleImg={articleImg1}
                                    name='How to claim the $HARBOR airdrop'
                                    date='Dec 5, 2022'
                                    description='Comdex is airdropping 150Mn Harbor tokens, out of which 20% airdrop is in the form Liquid Harbor, and the rest 80% is in the...'
                                />
                                <ArticleCard 
                                    articleImg={articleImg2}
                                    name='Understanding Commodo — cPools, Liquidation, and much..'
                                    date='Dec 5, 2022'
                                    description='Commodo is an interchain liquidity protocol built on the Comdex chain to deliver decentralized, efficient & secure money markets....'
                                />
                                <ArticleCard 
                                    articleImg={articleImg3}
                                    name='How to use cSwap DEX [Walkthrough]'
                                    date='Dec 5, 2022'
                                    description='cSwap is an IBC-enabled hybrid dex built on the Comdex chain. cSwap uses AMM liquidity pools which bring cross-chain markets and limit ...'
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.com/invite/7vjPvWKKMT' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default BlogDetails;