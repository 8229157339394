import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../../components/common";
import LeftNav from "../../LeftNav";
import "../../index.less";

const PreRequisites = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'Getting started with CosmWasm',
                                        href: 'gettingstarted-with-cosmWasm',
                                    },
                                    {
                                        title: 'Pre-Requisites',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <div className="mb-5">
                                    <h1>Pre-Requisites</h1>
                                
                                    <div className="mb-4 pt-3">
                                        <h3>1. Go (Version - 1.19+)</h3>
                                        <p>Install Go <a href="https://go.dev/doc/install" target="_blank" rel="noreferrer noopener">1.19+</a> by following instructions there.</p>
                                        <p>Verify the installation by typing “go version” in your terminal.</p>
                                    </div>

                                    <div className="mb-4">
                                        <h3>2. Rust</h3>
                                        <p>- Use the below command to install Rustup</p>
                                        <CodeSnippet 
                                            codeData={`curl --proto '=https' --tlsv1.2 -sSf https://sh.rustup.rs | sh`}
                                        />
                                        <p>- Once installed , make sure you have wasm32 target</p>
                                        <CodeSnippet 
                                            codeData={`rustup default stable
cargo version
# If this is lower than 1.55.0+, update
rustup update stable

rustup target list --installed
rustup target add wasm32-unknown-unknown`}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <h3>3. Install Comdex Binary</h3>
                                        <p>- Clone git repository</p>
                                        <CodeSnippet 
                                            codeData={`$ git clone https://github.com/comdex-official/comdex.git`}
                                        />
                                        
                                        <p>- Checkout tag</p>
                                        <CodeSnippet 
                                            codeData={`$ cd comdex
$ git fetch --tags
$ git checkout <tag>`}
                                        />

                                        <p>- Install dependencies</p>
                                        <CodeSnippet 
                                            codeData={`$ go mod vendor
$ make install`}
                                        />
                                        <p>Comdex binary will be successfully installed.</p>
                                    </div>

                                    <div className="mb-4">
                                        <h3>4. Docker</h3>
                                        <p><a href="https://docs.docker.com/engine/install/" target="_blank" rel="noreferrer noopener">https://docs.docker.com/engine/install/</a></p>
                                    </div>
                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/cosmWasm-development'><b>Development.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default PreRequisites;