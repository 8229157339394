import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const ErrorHandlingInRust = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'DEV LESSONS BASICS(RUST)',
                                    href: 'devlessons-with-rust',
                                },
                                {
                                    title: 'ERROR HANDLING IN RUST',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Error Handling in Rust</h1>
                                <p>
                                    Rust's approach to error handling is a core feature of the language that distinguishes it from other programming languages. Error handling in Rust is based on the concept of result types, which allow functions to return either a value or an error. This approach provides a structured and safe way to handle errors in Rust programs
                                </p>

                                <div className="learn-module">

                                    <h3>Understanding Rust's error-handling approach</h3>

                                    <p>
                                        In Rust, errors are treated as values that can be propagated and handled in a structured way. When a function encounters an error, it can return a result type instead of the expected return value. A result type is an enum that has two variants: Ok, which contains the successful value, and Err, which contains the error value.
                                    </p>
                                    <CodeSnippet
codeData={`enum Result<T, E> {    
    Ok(T),    
    Err(E),
}`}
/> 
                                    <p>
                                        By returning a result type, a function clearly communicates that it can either return the expected value or an error. The calling code can then handle the error in a structured way, without the need for try-catch blocks or other similar constructs.
                                    </p>

                                    <h3>Using the Result type</h3>
                                    <p>
                                        The Result type is used extensively in Rust programs to handle errors. The standard library provides a Result type that is used by many Rust functions. Here's an example of a function that returns a Result type:
                                    </p>
                                    <CodeSnippet
codeData={`fn divide(x: i32, y: i32) -> Result<i32, &'static str> {    
    if y == 0 {        
        return Err("division by zero");    
    }    
    Ok(x / y)
}`}
/> 
                                    <p>
                                        The divide function takes two integers and returns a Result type. If the second argument is zero, the function returns an error with the message "division by zero". Otherwise, it returns the result of dividing the first argument by the second argument.
                                    </p>
                                    <p>The calling code can then handle the result of the function in a structured way, using the match statement:</p>
                                    <CodeSnippet
codeData={`let result = divide(10, 2);
match result {    
    Ok(value) => println!("Result: {}", value),    
    Err(error) => println!("Error: {}", error),
}`}
/> 

                                    <p>The match statement checks the result of the divide function and prints either the successful value or the error message.</p>

                                    <h3>Returning errors from functions and handling them</h3>
                                    <p>Functions can also return their own custom error types, which can provide more detailed information about the error. For example:</p>
                                    <CodeSnippet
codeData={`enum MyError {    
    InvalidArgument,    
    FileNotFound,    
    OtherError,
}

fn open_file(filename: &str) -> Result<(), MyError> {    
    if filename == "" {        
        return Err(MyError::InvalidArgument);    
    }    
    // open file here    
    Ok(())
}`}
/> 
                                    <p>
                                        The open_file function takes a filename and returns a Result type with a unit value () and a custom error type MyError. If the filename is empty, the function returns an error with the InvalidArgument variant. Otherwise, it opens the file and returns a successful result with the Ok variant and a unit value ().
                                    </p>
                                    <p>
                                        The calling code can then handle the error in a structured way, using the match statement:
                                    </p>
                                    <CodeSnippet
codeData={`let result = open_file("file.txt");
match result {    
    Ok(_) => println!("File opened successfully"),    
    Err(MyError::InvalidArgument) => println!("Invalid argument"),    
    Err(MyError::FileNotFound) => println!("File not found"),    
    Err(MyError::OtherError) => println!("Other error"),
}`}
/>
                                    <p>
                                        The match statement checks the result of the open_file function and prints a message depending on the error variant.
                                    </p>
                                    <h3>Conclusion</h3>
                                    <p>
                                        Rust's error-handling approach is different from that of other languages, but it provides a powerful way to manage errors in a safe and efficient manner. By using the Result type and handling errors properly, Rust programs can be more reliable and robust.
                                    </p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/concurrency-inrust'><b>Advance Rust Programming.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ErrorHandlingInRust;