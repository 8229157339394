import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const ConcurrencyInRust = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'DEV LESSONS BASICS',
                                    href: 'dev-lessons-basics',
                                },
                                {
                                    title: 'concurrency in go',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Concurrency in Go</h1>
                                <p>
                                    In this lesson, we will cover concurrency in Go, including an introduction to concurrency, using Goroutines and Channels, and understanding race conditions and synchronization.
                                </p>

                                <div className="learn-module">
                                    <h1>Lesson Objectives:</h1>
                                    <div className="learn-m-card">
                                        <p>By the end of this lesson, you should be able to:</p>
                                        <ul>
                                            <li>
                                                Understand what concurrency is and why it's important.
                                            </li>
                                            <li>
                                                Use Goroutines and Channels in Go to create concurrent programs.
                                            </li>
                                            <li>
                                               Understand race conditions and synchronization in Go.
                                            </li>
                                        </ul>
                                    </div>

                                    <h3>What is Concurrency?</h3>

                                    <p>
                                        Concurrency is the ability of a program to run multiple tasks simultaneously. In a concurrent program, tasks are executed independently and may be started, stopped, or paused at any time. Concurrent programs can improve performance and responsiveness by allowing tasks to run in parallel and by preventing tasks from blocking each other.
                                    </p>

                                    <h3>Why is Concurrency Important?</h3>

                                    <p>
                                        Concurrency is important because it allows programs to take full advantage of modern hardware, which typically has multiple processors or cores. By running tasks in parallel, a concurrent program can utilize all available resources and perform complex computations more quickly than a single-threaded program.
                                    </p>

                                    <p>
                                        Concurrency can also improve the responsiveness and interactivity of a program by allowing it to perform multiple tasks at once. For example, a web server can handle multiple requests simultaneously, allowing it to serve many clients at once without blocking any of them.
                                    </p>

                                    <h3>How is Concurrency Implemented in Go?</h3>

                                    <p>
                                        Go has built-in support for concurrency through goroutines and channels. Goroutines are lightweight threads that are managed by the Go runtime. They allow multiple functions to run concurrently within a single process. Channels provide a way for goroutines to communicate and synchronize with each other.
                                    </p>
                                    <CodeSnippet
codeData={`func main() {    
    c := make(chan int)  

    go func() {        
        for i := 0; i < 10; i++ {            
            c <- i        
        }        
        close(c)    
    }()    
    for i := range c {        
        fmt.Println(i)    
    }
}`}
/>
                                    <p>
                                        In the above example, a goroutine is started that sends the values 0 through 9 to a channel. The main function then receives these values from the channel using a range loop.
                                    </p>
                                    <p>
                                        This is just a simple example of how concurrency can be implemented in Go. Go provides many more features and tools for implementing concurrent programs, including mutexes, condition variables, and select statements.
                                    </p>

                                    <h3>Conclusion</h3>
                                    <p>
                                        In summary, concurrency is the ability of a program to run multiple tasks simultaneously. Concurrency is important because it allows programs to take full advantage of modern hardware and improve performance and responsiveness. In Go, concurrency is implemented through goroutines and channels, which provide a powerful and flexible way to write concurrent programs. By understanding concurrency and how it's implemented in Go, you can write faster, more responsive programs that take full advantage of modern hardware.
                                    </p>

                                    <h2>Using Goroutines and Channels in Go</h2>
                                    <h3>Creating and Using Goroutines</h3>
                                    <p>
                                        Goroutines are lightweight threads that are managed by the Go runtime. They allow multiple functions to run concurrently within a single process. Goroutines can be created using the go keyword, which launches a new goroutine to execute the specified function.
                                    </p>
                                    <CodeSnippet
codeData={`func main() {    
    go hello()    
    fmt.Println("Main function")
}
func hello() {    
    fmt.Println("Hello from another goroutine")
}`}
/>

                                    <h3>Creating and Using Channels</h3>
                                    <p>
                                        Channels provide a way for goroutines to communicate and synchronize with each other. Channels can be created using the make function, which creates a new channel with a specified type.
                                    </p>
                                    <CodeSnippet
codeData={`func main() {    
    c := make(chan int)    
    go func() {        
        c <- 42    
    }()    
    fmt.Println(<-c)
}`}
/>
                                    <p>
                                        In the above example, a new channel of type int is created using the make function. A goroutine is launched to send the value 42 to the channel using the c {`<- 42`} syntax. The main function then receives the value from the channel using the {`<-c`} syntax.
                                    </p>

                                    <h3>Synchronizing Goroutines Using Channels</h3>

                                    <p>
                                        Channels can be used to synchronize goroutines, allowing them to coordinate their actions and avoid race conditions. One way to synchronize goroutines is to use a channel to signal when a task is complete.
                                    </p>
                                    <CodeSnippet
codeData={`func main() {    
    c := make(chan bool)    
    go func() {        
        // do some work        
        c <- true    
    }()    
    <-c    
    fmt.Println("Task completed")
}`}
/>

                                    <p>
                                        In the above example, a new channel of type bool is created to signal when the task is complete. A goroutine is launched to perform some work, and when the work is done, it sends a true value to the channel using the c {`<-`} true syntax. The main function then waits for a value to be received from the channel using the {`<-c`} syntax before printing "Task completed".
                                    </p>

                                    <h3>Using Select Statements to Handle Multiple Channels</h3>

                                    <p>
                                        Select statements provide a way to handle multiple channels in a single goroutine. The select statement waits until one of the channels is ready to receive or send a value, and then executes the corresponding block of code.
                                    </p>
                                    <CodeSnippet
codeData={`func main() {
    c1 := make(chan string)
    c2 := make(chan string)
    
    go func() {
        time.Sleep(time.Second)
        c1 <- "Hello"    
    }()    
    go func() {        
        time.Sleep(time.Second * 2)        
        c2 <- "World"    
    }()    
    
    for i := 0; i < 2; i++ {        
        select {        
        case msg1 := <-c1:            
            fmt.Println(msg1)        
        case msg2 := <-c2:            
            fmt.Println(msg2)        
        }
    }
} `}
/>
                                    <p>
                                        In the above example, two channels of type `string are created using the make function. Two goroutines are launched to send a value to each channel after a specified amount of time has passed. The main function uses a for loop and a select statement to wait for a value to be received from one of the channels. When a value is received, the corresponding block of code is executed.
                                    </p>

                                    <h3>Conclusion</h3>
                                    <p>
                                        Goroutines and channels are powerful features in Go that allow for efficient and safe concurrent programming. By using goroutines and channels, you can create programs that can take advantage of modern hardware to perform complex tasks in parallel. With the knowledge gained in this lesson, you should be able to create your own concurrent programs using goroutines and channels in Go.
                                    </p>

                                    <h2>Understanding Race Conditions and Synchronization in Go</h2>
                                    <h3>Overview</h3>
                                    <p>
                                        Go is a concurrent programming language that allows for the creation of lightweight threads called goroutines. However, with the concurrent execution of multiple goroutines comes the risk of race conditions, which can result in unpredictable behavior and errors in a program. In this lesson, we will explore the concept of race conditions and synchronization techniques in Go.
                                    </p>

                                    <h3>Race Conditions</h3>
                                    <p>
                                        A race condition occurs when two or more goroutines access a shared resource concurrently, and the outcome depends on the order of access. The result may be unpredictable or incorrect because the goroutines' execution order is not guaranteed.
                                    </p>
                                    <p>
                                        For example, consider a program that maintains a shared counter variable that is incremented by multiple goroutines. If two goroutines increment the counter at the same time, it may result in the counter being incremented only once instead of twice.
                                    </p>
                                    <CodeSnippet
codeData={`var counter int
    
func incrementCounter() {    
    counter++
}`}
/>
                                    <p>
                                        In this scenario, the final value of the counter is incorrect because both goroutines incremented the counter at the same time, resulting in the counter being incremented only once instead of twice.
                                    </p>

                                    <h2>Synchronization Techniques</h2>
                                    <p>
                                        To avoid race conditions, synchronization techniques can be used to ensure that only one goroutine accesses a shared resource at a time.
                                    </p>
                                    <h3>Mutex</h3>

                                    <p>
                                        A mutex (short for mutual exclusion) is a synchronization primitive used to protect shared resources from simultaneous access by multiple goroutines. A mutex provides exclusive access to a shared resource by allowing only one goroutine to hold the mutex at a time.
                                    </p>
                                    <p>
                                        In Go, a mutex is implemented by the sync.Mutex type. Here is an example of how to use a mutex to protect a shared counter variable:
                                    </p>
                                    <CodeSnippet
codeData={`import (    
    "sync"    
    "fmt"
    )
    
    var counter int
    var mutex sync.Mutex
    
    func incrementCounter() {    
        mutex.Lock()    
        counter++    
        mutex.Unlock()
    }
    
    func main() {    
        for i := 0; i < 10; i++ {        
            go incrementCounter()    
        }    
        // Wait for goroutines to complete    
        fmt.Scanln()    
        fmt.Println("Counter:", counter)
    }`}
/>
                                    <p>
                                        In this example, the mutex.Lock() call ensures that only one goroutine can access the counter variable at a time, while the mutex.Unlock() call releases the mutex after the access is complete.
                                    </p>

                                    <h3>Channels</h3>
                                    <p>
                                        Channels are another synchronization mechanism in Go that can be used to avoid race conditions. A channel provides a way for multiple goroutines to communicate and synchronize their operations.
                                    </p>
                                    <p>
                                        Here is an example of how to use a channel to protect a shared counter variable:
                                    </p>
                                    <CodeSnippet
codeData={`import "fmt"
                                    
var counter int
var done chan bool

func incrementCounter() {    
    counter++    
    done <- true
    }
    
func main() {    
    done = make(chan bool)    
    for i := 0; i < 10; i++ {        
        go incrementCounter()
        }    
        // Wait for goroutines to complete    
        for i := 0; i < 10; i++ {        
            <-done    
        }    
        fmt.Println("Counter:", counter)
    }`}
/>

                                    <p>
                                        In this example, the done channel is used to synchronize the access to the counter variable. When a goroutine increments the counter, it sends a value to the done channel, indicating that the access is complete. Other goroutines that need to access the counter can wait for the value to be received from the channel before accessing the counter.
                                    </p>

                                    <h3>Conclusion</h3>

                                    <p>
                                        Race conditions are a common problem in concurrent programming, but they can be avoided by using synchronization techniques like mutexes and channels. By ensuring that only one goroutine accesses a shared resource at a time, we can prevent unpredictable behavior and errors in our programs. In Go, mutexes and channels are powerful tools for achieving synchronization and building safe, concurrent applications.
                                    </p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/working-withfiles-inrust'><b>Working with Files and I/O in Rust.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ConcurrencyInRust;