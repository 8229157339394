import { Button, Tabs } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import "./index.less";

import Eventbg1 from '../../../assets/images/event-bg1.png';
import EventImg1 from '../../../assets/images/event-1.jpg';
import SpeakerImg from '../../../assets/images/speaker.png';
import TopBlueBg from '../../../assets/images/blue-texture-left.png';
import RedRightBg from '../../../assets/images/red-texture-right2.png';
import BlueLeftBg from '../../../assets/images/blue-texture-left2.png';

const DayOne = () => {
    return (
        <ul className="schedule-list">
            <li>
                <div className="time">9AM - 10 AM</div>
                <div className="right-dtl">
                    <h3>Lorem Ipsum</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
            </li>
            <li>
                <div className="time">9AM - 10 AM</div>
                <div className="right-dtl">
                    <h3>Lorem Ipsum</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
            </li>
            <li>
                <div className="time">9AM - 10 AM</div>
                <div className="right-dtl">
                    <h3>Lorem Ipsum</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
            </li>
            <li>
                <div className="time">9AM - 10 AM</div>
                <div className="right-dtl">
                    <h3>Lorem Ipsum</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
            </li>
            <li>
                <div className="time">9AM - 10 AM</div>
                <div className="right-dtl">
                    <h3>Lorem Ipsum</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
            </li>
            <li>
                <div className="time">9AM - 10 AM</div>
                <div className="right-dtl">
                    <h3>Lorem Ipsum</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
            </li>
        </ul>
    )
}

const items = [
    {
      key: '1',
      label: <><h3>Day 01</h3><p>Wed, January 21</p></>,
      children: <DayOne />,
    },
    {
      key: '2',
      label: <><h3>Day 02</h3><p>Thu, January 22</p></>,
      children: <DayOne />,
    },
    {
      key: '3',
      label: <><h3>Day 03</h3><p>Fri, January 23</p></>,
      children: <DayOne />,
    },
];

const EventDetails = () => {
    return (
        <div className="homepage-wrapper eventslist-wrapper event-details">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col sm='10'>
                            <Link className="bank-btn" to='/events'><SvgIcon name='arrow-left' viewbox='0 0 15 15' /> Back</Link>
                            <h1>The legendary <br /> Cosmos Conference</h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <div className="ticket-action">
                                <div>
                                    <h4>March 21-23</h4>
                                    <p>Istanbul, Turkey</p>
                                </div>
                                <Button type="primary">Get Tickets</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <img className="eventbanner-bg" src={Eventbg1} alt={Eventbg1} />
            </section>
            <section className="speakers-section">
                <img className="red-right" src={RedRightBg} alt='' />
                <Container>
                    <Row>
                        <Col>
                            <h2>Speakers</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul className="speaker-list">
                                <li>
                                    <div className="image-circle">
                                        <div className="image-circle-inner">
                                            <img src={SpeakerImg} alt="SpeakerImg" />
                                        </div>
                                    </div>
                                    <h4>Lorem Ipsum</h4>
                                    <p>Co-Founder of Cosmos</p>
                                </li>
                                <li>
                                    <div className="image-circle">
                                        <div className="image-circle-inner">
                                            <img src={SpeakerImg} alt="SpeakerImg" />
                                        </div>
                                    </div>
                                    <h4>Lorem Ipsum</h4>
                                    <p>Co-Founder of Cosmos</p>
                                </li>
                                <li>
                                    <div className="image-circle">
                                        <div className="image-circle-inner">
                                            <img src={SpeakerImg} alt="SpeakerImg" />
                                        </div>
                                    </div>
                                    <h4>Lorem Ipsum</h4>
                                    <p>Co-Founder of Cosmos</p>
                                </li>
                                <li>
                                    <div className="image-circle">
                                        <div className="image-circle-inner">
                                            <img src={SpeakerImg} alt="SpeakerImg" />
                                        </div>
                                    </div>
                                    <h4>Lorem Ipsum</h4>
                                    <p>Co-Founder of Cosmos</p>
                                </li>
                                <li>
                                    <div className="image-circle">
                                        <div className="image-circle-inner">
                                            <img src={SpeakerImg} alt="SpeakerImg" />
                                        </div>
                                    </div>
                                    <h4>Lorem Ipsum</h4>
                                    <p>Co-Founder of Cosmos</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="eventdtl-section">
                <Container>
                    <Row>
                        <Col md='4'>
                            <h2>Schedule and Agenda</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <Button type="secondary" className="with-icon" icon={<SvgIcon name="arrow-right" viewbox="0 0 15 15" />}>LEarn MOre</Button>
                        </Col>
                        <Col md='8'>
                            <Tabs defaultActiveKey="1" items={items} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="eventlist-section py-5 mb-5">
                <img className="blue-left" src={BlueLeftBg} alt='' />
                <Container>
                    <Row className='event-list-row'>
                        <Col md='5' className='event-content'>
                            <h2>Venue</h2>
                            <p>
                               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <div className="location-row">
                                <SvgIcon name='map-marker' viewbox='0 0 14 20' /> Istanbul, Turkey
                            </div>
                        </Col>
                        <Col md='7'>
                            <img className="event-img" src={EventImg1} alt="Coming to Istanbul in 2023" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.com/invite/7vjPvWKKMT' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default EventDetails;