import React from "react";
import { Button } from "antd";
import Lottie from 'react-lottie';
import { Col, Container, Row, SvgIcon } from "../../components/common";
import "./index.less";

import getcmdxRightbg from '../../assets/images/getcmdx-rightbg.png';
import getcmdxCircleLeft from '../../assets/images/getcmdx-circle.png';
import getCmdxLogo from '../../assets/images/get-cmdx.png';
import Cube1 from '../../assets/images/in-cube-1.png';
import Cube2 from '../../assets/images/in-cube-2.png';
import Cube3 from '../../assets/images/cube-2.png';
import Cube4 from '../../assets/images/cube-1.png';
import HelixIcon2 from '../../assets/images/helix2.png';
import getCmdx1 from '../../assets/json/get-cmdx-1.json';
import getCmdx2 from '../../assets/json/get-cmdx-2.json';
import getCmdx3 from '../../assets/json/get-cmdx-3.json';
import getCmdx4 from '../../assets/json/get-cmdx-4.json';
import TopBlueBg from '../../assets/images/blue-texture-left.png';
import GetcmdxModal from "./GetcmdxModal";
import GetcmdxWalletsModal from "./GetcmdxWalletsModal";
// import GetValidatorModal from "./getValidatorModal";

const Getcmdx = () => {
    const animationLottie1 = {
        loop: true,
        autoplay: true,
        animationData: getCmdx1,
        renderer: 'svg',
    }
    const animationLottie2 = {
        loop: true,
        autoplay: true,
        animationData: getCmdx2,
        renderer: 'svg',
    }
    const animationLottie3 = {
        loop: true,
        autoplay: true,
        animationData: getCmdx3,
        renderer: 'svg',
    }
    const animationLottie4 = {
        loop: true,
        autoplay: true,
        animationData: getCmdx4,
        renderer: 'svg',
    }
    return (
        <div className="homepage-wrapper getcmdx-wrapper">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col sm='7'>
                            <h1>Get CMDX <br /> and stake.</h1>
                        </Col>
                        <Col sm='5' className='right-col'>
                            <img src={getCmdxLogo} alt='Get CMDX' />
                        </Col>
                    </Row>
                </Container>
                <img className="left-circle" src={getcmdxCircleLeft} alt='' />
                <img className="right-bg" src={getcmdxRightbg} alt='' />
            </section>
            <section className="getcmdx-section">
                <Container>
                    <Row className='getcmdx-row'>
                        <Col md='6'>
                            <div className="getcmdx-text">
                                <div className="icon-circle">
                                    <div className="icon-circle-inner">
                                        01
                                    </div>
                                </div>
                                <div>
                                    <h2>Get CMDX through fiat or one of many crypto exchanges</h2>
                                    <p>
                                        Access the CMDX token directly through fiat or from one of the many DEXes where CMDX is listed.                                    </p>
                                    <GetcmdxModal
                                        text={"Browse DEXes"}
                                        title={"DEXes"}
                                        buyWithFiat={false}
                                    />
                                    <GetcmdxModal
                                        text={"With Fiat"}
                                        title={"Fiat On-Ramp"}
                                        buyWithFiat={true}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md='6' className="getcmdx-immage getcmdx-immage1">
                            <img className="cube-1" src={Cube1} alt='' />
                            <img className="cube-2" src={Cube2} alt='' />
                            <img className="cube-3" src={Cube3} alt='' />
                            <img className="cube-4" src={Cube4} alt='' />
                            <Lottie
                                options={animationLottie1}
                                height={400}
                                width={400}
                                style={{ maxWidth: '100%' }}
                            />
                        </Col>
                    </Row>
                    <Row className='getcmdx-row'>
                        <Col md='6' className="getcmdx-immage getcmdx-immage2">
                            <img className='helix-icon' src={HelixIcon2} alt='' />
                            <img className='cube-1' src={Cube1} alt='' />
                            <img className='cube-2' src={Cube3} alt='' />
                            <Lottie
                                options={animationLottie2}
                                height={400}
                                width={400}
                                style={{ maxWidth: '100%' }}
                            />
                        </Col>
                        <Col md='6'>
                            <div className="getcmdx-text">
                                <div className="icon-circle">
                                    <div className="icon-circle-inner">
                                        02
                                    </div>
                                </div>
                                <div>
                                    <h2>Transfer your CMDX to a wallet</h2>
                                    <p>
                                        Choose a wallet that suits your needs, create an account, and transfer your CMDX.
                                    </p>
                                    <GetcmdxWalletsModal />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='getcmdx-row'>
                        <Col md='6'>
                            <div className="getcmdx-text">
                                <div className="icon-circle">
                                    <div className="icon-circle-inner">
                                        03
                                    </div>
                                </div>
                                <div>
                                    <h2>Select your validators on any platform</h2>
                                    <p>
                                        Choose the validators you want to delegate your CMDX to. Spread your risk by delegating your CMDX to multiple validators.
                                    </p>
                                    <Button type="secondary" href="https://comdex.omniflix.co/ " target="_blank">Choose Validators</Button>
                                    {/* <GetValidatorModal/> */}
                                </div>
                            </div>
                        </Col>
                        <Col md='6' className="getcmdx-immage getcmdx-immage3">
                            <img className="cube-1" src={Cube1} alt='' />
                            <img className="cube-2" src={Cube2} alt='' />
                            <img className="cube-3" src={Cube3} alt='' />
                            <img className="cube-4" src={Cube4} alt='' />
                            <Lottie
                                options={animationLottie3}
                                height={400}
                                width={400}
                                style={{ maxWidth: '100%' }}
                            />
                        </Col>
                    </Row>
                    <Row className='getcmdx-row'>
                        <Col md='6' className="getcmdx-immage getcmdx-immage4">
                            <img className="cube-1" src={Cube1} alt='' />
                            <img className="cube-2" src={Cube2} alt='' />
                            <img className="cube-3" src={Cube3} alt='' />
                            <img className="cube-4" src={Cube4} alt='' />
                            <Lottie
                                options={animationLottie4}
                                height={400}
                                width={400}
                                style={{ maxWidth: '100%' }}
                            />
                        </Col>
                        <Col md='6'>
                            <div className="getcmdx-text">
                                <div className="icon-circle">
                                    <div className="icon-circle-inner">
                                        04
                                    </div>
                                </div>
                                <div>
                                    <h2>Lock up and stake your CMDX</h2>
                                    <p>
                                        Using your wallet, Delegate your CMDX to your chosen validator to start staking. This locks up your CMDX (i.e. you can't transfer them) and enables the chosen validators to operate the network.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.com/invite/7vjPvWKKMT' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Getcmdx;