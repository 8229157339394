import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import ArticleCard from "../../../components/common/ArticleCard";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import "./index.less";

import Eventbg1 from '../../../assets/images/event-bg1.png';
import articleImg1 from '../../../assets/images/recent-articles-1.jpg';
import articleImg2 from '../../../assets/images/recent-articles-2.jpg';
import articleImg3 from '../../../assets/images/recent-articles-3.jpg';
import BlogImg1 from '../../../assets/images/cswap-1.png';
import BlogImg2 from '../../../assets/images/cswap-2.png';
import BlogImg3 from '../../../assets/images/cswap-3.png';
import BlogImg4 from '../../../assets/images/cswap-4.png';
import BlogImg5 from '../../../assets/images/cswap-5.png';
import BlogImg6 from '../../../assets/images/cswap-6.png';
import BlogImg7 from '../../../assets/images/cswap-7.png';
import TopBlueBg from '../../../assets/images/blue-texture-left.png';

const BlogDetailsCswap = () => {
    return (
        <div className="homepage-wrapper eventslist-wrapper blog-details">
            <img className="top-left-bg" src={TopBlueBg} alt='' />
            <section className="banner-section">
                <Container>
                    <Row className="text-center">
                        <Col sm='9' className='mx-auto'>
                            <h1>How to use cSwap DEX <br /> [Walkthrough]</h1>
                            <p>
                                Posted on June 8, 2023
                            </p>
                            <img className="blog-dtl-banner" src={BlogImg1} alt="Blog" />
                        </Col>
                    </Row>
                </Container>
                <img className="banner-bg" src={Eventbg1} alt={Eventbg1} />
            </section>
            <section className="blog-details-section">
                <Container>
                    <Row>
                        <Col sm='9' className='mx-auto'>
                            <p>
                                cSwap is an IBC-enabled hybrid dex built on the Comdex chain. cSwap uses AMM liquidity pools which bring cross-chain markets and limit orders to DeFi. Through this mechanism, cSwap will be a go-to platform for traders and arbitrageurs, enabling them to implement sophisticated trading strategies.
                            </p>
                            
                            <h3>To start using cSwap, you will need to:</h3>
                            <ul>
                                <li>Connect Keplr wallet by clicking on the Keplr chrome extension and entering your password. If you don’t have Keplr wallet, you can install it by clicking here.</li>
                                <li>
                                    To get the testnet faucets, visit the “#request-faucet” channel in our Discord Server and type the command “$testnet {'<your wallet address>'}.
                                </li>
                            </ul>

                            <h3>Steps to trade/swap:</h3>
                            <ol type="1">
                                <li>Go to https://testnet.cswap.one/</li>
                                <li>Click on “Connect”.</li>
                            </ol>

                            <img className="w-100" src={BlogImg2} alt="Blog" />
                            <ul className="list-unstyled">
                                <li>3. Connect Keplr wallet; after a successful connection, you can start to use cSwap.</li>
                                <li>4. Click on “Trade”.</li>
                                <li>5. Choose the token you want to swap from by clicking on the “From” option.</li>
                            </ul>

                            <img className="w-100" src={BlogImg3} alt="Blog" />
                            <ul className="list-unstyled">
                                <li>6. Once you have selected the token you want to swap from, select the token you want to swap it to.</li>
                            </ul>

                            <img className="w-100" src={BlogImg4} alt="Blog" />
                            <ul className="list-unstyled">
                                <li>7. Type in the number of tokens to swap.</li>
                            </ul>

                            <img className="w-100" src={BlogImg5} alt="Blog" />
                            <ol>
                                <li>Remember to always leave some $CMDX in your wallet for gas when swapping tokens.</li>
                                <li>Make sure Slippage is not too high.</li>
                            </ol>
                            <ul className="list-unstyled">
                                <li>8. Click “Swap”.</li>
                            </ul>
                            <p>Review trade parameters and click on “swap”. Keplr wallet will show a pop-up asking for the approval of the transaction.</p>

                            <img className="w-100" src={BlogImg6} alt="Blog" />

                            <h3>Steps to trade/swap:</h3> 
                            <ul className="list-unstyled">
                                <li>1. Go to https://testnet.cswap.one/</li>
                                <li>2. Click on “Connect”.</li>
                            </ul>

                            <img className="w-100" src={BlogImg7} alt="Blog" />

                            <p className="text-center">Visit the dApp Now and claim your airdrop before 26 Feb <Link to='/'>CLAIM NOW.</Link></p>

                            <div className="text-center mt-5">
                                <Button type="secondary">Take me there</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="article-section recent-blog mt-5 pt-5">
                <Container>
                    <Row>
                        <Col>
                            <div className="headings text-left mb-0">
                                <h2 className="mb-2">Related Blogs</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="acticle-list">
                                <ArticleCard 
                                    articleImg={articleImg1}
                                    name='How to claim the $HARBOR airdrop'
                                    date='Dec 5, 2022'
                                    description='Comdex is airdropping 150Mn Harbor tokens, out of which 20% airdrop is in the form Liquid Harbor, and the rest 80% is in the...'
                                />
                                <ArticleCard 
                                    articleImg={articleImg2}
                                    name='Understanding Commodo — cPools, Liquidation, and much..'
                                    date='Dec 5, 2022'
                                    description='Commodo is an interchain liquidity protocol built on the Comdex chain to deliver decentralized, efficient & secure money markets....'
                                />
                                <ArticleCard 
                                    articleImg={articleImg3}
                                    name='How to use cSwap DEX [Walkthrough]'
                                    date='Dec 5, 2022'
                                    description='cSwap is an IBC-enabled hybrid dex built on the Comdex chain. cSwap uses AMM liquidity pools which bring cross-chain markets and limit ...'
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.com/invite/7vjPvWKKMT' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='https://test1.comdex.one/developer-portal' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default BlogDetailsCswap;