import { Button, Input } from "antd";
import React, { useState } from "react";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import "./index.less";

import ExploreBanner from '../../assets/images/explore-banner.png';
import TopBlueBg from '../../assets/images/blue-texture-left.png';
import { cardData } from "./cardData";
import { Select } from 'antd';

const ExploreCard = ({ name, descriptions, iconName, type, live, commingsoon, link }) => {

    return (
        <>
            {link ?
                <Button type="link" href={link} target="_blank" rel="noreferrer noopener">
                    <div className='explore-card'>
                        <div className="explore-card-inner">
                            <div className="card-upper">
                                <div className="left-col">
                                    <div className="icon-col">
                                        <img src={iconName} alt={name} />
                                    </div>
                                </div>
                                <div className="right-col">
                                    <div className="name">{type}</div>
                                    {live ? <div className="status live"><span></span> Live</div>
                                        :
                                        <div className="status commingsoon"><span></span> COMING SOON</div>
                                    }
                                </div>
                            </div>
                            <h3>{name}</h3>
                            <div className="card-bottom">
                                <p>
                                    {descriptions}
                                </p>
                            </div>
                        </div>
                    </div>
                </Button>
                :
                <div className='explore-card'>
                    <div className="explore-card-inner">
                        <div className="card-upper">
                            <div className="left-col">
                                <div className="icon-col">
                                    <img src={iconName} alt={name} />
                                </div>
                            </div>
                            <div className="right-col">
                                <div className="name">{type}</div>
                                {live ? <div className="status live"><span></span> Live</div>
                                    :
                                    <div className="status commingsoon"><span></span> COMING SOON</div>
                                }
                            </div>
                        </div>
                        <h3>{name}</h3>
                        <div className="card-bottom">
                            <p>
                                {descriptions}
                            </p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const Explore = () => {

    const [exploreCardData] = useState(cardData);
    const [filteredExploreCardData, setFilteredCardData] = useState(cardData);

    const items = [
        {
            key: '0',
            label: "All",
            value: "All"
        },
        {
            key: '1',
            label: "dApp",
            value: "dApp"
        },
        {
            key: '2',
            label: "Wallet",
            value: "Wallet"
        },
        {
            key: '3',
            label: "Bridge",
            value: "Bridge"
        },
        {
            key: '4',
            label: "Explorer",
            value: "Explorer"
        },
        {
            key: '5',
            label: "Stable Coin",
            value: "StableCoin"
        },
        {
            key: '6',
            label: "Oracle",
            value: "Oracle"
        },
    ];

    const handleSearchChange = (e) => {
        let filteredData = exploreCardData?.filter((item) => (((item?.name).toLowerCase())?.includes((e).toLowerCase())))
        setFilteredCardData(filteredData)
    }

    const handleSelectChange = (value) => {
        let filteredData = exploreCardData?.filter((item) => (((item?.category).toLowerCase()) === value.toLowerCase()))
        if (filteredData?.length > 0) {
            setFilteredCardData(filteredData)
        } else {
            setFilteredCardData(exploreCardData)
        }
    };

    return (
        <div className="homepage-wrapper explore-wrapper">
            <section className="banner-section">
                <img className="top-left-bg" src={TopBlueBg} alt='' />
                <Container>
                    <Row className="banner-row">
                        <Col sm='7' className='banner-row-left'>
                            <h1>Comdex <br /> Ecosystem</h1>
                            <p>
                                Explore Comdex's ecosystem of products and integrations and join the community to be a part of it all.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <img className="explore-banner" src={ExploreBanner} alt={ExploreBanner} />
            </section>
            <section className="explore-section-alt">
                <Container>
                    <Row>
                        <Col className='text-right filter-row'>
                            <Select
                                defaultValue="Sort by Category"
                                style={{
                                    width: 200,
                                }}
                                className="filterdrop-popup select-drop"
                                onChange={handleSelectChange}
                                options={items}
                                suffixIcon={<SvgIcon name='drop-icon' viewbox='0 0 12 8' />}
                            />
                            <Input placeholder="Search.." suffix={<SvgIcon name='search' viewbox='0 0 27 27.007' />} onChange={(e) => handleSearchChange(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm='12'>
                            <ul className="list">
                                {
                                    filteredExploreCardData?.map((item) => {
                                        return (
                                            <li key={item.name}>
                                                <ExploreCard
                                                    name={item?.name}
                                                    descriptions={item?.descriptions}
                                                    iconName={item?.icon}
                                                    live={item?.live}
                                                    type={item?.type}
                                                    link={item?.link}
                                                />
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="statics-section-alt">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-staking' viewbox='0 0 46 46' />
                                            <div>
                                                <Button type="link" href='https://comdex.omniflix.co/' target="_blank" rel="noreferrer noopener">
                                                    <h3>Start Staking <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Get CMDX and start exploring and staking.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                                            <div>
                                                <Button type="link" href='https://discord.gg/comdexofficial' target="_blank" rel="noreferrer noopener">
                                                    <h3>Join the Discussion <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Join our discord channel for more queries and help.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-inner">
                                            <SvgIcon name='start-building' viewbox='0 0 42 42' />
                                            <div>
                                                <Button type="link" href='/developer-portal' rel="noreferrer noopener">
                                                    <h3>Start Building <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                </Button>
                                                <p>Check our Developers portal for more tutorials.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Explore;
