import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../../components/common";
import LeftNav from "../../LeftNav";
import "../../index.less";

const CosmWasmDevelopment = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'Getting started with CosmWasm',
                                        href: 'gettingstarted-with-cosmWasm',
                                    },
                                    {
                                        title: 'Development',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <div className="mb-5">
                                    <h1>Development</h1>
                                
                                    <div className="mb-4 pt-3">
                                        <h3>1. Create a Key</h3>
                                        <p>
                                            The first thing you need to interact with the testnet is a valid account. Start with adding a new key to the wasmd configuration.
                                        </p>
                                        <CodeSnippet 
                                            codeData={`$ comdex keys add <key_name>`}
                                        />
                                        <p>
                                            As a result of this command, you get information about just the prepared account. Two things are relevant here:
                                        </p>
                                        <ul className="list-2">
                                            <li>address is your identity in the blockchain</li>
                                            <li>mnemonic (omitted by myself in the example) is 12 words that allow you to recreate an account so you can use it, for example, from a different machine</li>
                                        </ul>
                                        <p>(Note: Please save the mnemonics as you will not be able to retrieve it later.)</p>
                                    </div>

                                    <div className="mb-4">
                                        <h3>2. Create a New Contract from Template</h3>
                                        <p>
                                            Assuming you have a recent version of Rust and Cargo installed (via rustup), then the following should get you a new repo to start a contract:
                                        </p>
                                        <p>Install cargo-generate and cargo-run-script. Unless you did that before, run this line now:</p>
                                        <CodeSnippet 
                                            codeData={`$ cargo install cargo-generate --features vendored-openssl
$ cargo install cargo-run-script`}
                                        />
                                        <p>Now, use it to create your new contract. Go to the folder in which you want to place it and run</p>
                                        <CodeSnippet 
                                            codeData={`cargo generate --git https://github.com/CosmWasm/cw-template.git --name PROJECT_NAME`}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <h3>3. Compiling a Contract</h3>
                                        <p>To reduce gas costs, the binary size should be as small as possible. This will result in a less costly deployment, and lower fees on every interaction. Luckily, there is tooling to help with this. You can optimise production code using the rust-optimizer. rust-optimizer produces reproducible builds of CosmWasm smart contracts. This means third parties can verify that the contract is actually the claimed code.</p>
                                        <CodeSnippet 
                                            codeData={`docker run --rm -v "$(pwd)":/code \

--mount type=volume,source="$(basename "$(pwd)")_cache",target=/code/target \

--mount type=volume,source=registry_cache,target=/usr/local/cargo/registry \

cosmwasm/rust-optimizer:0.12.11
`}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <h3>4. Upload a Contract</h3>
                                        <p>
                                            After successful compilation of code , docker will generate the {`<project_name>`}.wasm file in the artifacts folder.
                                        </p>
                                        <p>
                                            Comdex testnet and mainnet has permissioned CosmWasm i.e governance proposal is needed to upload the contract
                                        </p>
                                        <CodeSnippet 
codeData={`comdex tx gov submit-proposal wasm-store <project_name>.wasm --title <title> --description 
<description> --builder <optimizer_used> --code-hash <checksum> --code-source-url 
<code_source>  --from <key_name> --chain-id <CHAIN_ID> --node <RPC> --gas 1000000 --fees 
100000ucmdx --deposit <deposit_coin> –-admin <admin_address>`}
                                        />
                                        <p>
                                            Once the proposal is passed , you can retrieve the code_id from below query
                                        </p>
                                        <CodeSnippet 
                                            codeData={`Comdex q wasm list-code --chain-id <CHAIN_ID> --node <RPC>`}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <h3>5. Initialize contract</h3>
                                        <CodeSnippet 
codeData={`comdex tx wasm instantiate <code_id> <json_encoded_init_args> --label <string label>  --from 
<key_name> --chain-id <CHAIN_ID> -- node <RPC> --gas 1000000 --fees 100000ucmdx`}
                                        />
                                        <p>
                                            Once successful completion of the transaction , the contract address will be generated.
                                        </p>
                                        <p>
                                            Use comdex q tx <b>{`<tx_hash> --node <RPC>`}</b> to extract the contract address from the output log.
                                        </p>
                                    </div>

                                    <div className="mb-4">
                                        <h3>6. Execute a  transaction</h3>
                                        <CodeSnippet 
codeData={`comdex tx wasm execute <CONTRACT_ADDR>  <EXECUTE_MSG> --from <key_name>  --chain-id 
<CHAIN_ID> --node <RPC> --gas 1000000 --fees 100000ucmdx`}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <h3>7. Query Data from contract</h3>
                                        <CodeSnippet 
codeData={`comdex q wasm contract-state smart <CONTRACT_ADDR>  <QUERY_MSG> --chain-id 
<CHAIN_ID>  --node <RPC>`}
                                        />
                                    </div>
                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/example-ofRust-contract'><b>Example of Rust Contract..</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmWasmDevelopment;