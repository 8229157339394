import { Button, Divider } from "antd";
import React from "react";
import { Container, Row, Col, SvgIcon } from "../../components/common";
import { Link } from "react-router-dom";
import "./index.less";

import footerBg from '../../assets/images/footer-bg.png';

const FooterBar = () => {
    return (
        <div className="footer-main">
            <img className="footer-bg" src={footerBg} alt='' />
            <Container>
                <Row>
                    <Col>
                        <Divider />
                    </Col>
                </Row>
                <Row>
                    <Col lg="2" md="4" className="footer-col footerleft-col">
                        <SvgIcon name="comdex-logo" viewbox="0 0 89.782 19.965" />
                    </Col>
                    <Col lg="2" md="4" sm='6' xs='6' className="footer-col">
                        <h3>dApps</h3>
                        <ul>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://cswap.one/", "_blank");
                                    newWindow.opener = null;
                                }}>cSwap</Button>
                            </li>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://harborprotocol.one/ ", "_blank");
                                    newWindow.opener = null;
                                }}>Harbor</Button>
                            </li>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://commodo.one/ ", "_blank");
                                    newWindow.opener = null;
                                }}>Commodo</Button>
                            </li>
                        </ul>
                    </Col>
                    <Col lg="2" md="4" sm='6' xs='6' className="footer-col">
                        <h3>Staking</h3>
                        <ul>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://comdex.omniflix.co/", "_blank");
                                    newWindow.opener = null;
                                }}>OmniFlix</Button>
                            </li>
                            {/* <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://app.unagii.com/stake/comdex", "_blank");
                                    newWindow.opener = null;
                                }}>Unagii</Button>
                            </li> */}
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://wallet.cosmostation.io/comdex", "_blank");
                                    newWindow.opener = null;
                                }}>Cosmostation</Button>
                            </li>
                            {/* <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://ezstaking.tools/delegations", "_blank");
                                    newWindow.opener = null;
                                }}>Ez-Staking</Button>
                            </li>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://citadel.one/", "_blank");
                                    newWindow.opener = null;
                                }}>Citadel </Button>
                            </li> */}
                        </ul>
                    </Col>
                    <Col lg="2" md="4" sm='6' xs='6' className="footer-col">
                        <h3>Network</h3>
                        <ul>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://www.mintscan.io/comdex", "_blank");
                                    newWindow.opener = null;
                                }}>Mintscan</Button>
                            </li>
                            {/* <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://comdex.aneka.io/", "_blank");
                                    newWindow.opener = null;
                                }}>Aneka</Button>
                            </li> */}
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://look.chillvalidation.com/comdex", "_blank");
                                    newWindow.opener = null;
                                }}>Look.Chill</Button>
                            </li>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://atomscan.com/comdex", "_blank");
                                    newWindow.opener = null;
                                }}>Atomscan</Button>
                            </li>
                        </ul>
                    </Col>
                    <Col lg="2" md="4" sm='6' xs='6' className="footer-col">
                        <h3>Resources</h3>
                        <ul>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://docs.comdex.one/", "_blank");
                                    newWindow.opener = null;
                                }}>Docs</Button>
                            </li>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://paper.comdex.one/Comdex-Litepaper.pdf", "_blank");
                                    newWindow.opener = null;
                                }}>Litepaper</Button>
                            </li>
                            <li>
                                <Button type="link" onClick={() => {
                                    let newWindow = window.open("https://academy.comdex.one/", "_blank");
                                    newWindow.opener = null;
                                }}>Academy</Button>
                            </li>
                            <li>
                                <Link to="/blogs">Blogs</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col lg="2" md="4" sm='6' xs='6' className="footer-col footer-col-last">
                        <h3>Get in touch</h3>
                        <p>
                            <Link to='/contact-us'>
                                Question or Feedback? <br /> We’d love to hear from you
                            </Link>
                        </p>
                        <p>info@comdex.one</p>
                    </Col>
                </Row>
                <Row style={{ alignItems: "center" }}>
                    <Col sm="6" className="footerbottom-left">
                        <p> © 2024 Comdex. All Rights Reserved.</p>
                    </Col>
                    <Col sm="6" className="footerbottom-right">
                        <div className="footer-links">
                            <Button type="link" href="https://twitter.com/ComdexOfficial" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="twitter_side" viewbox="0 0 55.819 46.915" />
                            </Button>
                            <Button type="link" href="https://t.me/ComdexChat" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="telegram" viewbox="0 0 54.209 46.163" />
                            </Button>
                            <Button type="link" href="https://forum.comdex.one/" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="forum" viewbox="0 0 27.205 27.205" />
                            </Button>
                            <Button type="link" href="https://discord.gg/comdexofficial" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="discord" viewbox="0 0 65.002 49.503" />
                            </Button>
                            <Button type="link" href="https://blog.comdex.one/" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="medium" viewbox="0 0 58.333 48" />
                            </Button>
                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="github" viewbox="0 0 60 59.495" />
                            </Button>
                            <Button type="link" href="https://www.reddit.com/r/ComdexOne/" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="reddit" viewbox="0 0 18 18" />
                            </Button>
                            <Button type="link" href="https://www.linkedin.com/company/comdexofficial/" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="linkedin" viewbox="0 0 18 18" />
                            </Button>
                            <Button type="link" href="/contact-us " target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="chat" viewbox="0 0 25.715 18" />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FooterBar
