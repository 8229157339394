import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const CosmosModuleTwo = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'WHAT IS COSMOS',
                                    href: '',
                                },
                                {
                                    title: 'MODULE 2',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Building a Basic Cosmos Application</h1>
                                <p>
                                    In this module, we will introduce the Cosmos SDK and its architecture. We will also compare it with other blockchain frameworks and discuss the key features and benefits of using the Cosmos SDK. Finally, we will go through the process of setting up the development environment.
                                </p>

                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                
                                <div className="learn-module">
                                    <h1>What will you learn in Module 2.1</h1>
                                    <div className="learn-m-card">
                                        <ul>
                                            <li>
                                                Overview of blockchain application development with Cosmos SDK
                                            </li>
                                            <li>
                                                Setting up a new blockchain application using the SDK
                                            </li>
                                            <li>
                                                Understanding the basic structure of a Cosmos application
                                            </li>
                                            <li>
                                                Creating a new module and integrating it into the application
                                            </li>
                                            <li>
                                                Testing the new module using the SDK's built-in tools
                                            </li>
                                        </ul>
                                    </div>

                                    <h1>Creating a basic blockchain application using Cosmos SDK</h1>

                                    <p>
                                        In this submodule, we'll introduce the basics of developing a blockchain application using the Cosmos SDK. We'll start with an overview of blockchain application development with Cosmos SDK, followed by setting up a new blockchain application using the SDK. 
                                    </p>
                                    
                                    <p>
                                        Then, we'll explore the basic structure of a Cosmos application and create a new module that we'll integrate into the application. Finally, we'll test the new module using the SDK's built-in tools.
                                    </p>

                                    <h3>Topics:</h3>
                                    <ol>
                                        <li>Introduction to blockchain application development with Cosmos SDK</li>
                                        <li>Setting up a new blockchain application using the SDK</li>
                                        <li>Understanding the basic structure of a Cosmos application</li>
                                        <li>Creating a new module and integrating it into the application</li>
                                        <li>Testing the new module using the SDK's built-in tools</li>
                                    </ol>

                                    <h3 className="mt-5">Subtopics:</h3>
                                    <h4>1. Introduction to blockchain application development with Cosmos SDK:</h4>
                                    <ul className="list-2">
                                        <li>
                                            Overview of Cosmos SDK and its capabilities
                                        </li>
                                        <li>
                                            Understanding the role of the SDK in blockchain application development
                                        </li>
                                        <li>
                                            Advantages of using Cosmos SDK for blockchain application development
                                        </li>
                                    </ul>

                                    <h4>2. Setting up a new blockchain application using the SDK</h4>
                                    <ul className="list-2">
                                        <li>
                                            Installing the required software for development using Cosmos SDK
                                        </li>
                                        <li>
                                            Creating a new project using the Cosmos SDK
                                        </li>
                                        <li>
                                            Setting up a local blockchain network for testing and development
                                        </li>
                                    </ul>

                                    <h4>3. Understanding the basic structure of a Cosmos application:</h4>
                                    <ul className="list-2">
                                        <li>Overview of the different components of a Cosmos application</li>
                                        <li>Understanding the roles of the ABCI server, SDK modules, and other components</li>
                                        <li>Examining the interactions between the different components during application execution</li>
                                    </ul>

                                    <h4>4. Creating a new module and integrating it into the application:</h4>
                                    <ul className="list-2">
                                        <li>Understanding the modular architecture of the Cosmos SDK</li>
                                        <li>Creating a new module using the SDK's built-in tools</li>
                                        <li>Integrating the new module into the application</li>
                                        <li>Understanding the interactions between the new module and the existing modules</li>
                                    </ul>

                                    <h4>5. Testing the new module using the SDK's built-in tools:</h4>
                                    <ul className="list-2">
                                        <li>Overview of the SDK's built-in testing tools</li>
                                        <li>Creating and running tests for the new module</li>
                                        <li>Analysing test results and debugging issues</li>
                                        <li>Understanding the modular architecture of the Cosmos SDK</li>
                                    </ul>

                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with the first section <Link to='/'><b>Introduction to blockchain application development with Cosmos SDK:</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmosModuleTwo;