import cSwapIcon from '../../assets/images/cSwap.png';
import HarborIcon from '../../assets/images/Harbor.png';
import CmstIcon from '../../assets/images/Cmst.png';
import CommodoIcon from '../../assets/images/Commodo.png';
import cAssetIcon from '../../assets/images/cAsset.png';
import ShipFiIcon from '../../assets/images/ShipFi.png';
import LeapWalletIcon from '../../assets/images/Leap-Wallet.png';
import AxelarIcon from '../../assets/images/Axelar.png';
import SyndicateIcon from '../../assets/images/Syndicate.png';
import MintscanIcon from '../../assets/images/Mintscan.png';
import BigDipperIcon from '../../assets/images/BigDipper.png';
import SkipIcon from '../../assets/images/Skip.png';
import WhiteWhaleIcon from '../../assets/images/White-Whale.png';
import CitadeloneIcon from '../../assets/images/Citadel-one.png';
import BandProtocolIcon from '../../assets/images/Band-Protocol.png';

export const cardData = [
    {
        name: "cSwap",
        descriptions: "cSwap is an IBC-enabled hybrid dex built on the Comdex chain. cSwap uses AMM liquidity pools which bring cross-chain markets and limit orders to DeFi. Through this mechanism, cSwap will be a go-to platform for traders and arbitrageurs, enabling them to implement sophisticated trading strategies.",
        icon: cSwapIcon,
        live: true,
        type: "dApp",
        link: "https://cswap.one/",
        category: "dApp"
    },
    {
        name: "CMST",
        descriptions: "Composite ($CMST) is a decentralized, censorship-resistant, over-collateralized stablecoin, backed by interchain assets, and is built on the Comdex chain.",
        icon: CmstIcon,
        live: true,
        type: "Stable Coin",
        link: "https://harborprotocol.one/home",
        category: "StableCoin"
    },
    {
        name: "Harbor",
        descriptions: "HARBOR protocol is the dApp on the Comdex chain (powered by the Cosmos SDK and CosmWasm smart contracts) that enables safelisted assets to be locked in Vaults and mint Composite.",
        icon: HarborIcon,
        live: true,
        type: "dApp",
        link: "https://harborprotocol.one/",
        category: "dApp"
    },
    {
        name: "Commodo",
        descriptions: "Commodo is a decentralised, open-source, IBC-native, collateralized lending-borrowing platform on Cosmos. Commodo tries to alleviate these pain points in existing lending-borrowing markets. It has a distinctive architectural design with cPools and transit assets.",
        icon: CommodoIcon,
        live: true,
        type: "dApp",
        link: "https://commodo.one/",
        category: "dApp"
    },
    {
        name: "cAsset",
        descriptions: "cAsset is a synthetics protocol, it will allow users to gain exposure to different synthetic assets whilst remaining on-chain.",
        icon: cAssetIcon,
        type: "dApp",
        link: "",
        category: "dApp"
    },
    {
        name: "ShipFi",
        descriptions: "ShipFi enables the collateralization of real-world assets as NFTs, utilizing the $SHIP token. ShipFi will facilitates the digitization of trade finance debt products.",
        icon: ShipFiIcon,
        type: "dApp",
        link: "",
        category: "dApp"
    },
    {
        name: "Leap Wallet",
        descriptions: "Non-custodial Cosmos super wallet available on desktop and mobile.",
        icon: LeapWalletIcon,
        live: "live",
        type: "Wallet",
        link: "https://www.leapwallet.io/",
        category: "wallet"
    },
    {
        name: "Axelar",
        descriptions: "Axelar delivers secure cross-chain communication for Web3. Our infrastructure enables dApp users to interact with any asset or application, on any chain, with one click.",
        icon: AxelarIcon,
        live: "live",
        type: "Bridge",
        link: "https://satellite.money/?source=comdex&destination=osmosis&asset_denom=uusdc&destination_address=",
        category: "Bridge"
    },
    {
        name: "Syndicate",
        descriptions: "A solutions provider Maximizing liquidity, governance and value for Comdex Applications.",
        icon: SyndicateIcon,
        type: "dApp",
        link: "",
        category: "dApp"
    },
    {
        name: "Mintscan",
        descriptions: "Blockchain Explorer.",
        icon: MintscanIcon,
        live: "live",
        type: "Explorer",
        link: "https://www.mintscan.io/comdex",
        category: "Explorer"
    },
    {
        name: "BigDipper",
        descriptions: "Big Dipper is the open-source explorer for the Interchain.",
        icon: BigDipperIcon,
        live: "live",
        type: "Explorer",
        link: "https://bigdipper.live/comdex",
        category: "Explorer"
    },
    {
        name: "Skip",
        descriptions: "Skip builds sovereign MEV solutions for app-chains to increase resistance and recapture MEV rewards for network participants.",
        icon: SkipIcon,
        type: "dApp",
        link: "https://skip.money/",
        category: "dApp"
    },
    {
        name: "White Whale",
        descriptions: "White Whale is an Interchain Liquidity Protocol. Through its interconnected Liquidity Hubs on every major Cosmos SDK Chain it aims to solve liquidity fragmentation and market inefficiencies between chains.",
        icon: WhiteWhaleIcon,
        live: "live",
        type: "dApp",
        link: "https://whitewhale.money/",
        category: "dApp"
    },
    {
        name: "Citadel one",
        descriptions: "Unified multi currency wallet for cryptocurrency.",
        icon: CitadeloneIcon,
        live: "live",
        type: "Wallet",
        link: "https://citadel.one/",
        category: "Wallet"
    },
    {
        name: "Band Protocol",
        descriptions: "Band Protocol is a secure, scalable, cross-chain decentralized data oracle platform that aggregates and connects real-world data and APIs to smart contracts.",
        icon: BandProtocolIcon,
        live: "live",
        type: "Oracle",
        link: "https://www.bandprotocol.com/",
        category: "Oracle"
    },
]