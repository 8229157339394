import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, SvgIcon } from "../../../components/common";
import LeftNav from "./LeftNav";
import "./index.less";

const CosmosModuleOneTwo = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'INTRODUCTION TO COSMOS',
                                    href: 'whatis-Cosmos-sdk',
                                },
                                {
                                    title: 'WHAT IS COSMOS',
                                    href: '',
                                },
                                {
                                    title: 'MODULE 1',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Comparison with other blockchain frameworks</h1>
                                <p>
                                    When it comes to blockchain application development, there are several popular blockchain frameworks that developers can choose from. Ethereum, Hyperledger Fabric, and EOSIO are some of the most popular frameworks available, and each of them has its own advantages and limitations. In this module, we will compare the Cosmos SDK with these frameworks, highlighting the key differences and benefits of using the Cosmos SDK.
                                </p>

                                <ul className="info-list">
                                    <li>
                                        <SvgIcon name='info' viewbox='0 0 20 20' />
                                        Last Updated Jan 8, 2023
                                    </li>
                                    <li>
                                        <SvgIcon name='globe' viewbox='0 0 8.493 8.493' />
                                        English
                                    </li>
                                </ul>
                                
                                <div className="learn-module">
                                    <h1>What will you learn in Module 1.2</h1>
                                    <div className="learn-m-card">
                                        <ul>
                                            <li>
                                                Comparing Cosmos SDK with other popular blockchain frameworks
                                            </li>
                                            <li>
                                                 Understanding the key differences between Cosmos SDK and other frameworks
                                            </li>
                                            <li>
                                                Advantages and disadvantages of using Cosmos SDK
                                            </li>
                                        </ul>
                                    </div>

                                    <h3>Ethereum:</h3>
                                    <p>
                                        Ethereum is a decentralized, open-source blockchain platform that enables the creation of smart contracts and decentralized applications (dApps). Unlike the Cosmos SDK, which is designed to be interoperable with other blockchains, Ethereum is not interoperable with other blockchains out of the box. This means that Ethereum-based applications cannot communicate with other blockchain networks, making it more difficult to build decentralized applications that are connected to multiple networks.
                                    </p>
                                    <p>
                                        One key advantage of using Ethereum is its large developer community and ecosystem, which has resulted in a wide range of tools, libraries, and resources available for developers. Ethereum is also well-suited for creating decentralized finance (DeFi) applications, which have become increasingly popular in recent years.
                                    </p>

                                    <h3>Hyperledger Fabric:</h3>
                                    <p>
                                        Hyperledger Fabric is a permissioned blockchain framework that allows developers to create private, secure blockchain networks for business use cases. Unlike the Cosmos SDK, which is designed to be interoperable with other blockchains, Hyperledger Fabric is not designed for interoperability. This means that applications built on Hyperledger Fabric are limited to the network they are built on and cannot interact with other blockchain networks.
                                    </p>
                                    <p>
                                        One key advantage of using Hyperledger Fabric is its emphasis on privacy and security, making it well-suited for enterprise applications. Hyperledger Fabric also offers a modular architecture that allows developers to customize the framework for their specific needs.
                                    </p>

                                    <h3>EOSIO:</h3>
                                    <p>
                                        EOSIO is blockchain software that allows developers to build decentralized applications. It is designed to be scalable and can handle high transaction volumes. Unlike Cosmos, EOSIO uses a delegated Proof of Stake (dPoS) consensus mechanism. This means that token holders can vote for block producers who will validate transactions and maintain the network.
                                    </p>
                                    <p>
                                        One key advantage of using EOSIO is its scalability, which makes it well-suited for high transaction volume applications. EOSIO also offers a user-friendly developer experience, which has helped to grow its developer community.
                                    </p>

                                    <h3>Polkadot:</h3>
                                    <p>
                                        Polkadot is a multi-chain framework that allows developers to create their own custom blockchain networks, which can then be connected to the Polkadot network. Similar to the Cosmos SDK, Polkadot emphasizes interoperability between different blockchain networks.
                                    </p>
                                    <p>
                                        One key advantage of using Polkadot is its ability to scale horizontally, meaning that the network can add more parachains (custom blockchains) as needed to increase its capacity. Polkadot also offers a governance model that allows token holders to vote on network upgrades and changes.
                                    </p>

                                    <h3>Avalanche:</h3>
                                    <p>
                                        Avalanche is a platform for creating decentralized applications that is designed to be highly scalable, fast, and interoperable. Avalanche uses a consensus mechanism called Avalanche, which allows for high transaction throughput and fast finality.
                                    </p>
                                    <p>
                                        One key advantage of using Avalanche is its high throughput and low latency, which makes it well-suited for applications that require fast transaction times. Avalanche also offers a unique feature called subnets, which allows developers to create their own custom blockchain networks within the Avalanche ecosystem.
                                    </p>
                                    <p>
                                        Overall, the Cosmos SDK stands out from these other frameworks for its emphasis on interoperability between different blockchain networks, which creates new possibilities for decentralized applications. However, each framework has its own unique advantages and limitations, and developers should consider their specific use case and requirements when choosing a blockchain framework to us
                                    </p>
                                    <p>
                                        The Cosmos SDK is designed to be modular and interoperable with other blockchains in the Cosmos Network. This means developers can build custom blockchain applications that can interact with other blockchains in the network, creating new possibilities for decentralized applications. The SDK's modularity also makes it easy for developers to customize the application's features, allowing them to create blockchain applications for different use cases.
                                    </p>

                                    <h3>Advantages of using Cosmos SDK:</h3>

                                    <h4>1. Interoperability:</h4>
                                    <p>
                                        Interoperability: Cosmos SDK's Inter-Blockchain Communication (IBC) protocol allows for interoperability between different blockchain applications, enabling decentralized applications to interact with each other. This means that developers can build more complex and powerful applications that are connected to multiple blockchain networks.
                                    </p>

                                    <h4>2. Modularity:</h4>
                                    <p>
                                        Cosmos SDK's modular architecture allows developers to add or remove features as needed, making it easier to customize blockchain applications. This means that developers can create blockchain applications that are tailored to specific use cases, and can also update and modify their applications as needed.
                                    </p>

                                    <h4>3. Flexibility:</h4>
                                    <p>
                                        The SDK's flexibility allows developers to create blockchain applications for a wide range of use cases, from simple token issuance to complex decentralized applications. This means that developers can leverage the SDK's modular architecture to create custom applications that meet their specific needs.
                                    </p>

                                    <h3>Disadvantages of using Cosmos SDK:</h3>
                                    <h4>1. Learning Curve:</h4>
                                    <p>
                                        The SDK's modular architecture and inter-blockchain communication capabilities may require a steeper learning curve compared to other blockchain frameworks.
                                    </p>
                                    
                                    <h4>2. Limited Adoption:</h4>
                                    <p>
                                        As a relatively new blockchain framework, the Cosmos SDK may not have as large of a developer community or user base compared to more established blockchain frameworks.
                                    </p>
                                </div>

                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with the first section <Link to='/'><b>Key features and benefits of using Cosmos SDK.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CosmosModuleOneTwo;