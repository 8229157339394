import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const CancelRequest = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: 'developer-portal',
                                    },
                                    {
                                        title: 'CREATE YOUR OWN COMDEX MODULE',
                                        href: 'dev-lessons-advance',
                                    },
                                    {
                                        title: 'CANCEL REQUEST',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Cancel a Loan</h1>
                                <p>
                                    If you have created a loan as a borrower but have changed your mind and no longer want to proceed with it, you can choose to cancel the loan. However, this can only be done if the loan's current status is marked as "requested."
                                </p>
                                <p>
                                    Once you cancel the loan, the collateral tokens that were being held as security for the loan will be transferred back to your account from the module account. As a result, you will regain possession of the collateral tokens you had initially provided as security for the loan.
                                </p>


                                <div className="learn-module">
                                <CodeSnippet
codeData={`func (k Keeper) Cancel(ctx sdk.Context, loanID uint64) error {    
    loan, found := k.GetLoan(ctx, loanID)    
    if !found {        
        return sdkerrors.Wrapf(sdkerrors.ErrKeyNotFound, "key %d doesn't exist for loanID", loanID)    
    }    
    if loan.State != "requested" {        
        return sdkerrors.Wrapf(types.ErrWrongLoanState, "%v", loan.State)    
    }    
    borrowerAddr, err := sdk.AccAddressFromBech32(loan.Owner)    
    if err != nil {        
        return err    
    }

    pair, found := k.Asset.GetPair(ctx, loan.PairID)    
    if !found {        
        return assettypes.ErrorPairDoesNotExist    
    }    

    assetIn, found := k.Asset.GetAsset(ctx, pair.AssetIn)    
    if !found {        
        return assettypes.ErrorAssetDoesNotExist    
    }

    err = k.bankKeeper.SendCoinsFromModuleToAccount(ctx, types.ModuleName, borrowerAddr, sdk.NewCoins(sdk.NewCoin(assetIn.Denom, loan.AmountIn.Add(loan.Fee))))    
    if err != nil {        
        return err    
    }    
    k.DeleteLoan(ctx, loanID)

    return nil
}`}
/>

                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/cli-query'><b>CLI Query.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CancelRequest;