import { Breadcrumb, Button } from "antd";
import React from "react";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";
import { Link } from "react-router-dom";

const CliTxn = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className="introductiondtl-row">
                        <LeftNav />
                        <div className="introductiondtl-right">
                            <Breadcrumb
                                separator={<SvgIcon name="chevron-right" viewbox="0 0 8 12" />}
                                items={[
                                    {
                                        title: "DEVELOPER PORTAL",
                                        href: "",
                                    },
                                    {
                                        title: "CREATE YOUR OWN COMDEX MODULE",
                                        href: "",
                                    },
                                    {
                                        title: "CLI txn",
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Creating Commands for Transaction</h1>
                                <p>
                                    We first need to generate the .pb files for tx.proto and then
                                    define txn messages and msgserver methods.
                                </p>



                                <div className="learn-module">

                                <CodeSnippet
codeData={`func txRequest() * cobra.Command {
    cmd: = & cobra.Command {
        Use: "request [app-id] [pair-id] [amountIn] [amountOut] [fee] [duration]",
        Short: "create a loan request",
        Args: cobra.ExactArgs(6),
        RunE: func(cmd * cobra.Command, args[] string) error {
            ctx,
            err: = client.GetClientTxContext(cmd)
            if err != nil {
                return err
            }

            appID,
            err: = strconv.ParseUint(args[0], 10, 64)
            if err != nil {
                return err
            }

            pairID,
            err: = strconv.ParseUint(args[1], 10, 64)
            if err != nil {
                return err
            }

            amountIn,
            ok: = sdk.NewIntFromString(args[2])
            if !ok {
                return err
            }
            amountOut,
            ok: = sdk.NewIntFromString(args[3])
            if !ok {
                return err
            }

            fee,
            ok: = sdk.NewIntFromString(args[4])
            if !ok {
                return err
            }

            duration,
            ok: = sdk.NewIntFromString(args[5])
            if !ok {
                return err
            }

            msg: = types.NewMsgCreate(ctx.GetFromAddress()
                .String(), appID, pairID, amountIn, amountOut, fee, duration.Int64())

            return tx.GenerateOrBroadcastTxCLI(ctx, cmd.Flags(), msg)
        },
    }

        flags.AddTxFlagsToCmd(cmd)
    return cmd
} `}
/>

                                    <CodeSnippet
codeData={`func txApprove() * cobra.Command {
    cmd: = & cobra.Command {
        Use: "approve [loan-id]",
        Short: "approve a loan request",
        Args: cobra.ExactArgs(1),
        RunE: func(cmd * cobra.Command, args[] string) error {
            ctx, err: = client.GetClientTxContext(cmd)
            if err != nil {
                return err
            }

            loanID, err: = strconv.ParseUint(args[0], 10, 64)
            if err != nil {
                return err
            }

            msg: = types.NewMsgApprove(ctx.GetFromAddress()
                .String(), loanID)

            return tx.GenerateOrBroadcastTxCLI(ctx, cmd.Flags(), msg)
        },
    }

        flags.AddTxFlagsToCmd(cmd)
    return cmd
}
    `}
/>

                                    <CodeSnippet
codeData={`func txRepay() * cobra.Command {
    cmd: = & cobra.Command {
        Use: "repay [loan-id]",
        Short: "repay a loan ",
        Args: cobra.ExactArgs(1),
        RunE: func(cmd * cobra.Command, args[] string) error {
            ctx, err: = client.GetClientTxContext(cmd)
            if err != nil {
                return err
            }
            loanID, err: = strconv.ParseUint(args[0], 10, 64)
            if err != nil {
                return err
            }

            msg: = types.NewMsgRepay(ctx.GetFromAddress()
                .String(), loanID)

            return tx.GenerateOrBroadcastTxCLI(ctx, cmd.Flags(), msg)
        },
    }

        flags.AddTxFlagsToCmd(cmd)
    return cmd
}
    `}
/>

                                    <CodeSnippet
codeData={`func txCancel() * cobra.Command {
    cmd: = & cobra.Command {
        Use: "cancel [loan-id]",
        Short: "Cancel a loan ",
        Args: cobra.ExactArgs(1),
        RunE: func(cmd * cobra.Command, args[] string) error {
            ctx, err: = client.GetClientTxContext(cmd)
            if err != nil {
                return err
            }

            loanID, err: = strconv.ParseUint(args[0], 10, 64)
            if err != nil {
                return err
            }

            msg: = types.NewMsgCancel(ctx.GetFromAddress()
                .String(), loanID)

            return tx.GenerateOrBroadcastTxCLI(ctx, cmd.Flags(), msg)
        },
    }

        flags.AddTxFlagsToCmd(cmd)
    return cmd
}
    `}
/>

                                    <p>Here are the msgserver methods:</p>
                                    <CodeSnippet
codeData={`func NewMsgServerImpl(keeper Keeper) types.MsgServer {    
    return &msgServer{Keeper: keeper}
}

var _ types.MsgServer = msgServer{}

func (m msgServer) MsgCreate(goCtx context.Context, request 
*types.MsgCreateRequest) (*types.MsgCreateResponse, error) {    
    ctx := sdk.UnwrapSDKContext(goCtx)    
    err := m.Keeper.Request(ctx, request.AppId, request.PairId, 
    request.GetFrom(), request.AmountIn, request.AmountOut, 
    request.Fee, request.DurationDays)    
        if err != nil {        
            return nil, err    
        }    
        return &types.MsgCreateResponse{}, nil
    }

func (m msgServer) MsgApprove(goCtx context.Context, request 
*types.MsgApproveRequest) (*types.MsgApproveResponse, error) {    
    ctx := sdk.UnwrapSDKContext(goCtx)    
    err := m.Keeper.Approve(ctx, request.LoanId, request.GetFrom())    
    if err != nil {        
        return nil, err    
    }    
    return &types.MsgApproveResponse{}, nil
}

func (m msgServer) MsgRepay(goCtx context.Context, request 
*types.MsgRepayRequest) (*types.MsgRepayResponse, error) {    
    ctx := sdk.UnwrapSDKContext(goCtx)    
    err := m.Keeper.Repay(ctx, request.LoanId)    
    if err != nil {        
        return nil, err    
    }    
    return &types.MsgRepayResponse{}, nil
}

func (m msgServer) MsgCancel(goCtx context.Context, request 
*types.MsgCancelRequest) (*types.MsgCancelResponse, error) {    
    ctx := sdk.UnwrapSDKContext(goCtx)    
    err := m.Keeper.Cancel(ctx, request.LoanId)    
    if err != nil {        
        return nil, err    
    }    
    return &types.MsgCancelResponse{}, nil
}`}
/>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/gettingstarted-with-cosmWasm'><b>Getting started with CosmWasm.</b></Link></p>
                            
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name="documentation" viewbox="0 0 37 41" />
                                                        <div>
                                                            <Button
                                                                type="link"
                                                                href="https://docs.comdex.one/"
                                                                target="_blank"
                                                                rel="noreferrer noopener"
                                                            >
                                                                <h3>
                                                                    Documentation{" "}
                                                                    <SvgIcon
                                                                        name="arrow-link"
                                                                        viewbox="0 0 16 16"
                                                                    />
                                                                </h3>
                                                            </Button>
                                                            <p>
                                                                Learn and apply information from the official
                                                                sources about a task or project.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name="github" viewbox="0 0 60 59.495" />
                                                        <div>
                                                            <Button
                                                                type="link"
                                                                href="https://github.com/comdex-official"
                                                                target="_blank"
                                                                rel="noreferrer noopener"
                                                            >
                                                                <h3>
                                                                    Github{" "}
                                                                    <SvgIcon
                                                                        name="arrow-link"
                                                                        viewbox="0 0 60 59.495"
                                                                    />
                                                                </h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className="ratethis-page">
                            <p>Rate this Page</p>
                            <Button>
                                <SvgIcon name="thumb-up" viewbox="0 0 20 19" />
                            </Button>
                            <Button>
                                <SvgIcon name="thumb-down" viewbox="0 0 20 19" />
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default CliTxn;
