import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const WorkingWithFilesInRust = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'DEV LESSONS BASICS(RUST)',
                                    href: 'devlessons-with-rust',
                                },
                                {
                                    title: 'working with files & I/o in rust',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Working with Files and I/O in Rust</h1>
                                <p>
                                    In this lesson, we will cover the basics of file input/output in Rust. We will learn how to read and write files, use file I/O functions, and understand streams and buffers in Rust.
                                </p>

                                <div className="learn-module">

                                    <h3>Reading and Writing Files</h3>

                                    <p>
                                        Rust provides several functions to read and write files. The std::fs::File struct represents a file in Rust. You can create a File object by calling the File::open() function, which returns a Result object containing a File object if successful.
                                    </p>
                                    <CodeSnippet
codeData={`use std::fs::File;
use std::io::prelude::*;

fn main() -> std::io::Result<()> {    
    let mut file = File::create("output.txt")?;    
    file.write_all(b"Hello, world!")?;    
    Ok(())
}`}
/>
                                    <p>
                                        In this example, we create a File object named file by calling the File::create() function. We then write the string "Hello, world!" to the file using the write_all() function.
                                    </p>
                                    <p>
                                        To read from a file, we can use the File::open() function and then use the BufReader struct to read from the file. The BufReader struct is a buffered reader that improves the efficiency of reading from a file.
                                    </p>
                                    <CodeSnippet
codeData={`use std::fs::File;
use std::io::prelude::*;
use std::io::BufReader;

fn main() -> std::io::Result<()> {    
    let file = File::open("input.txt")?;    
    let reader = BufReader::new(file);    
    
    for line in reader.lines() {        
        println!("{}", line?);    
    }    
    
    Ok(())
}`}
/>
                                    <p>
                                        In this example, we open a file named input.txt using the File::open() function. We then create a BufReader object named reader and pass it the file object. We can then use the lines() method of the reader object to iterate over each line of the file.
                                    </p>

                                    <h3>Using File I/O Functions</h3>
                                    <p>
                                        Rust also provides several file I/O functions to make it easier to work with files. Some of these functions include read_to_string(), write(), flush(), seek(), and metadata(). These functions allow us to read and write data to and from files, flush the contents of a file, move the file pointer, and get metadata about a file, respectively.
                                    </p>
                                    <CodeSnippet
codeData={`use std::fs::File;
use std::io::prelude::*;

fn main() -> std::io::Result<()> {    
    let mut file = File::create("output.txt")?;    
    file.write_all(b"Hello, world!")?;    
    file.flush()?;    
    
    let mut input = String::new();    
    File::open("input.txt")?.read_to_string(&mut input)?;    
    
    println!("{}", input);    
    
    Ok(())
}`}
/>
                                    <p>
                                        In this example, we use the write_all() function to write the string "Hello, world!" to a file. We then call the flush() function to ensure that all data is written to the file. We then read the contents of the file into a string using the read_to_string() function.
                                    </p>
                                    <h3>Understanding Streams and Buffers</h3>
                                    <p>
                                        In Rust, streams and buffers are important concepts in file I/O. A stream is an abstraction that represents a sequence of data that can be read or written to. Buffers are used to store data in memory before it is written to a file or read from a file.
                                    </p>
                                    <p>
                                        When reading from a file, Rust's I/O library reads data in chunks, which are stored in a buffer. Reading data in chunks can be more efficient than reading one byte at a time. When writing to a file, data is first stored in a buffer and then written to the file. This is also more efficient than writing one byte at a time.
                                    </p>
                                    <p>
                                        Rust's I/O library provides various methods for reading and writing data to files, including buffered and unbuffered I/O. Buffered I/O is generally faster than unbuffered I/O, but it uses more memory.
                                    </p>
                                    <p>
                                        Streams and buffers can also be used in network programming in Rust. For example, when sending data over a network, the data is first stored in a buffer before being sent over the network stream.
                                    </p>
                                    <p>
                                        Understanding streams and buffers is important in Rust for efficient file I/O and network programming.
                                    </p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/create-your-dApp'><b>Create your first dApp on Comdex.</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default WorkingWithFilesInRust;