import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const ArraysAndSlices = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                {
                                    title: 'DEVELOPER PORTAL',
                                    href: 'developer-portal',
                                },
                                {
                                    title: 'DEV LESSONS BASICS',
                                    href: 'dev-lessons-basics',
                                },
                                {
                                    title: 'Arrays and Slices',
                                },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Arrays and Slices</h1>
                                <p>
                                    In this lesson, we will cover arrays and slices in Go, including creating and initializing arrays and slices, accessing elements of an array or slice, and using built-in functions for manipulating slices.
                                </p>

                                <div className="learn-module">
                                    <h1>Lesson Objectives:</h1>
                                    <div className="learn-m-card">
                                        <p>By the end of this lesson, you should be able to:</p>
                                        <ul>
                                            <li>
                                                Understand how to create and initialize arrays and slices in Go.
                                            </li>
                                            <li>
                                                Access elements of an array or slice in Go.
                                            </li>
                                            <li>
                                               Use built-in functions for manipulating slices in Go.
                                            </li>
                                        </ul>
                                    </div>

                                    <h3>Creating and Initializing Arrays and Slices</h3>

                                    <p>
                                        Arrays and slices are fundamental data structures in many programming languages, including Go, which we will be focusing on in this lesson. In Go, arrays and slices are used to store and manipulate collections of data of the same type. In this lesson, we will cover how to create and initialize arrays and slices in Go.
                                    </p>

                                    <h3>Creating Arrays</h3>
                                    <p>
                                        An array is a fixed-size collection of elements of the same type. The size of the array is determined at the time of creation and cannot be changed. To create an array in Go, we use the following syntax:
                                    </p>

                                    <p>Here is an example of a function that takes two integer arguments and returns their sum:</p>
                                    <CodeSnippet
codeData={`var arrayName [size]dataType`}
/>

                                    <p>Here, arrayName is the name of the array, size is the number of elements in the array, and dataType is the type of data that the array will store. For example, to create an array of integers with 5 elements, we would use the following code:</p>
                                    <CodeSnippet
codeData={`var numbers [5]int`}
/>

                                    <p>
                                        This creates an array called numbers with a size of 5 and the int data type. By default, all elements in the array are initialized to their zero values (which is 0 for integers).
                                    </p>

                                    <h3>Initializing Arrays</h3>
                                    <p>We can also initialize the values of an array at the time of creation. To do this, we use the following syntax:</p>
                                    <CodeSnippet
codeData={`var arrayName = [size]dataType{value1, value2, ..., valueN}`}
/>

                                    <p>
                                        Here, arrayName is the name of the array, size is the number of elements in the array, dataType is the type of data that the array will store, and value1, value2, ..., valueN are the initial values of the array elements. For example, to create an array of integers with 5 elements and initialize the values of the elements, we would use the following code:
                                    </p>
                                    <CodeSnippet
codeData={`var numbers = [5]int{1, 2, 3, 4, 5}`}
/>

                                    <p>
                                        This creates an array called numbers with a size of 5, the int data type, and the values 1, 2, 3, 4, and 5 for the array elements.
                                    </p>

                                    <h3>Creating Slices</h3>
                                    <p>
                                        A slice is a dynamic data structure that can grow or shrink as needed. Slices are created from arrays, and they provide a more flexible way of working with collections of data. To create a slice in Go, we use the following syntax:
                                    </p>

                                    <h3>var sliceName []dataType</h3>
                                    <p>Here, sliceName is the name of the slice, and dataType is the type of data that the slice will store. For example, to create a slice of integers, we would use the following code:</p>

                                    <h3>var numbers []int</h3>
                                    <p>This creates a slice called numbers with the int data type.</p>

                                    <h3>Initializing Slices</h3>
                                    <p>We can also initialize the values of a slice at the time of creation. To do this, we use the following syntax:</p>
                                    <CodeSnippet
codeData={`var sliceName = []dataType{value1, value2, ..., valueN}`}
/>

                                    <p>Here, sliceName is the name of the slice, dataType is the type of data that the slice will store, and value1, value2, ..., valueN are the initial values of the slice elements. For example, to create a slice of integers and initialize the values of the elements, we would use the following code:</p>
                                    
                                    <CodeSnippet
codeData={`var numbers = []int{1, 2, 3, 4, 5}`}
/>
                                    <p>This creates a slice called numbers with the int data type.</p>

                                    <h3>Here are some additional examples of creating and initializing arrays and slices in Go:</h3>

                                    <h4>Creating Arrays</h4>
                                    <CodeSnippet
codeData={`// Create an array of strings with 3 elements
var fruits [3]string

// Create an array of booleans with 4 elements
var flags [4]bool`}
/>
                                    <h4>Creating Slices</h4>
                                    <CodeSnippet
codeData={`// Create a slice of integers
var numbers []int

// Create a slice of strings
var fruits []string`}
/>

                                    <h4>Initializing Slices</h4>
                                    <CodeSnippet
codeData={`// Create a slice of integers and initialize the values
var numbers = []int{1, 3, 5, 7, 9}

// Create a slice of strings and initialize the values
var fruits = []string{"apple", "banana", "orange"}`}
/>

                                    <p>It's worth noting that we can also create and initialize arrays and slices using the make function. This is particularly useful for creating slices with a specific length and capacity, as well as for creating dynamic arrays. Here's an example:</p>
                                    <CodeSnippet
codeData={`// Create a slice of integers with a length and capacity of 5
var numbers = make([]int, 5)

// Create a dynamic array of integers with a length of 0 and capacity of 5
var dynamicArray = make([]int, 0, 5)`}
/>
                                    <p>In summary, creating and initializing arrays and slices in Go is a fundamental concept that every programmer should understand. By following the examples and syntax outlined in this lesson, you'll be well on your way to working with collections of data in your Go programs.</p>

                                    <h4>Accessing Elements of an Array or Slice in Go</h4>
                                    <p>In Go, arrays and slices are a collection of elements of the same type. You may need to access the individual elements of an array or slice to manipulate or work with them. In this section, we'll cover how to access elements of an array or slice in Go, and we'll also explore some of the built-in functions for manipulating slices.</p>

                                    <h4>Accessing Elements of an Array</h4>
                                    <p>To access an element of an array in Go, you use the index of the element within the array. In Go, array indices start at 0. Here's an example of how to access the second element of an array:</p>
                                    <CodeSnippet
codeData={`var numbers = [5]int{1, 2, 3, 4, 5}
var secondNumber = numbers[1] // Access the second element of the array`}
/>
                                    <p>In this example, we create an array called numbers with 5 elements, and then access the second element (which has an index of 1) and assign it to a variable called secondNumber.</p>

                                    <h4>Accessing Elements of a Slice</h4>
                                    <p>To access an element of a slice in Go, you also use the index of the element within the slice. However, slices are a bit more flexible than arrays, as their size can change dynamically. Here's an example of how to access the third element of a slice:</p>
                                    <CodeSnippet
codeData={`var numbers = []int{1, 2, 3, 4, 5}
var thirdNumber = numbers[2] // Access the third element of the slice`}
/>

                                    <p>In this example, we create a slice called numbers with 5 elements, and then access the third element (which has an index of 2) and assign it to a variable called thirdNumber.</p>

                                    <p>It's worth noting that when you access an element that is out of bounds of the slice, a runtime panic occurs.</p>

                                    <h3>Built-in Functions for Manipulating Slices</h3>

                                    <p>Built-in Functions for Manipulating Slices</p>

                                    <h4>append</h4>
                                    <p>The append function is used to add elements to the end of a slice. Here's an example:</p>
                                    <CodeSnippet
codeData={`var numbers = []int{1, 2, 3}
numbers = append(numbers, 4, 5) // Append two new elements to the end of the slice`}
/>
                                    <p>
                                        In this example, we create a slice called numbers with 3 elements, and then use the append function to add two new elements (4 and 5) to the end of the slice. The append function returns a new slice, so we assign the result back to numbers to update the original slice.
                                    </p>
                                    <p>
                                        It's important to note that when you append to a slice, the underlying array may need to be resized, which can be an expensive operation in terms of time and memory. To avoid frequent resizing, you can use the make function to create a slice with an initial capacity that is large enough to hold the expected number of elements.
                                    </p>

                                    <h4>copy</h4>
                                    <p>The copy function is used to copy the elements from one slice to another. Here's an example</p>
                                    <CodeSnippet
codeData={`var source = []int{1, 2, 3}
var destination = make([]int, len(source))
copy(destination, source) // Copy the elements from source to destination`}
/>

                                    <p>
                                        In this example, we create a slice called source with 3 elements, and then create a new slice called destination with the same length as source. We then use the copy function to copy the elements from source to destination.
                                    </p>
                                    <p>
                                        It's important to note that when you copy between slices, the destination slice must have enough capacity to hold the copied elements. If the destination slice is too small, the copy function will panic at runtime.
                                    </p>

                                    <h4>len and cap</h4>
                                    <p>The len and cap functions are used to get the length and capacity of a slice, respectively. Here's an example:</p>
                                    <CodeSnippet
codeData={`var numbers = []int{1, 2, 3, 4, 5}
var length = len(numbers) // Get the length of the slice
var capacity = cap(numbers) // Get the capacity of the slic`}
/>

                                    <p>In this example, we create a slice called numbers with 5 elements, and then use the len and cap functions to get the length and capacity of the slice.</p>

                                    <h4>Conclusion</h4>
                                    <p>In this lesson, we covered how to access elements of an array or slice in Go, and we also explored some of the built-in functions for manipulating slices. By understanding how to work with arrays and slices in Go, you'll be able to write more powerful and expressive code.</p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/pointers-and-struct'><b>Pointers and Structs..</b></Link></p>
                                
                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ArraysAndSlices;