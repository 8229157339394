import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CodeSnippet, Col, Container, Row, SvgIcon } from "../../../../components/common";
import LeftNav from "../LeftNav";
import "../index.less";

const StructsAndEnumsInRust = () => {
    return (
        <div className="homepage-wrapper introduction-dtl-wrapper">
            <section className="introductiondtl-section">
                <Container>
                    <div className='introductiondtl-row'>
                        <LeftNav />
                        <div className='introductiondtl-right'>
                            <Breadcrumb
                                separator={<SvgIcon name='chevron-right' viewbox='0 0 8 12' />}
                                items={[
                                    {
                                        title: 'DEVELOPER PORTAL',
                                        href: '',
                                    },
                                    {
                                        title: 'DEV LESSONS BASICS(RUST)',
                                        href: '',
                                    },
                                    {
                                        title: 'STRUCTS AND ENUMS IN RUST',
                                    },
                                ]}
                            />
                            <div className="into-dtl">
                                <h1>Structs and Enums in Rust</h1>
                                <p>
                                    Rust is not an object-oriented programming language, but it does provide features that allow you to achieve some of the same goals. Two of these features are structs and enums.
                                </p>

                                <div className="learn-module">

                                    <h3>Structs</h3>

                                    <p>
                                        A struct is a data structure that groups together variables of different types under a single name. It's similar to a class in an object-oriented language. Structs are defined using the struct keyword, followed by the name of the struct and a set of curly braces containing the variables that make up the struct.
                                    </p>
                                    <p>Here's an example:</p>
                                    <CodeSnippet
codeData={`struct Point {    
    x: i32,    
    y: i32,
}`}
/> 
                                    <p>
                                        This defines a Point struct with two i32 variables: x and y. You can create a new instance of this struct like this:
                                    </p>
                                    <p></p>
                                    <CodeSnippet
codeData={`let my_point = Point { x: 3, y: 4 };`}
/> 
                                    <p>You can access the variables in a struct using dot notation:</p>
                                    <CodeSnippet
codeData={`let my_x = my_point.x;
let my_y = my_point.y;`}
/> 

                                    <h3>Enums</h3>
                                    
                                    <p>
                                        An enum is a type that can have one of several values. It's similar to a class hierarchy in an object-oriented language. Enums are defined using the enum keyword, followed by the name of the enum and a set of curly braces containing the possible values.
                                    </p>
                                    <p>Here's an example:</p>
                                    <CodeSnippet
codeData={`enum Color {    
    Red,    
    Green,    
    Blue,
}`}
/> 
                                    <p>
                                        This defines a Color enum with three possible values: Red, Green, and Blue. You can create a new instance of this enum like this:
                                    </p>
                                    <p>
                                        let my_color = Color::Red;
                                    </p>
                                    <p>You can match on the value of an enum using a match expression:</p>
                                    <CodeSnippet
codeData={`match my_color {    
    Color::Red => println!("The color is red!"),    
    Color::Green => println!("The color is green!"),    
    Color::Blue => println!("The color is blue!"),
}`}
/> 

                                    <h3>Object-oriented programming in Rust</h3>
                                    <p>
                                        Rust doesn't have classes or inheritance like traditional object-oriented languages, but it does provide ways to achieve similar functionality. Structs can be used to group together variables and functions that operate on those variables, similar to how classes group together instance variables and methods. Enums can be used to define a set of related values, similar to how a class hierarchy defines a set of related classes.
                                    </p>
                                    <p>
                                        Rust also provides traits, which are similar to interfaces in other languages. Traits define a set of methods that a type must implement in order to be considered to have that trait. This allows you to define generic functions that can operate on any type that implements a particular trait.
                                    </p>
                                    <CodeSnippet
codeData={`trait Printable {    
    fn print(&self);
}

struct MyStruct {    
    x: i32,
}

impl Printable for MyStruct {    
    fn print(&self) {        
        println!("MyStruct {{ x: {} }}", self.x);    
    }
}

fn print_something<T: Printable>(thing: &T) {    
    thing.print();
}

fn main() {    
    let my_struct = MyStruct { x: 42 };    
    print_something(&my_struct);
}`}
/> 
                                    <p>
                                        This code defines a trait called Printable, which requires implementing a print method. The MyStruct struct implements this trait, and the print_something function takes a generic type that must implement the Printable trait. It then calls the print method on the passed-in object. While Rust's approach to object-oriented programming may be different from other languages, it provides powerful features that can be used to achieve similar goals.
                                    </p>
                                </div>
                                <h2>Next Up</h2>
                                <p className="bottom-link">Dive right into Cosmos with <Link to='/error-handling-inrust'><b>Error Handling in Rust.</b></Link></p>

                            </div>
                            <section className="statics-section-alt grid-2">
                                <Row>
                                    <Col>
                                        <ul>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='documentation' viewbox='0 0 37 41' />
                                                        <div>
                                                            <Button type="link" href="https://docs.comdex.one/" target="_blank" rel="noreferrer noopener">
                                                                <h3>Documentation <SvgIcon name='arrow-link' viewbox='0 0 16 16' /></h3>
                                                            </Button>
                                                            <p>Learn and apply information from the official sources about a task or project.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="card">
                                                    <div className="card-inner">
                                                        <SvgIcon name='github' viewbox='0 0 60 59.495' />
                                                        <div>
                                                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                                                <h3>Github <SvgIcon name='arrow-link' viewbox='0 0 60 59.495' /></h3>
                                                            </Button>
                                                            <p>Get the codes in one place.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                    <Row>
                        <Col className='ratethis-page'>
                            <p>Rate this Page</p>
                            <Button><SvgIcon name='thumb-up' viewbox='0 0 20 19' /></Button>
                            <Button><SvgIcon name='thumb-down' viewbox='0 0 20 19' /></Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default StructsAndEnumsInRust;