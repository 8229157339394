import { Button } from 'antd';
import React, {useState} from 'react';
import { SvgIcon } from './components/common';
  
const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0){
      setVisible(true)
    } 
    else if (scrolled <= 50){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <>
    {visible ? 
      <Button className='scrolltop-arrow' onClick={scrollToTop} >
        <SvgIcon name='arrow-up' viewbox='0 0 32 32' />
      </Button>
      : null}
    </>
  );
}
  
export default ScrollButton;